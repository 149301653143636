<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closePayPledgeItemModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Pay Pledges</span>
            <br>
            <div class="modal__content">
                <p v-if="errorMessage" class="text-center text-danger auth-status-msg">{{ errorMessage }}</p>
                <div class="row">
                    <div class="col-12">
                        <Form @submit="addPledgeItemPayment">
                            <div class="card">
                                <div class="card-body">
                                    <div v-if="selectedPledgeItem" class="form-group">
                                        <label for="amount">Amount</label>
                                        <Field v-model="payment.amount" type="number"
                                            :rules="`max_value:${(selectedPledgeItem.price * selectedPledgeItem.quantity)},Amount`"
                                            step="0.01" name="amount" class="form-control" id="amount"
                                            placeholder="0.00" />
                                        <ErrorMessage name="amount" class="form-text text-danger" />
                                    </div>
                                    <div v-else class="form-group">
                                        <label for="amount">Amount</label>
                                        <Field v-model="payment.amount" type="number"
                                            rules="required" step="0.01" name="amount" class="form-control" id="amount"
                                            placeholder="0.00" />
                                        <ErrorMessage name="amount" class="form-text text-danger" />
                                    </div>
                                    <div class="form-group">
                                        <label for="paymentMethod">Payment Method</label>
                                        <select v-model="payment.paymentMethod" class="form-control" rules="required">
                                            <option>---</option>
                                            <template v-bind:key="index" v-for="(payment, index) in PAYMENT_METHODS">
                                                <option :value="payment">{{ payment }}</option>
                                            </template>
                                        </select>
                                        <ErrorMessage name="paymentMethod" class="form-text text-danger" />
                                    </div>
                                    <div v-if="payment.paymentMethod == PAYMENT_METHODS[3]" class="form-group">
                                        <label for="checkNumber">Check Number</label>
                                        <Field v-model="payment.checkNumber" rules="required" type="text"
                                            name="checkNumber" class="form-control" id="checkNumber"
                                            placeholder="##########" />
                                        <ErrorMessage name="checkNumber" class="form-text text-danger" />
                                    </div>
                                    <div v-if="payment.paymentMethod == PAYMENT_METHODS[4]" class="form-group">
                                        <label for="cardOptions">Charity Card Option</label>
                                        <select v-model="payment.charityCardOption" class="form-control"
                                            rules="required">
                                            <option>---</option>
                                            <template v-bind:key="index"
                                                v-for="(cardOptions, index) in CHARITY_CARD_OPTIONS">
                                                <option :value="cardOptions">{{ cardOptions }}</option>
                                            </template>
                                        </select>
                                        <ErrorMessage name="cardOptions" class="form-text text-danger" />
                                    </div>
                                    <div v-if="payment.paymentMethod == PAYMENT_METHODS[4] && payment.charityCardOption == CHARITY_CARD_OPTIONS[4]" class="form-group">
                                        <label for="charityCardOptionOther">Charity Card Option Other</label>
                                        <Field v-model="charityCardOptionOther" rules="required" type="text"
                                            name="charityCardOptionOther" class="form-control"
                                            id="charityCardOptionOther" placeholder="Type here" />
                                        <ErrorMessage name="charityCardOptionOther" class="form-text text-danger" />
                                    </div>
                                    <div class="form-check custom-checkbox">
                                        <label class="form-check-label" for="exampleCheck1">
                                            <Field v-model="payment.sendReceipt" :value="true" type="checkbox"
                                                class="form-check-input" id="exampleCheck1" name="sendReceipt" />
                                            <span class="checkmark"></span>
                                            Send Receipt
                                        </label>
                                        <ErrorMessage name="sendReceipt" class="form-text text-danger" />
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div class="row justify-content-end modal__actions">
                                <button class="col-2 btn btn-save" :disabled="pledgeItemIsLoading ? true : false">
                                    <span v-if="pledgeItemIsLoading" class="spinner-border spinner-border-sm"
                                        role="status" aria-hidden="true"></span>
                                    <span v-if="!pledgeItemIsLoading">Pay</span>
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import { PLEDGE_PLEDGE_ITEM } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min, max_value } from '@vee-validate/rules';
import { PAYMENT_METHODS, CHARITY_CARD_OPTIONS } from '@/utils/const';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);
defineRule('max_value', (value, [max, fieldName = 'Field']) => {
    if (!value || parseFloat(value) <= parseFloat(max)) {
        return true;
    }
    return `${fieldName} must not be greater than ${max}`;
});

export default {
    props: {
        selectedMemberId: String,
        selectedPledgeItem: String
    },
    components: {
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage
    },
    data() {
        return {
            PAYMENT_METHODS,
            CHARITY_CARD_OPTIONS,
            showModal: true,
            payment: {
                id: null,
                amount: null,
                paymentMethod: null,
                checkNumber: null,
                charityCardOption: null,
                memb: null,
                isPaid: true,
                sendReceipt: true
            },
            charityCardOptionOther: null
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        pledgeItem() {
            return this.$store.getters.pledgeItem.data
        },
        pledgeItemIsLoading() {
            return this.$store.getters.pledgeItem.isLoading
        }
    },
    methods: {
        setValues() {
            if (this.selectedPledgeItem) {
                this.payment.id = this.selectedPledgeItem.id
            }
            this.payment.memb = this.selectedMemberId
        },
        addPledgeItemPayment() {
            if (this.charityCardOptionOther != null) {
                this.payment.charityCardOption = this.charityCardOptionOther
            }

            if (this.payment.sendReceipt == null) {
                this.payment.sendReceipt = false
            }

            this.$store.dispatch(PLEDGE_PLEDGE_ITEM, this.payment).then((res) => {
                if (res) {
                    this.$emit('closePayPledgeItemModal')
                    this.$emit('refreshPledgeItems')
                }
            })
        }
    },
    mounted() {
        this.setValues()
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    position: relative;
    left: 35%;
    margin-top: 50px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
}

.modal__title {
    font-size: 18px;
    font-weight: 600;
}

.modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}

.modal__content .nav-tabs .nav-link {
    font-size: 15px !important;
}

.form-check {
    padding-left: 0px;
    margin-top: 15px;
    position: relative;
    top: 5px;
    cursor: pointer;
}

.form-check .form-check-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.form-check .form-check-label {
    cursor: pointer;
}


.custom-checkbox .checkmark {
    position: relative;
    top: 3px;
    height: 18px;
    width: 18px;
    background-color: #F9F9FC;
    border: 1px solid #cfd3dd;    
    border-radius: 4px;
    display: inline-block;
    margin-right: 10px;

}

.custom-checkbox .form-check-input:checked+.checkmark {
    background-color: #14243D;
}

.custom-checkbox .checkmark::after {
    content: "";
    position: absolute;
    display: none;
}

.custom-checkbox .form-check-input:checked+.checkmark::after {
    display: block;
}

.custom-checkbox .checkmark::after {
    left: 6px;
    top: 3px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.btn-save {
    background-color: #14243D;
}
</style>