import baseAPI from '@/api/base';
import { EditRoom, AddRoom, GetRoom } from '../dtos/room.dto';
import { DELETE_ROOM_URL, ROOM_URL, EDIT_ROOM_URL, REGISTER_ROOM_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class Room {
    static getRooms = async(getRoom: GetRoom) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(ROOM_URL, {
                    params: getRoom
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static registerRoom = async(addRoom: AddRoom) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(REGISTER_ROOM_URL, {
                    ...addRoom
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static editRoom = async(editRoom: EditRoom) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(EDIT_ROOM_URL, {
                    ...editRoom
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static deleteRoom = async(donationId: string) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.delete(`${DELETE_ROOM_URL}/${donationId}`)

                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default Room