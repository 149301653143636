import axios, { AxiosResponse } from 'axios'
import { SET_INVOICE_ITEM_PAYMENT_LOGS, SET_INVOICE_ITEM_PAYMENT_LOG, SET_ERRORMSG, SET_SINGLE_INVOICE_ITEM_PAYMENT_LOGS } from '../mutation_types'
import {
    CLEAR_INVOICE_ITEM_PAYMENT_LOGS_ISLOADING,
    GET_INVOICE_ITEM_PAYMENT_LOGS,
    GET_SINGLE_INVOICE_ITEM_PAYMENT_LOGS
} from '../action_types'
import { StateObject } from '@/dtos/state.dto'
import InvoiceItemPurchaseLog from '@/services/invoice_item_payment_log'
import { Module } from 'vuex'
import { GetInvoiceItemPaymentLog } from '@/dtos/invoice_item_purchase_log'

const invoiceItemPaymentLog: Module<any, {}> = {
    state: {
        invoiceItemPaymentLog: {
            data: null,
            isLoading: false
        },
        invoiceItemPaymentLogs: {
            data: null,
            isLoading: false
        },
        singleInvoiceItemPaymentLogs: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        invoiceItemPaymentLog: (state: { invoiceItemPaymentLog: any }) => state.invoiceItemPaymentLog,
        invoiceItemPaymentLogs: (state: { invoiceItemPaymentLogs: any }) => state.invoiceItemPaymentLogs,
        singleInvoiceItemPaymentLogs: (state: { singleInvoiceItemPaymentLogs: any }) => state.singleInvoiceItemPaymentLogs
    },
    mutations: {
        [SET_INVOICE_ITEM_PAYMENT_LOG]: (state: { invoiceItemPaymentLog: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.invoiceItemPaymentLog.data = payload.data : null, state.invoiceItemPaymentLog.isLoading = payload.isLoading },
        [SET_INVOICE_ITEM_PAYMENT_LOGS]: (state: { invoiceItemPaymentLogs: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.invoiceItemPaymentLogs.data = payload.data : null, state.invoiceItemPaymentLogs.isLoading = payload.isLoading },
        [SET_SINGLE_INVOICE_ITEM_PAYMENT_LOGS]: (state: { singleInvoiceItemPaymentLogs: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.singleInvoiceItemPaymentLogs.data = payload.data : null, state.singleInvoiceItemPaymentLogs.isLoading = payload.isLoading }
    },
    actions: {
        [GET_INVOICE_ITEM_PAYMENT_LOGS]: async({ commit }: any, payload: any) => {
            commit(SET_INVOICE_ITEM_PAYMENT_LOGS, { data: null, isLoading: true })

            let result: AxiosResponse = await InvoiceItemPurchaseLog.getInvoiceItemPaymentLogs(payload as GetInvoiceItemPaymentLog)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_INVOICE_ITEM_PAYMENT_LOGS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_INVOICE_ITEM_PAYMENT_LOGS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_INVOICE_ITEM_PAYMENT_LOGS, { isLoading: false })
                return false;
            }
        },
        [GET_SINGLE_INVOICE_ITEM_PAYMENT_LOGS]: async({ commit }: any, payload: any) => {
            commit(SET_SINGLE_INVOICE_ITEM_PAYMENT_LOGS, { data: null, isLoading: true })

            let result: AxiosResponse = await InvoiceItemPurchaseLog.getInvoiceItemPaymentLogs(payload as GetInvoiceItemPaymentLog)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_SINGLE_INVOICE_ITEM_PAYMENT_LOGS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_SINGLE_INVOICE_ITEM_PAYMENT_LOGS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_SINGLE_INVOICE_ITEM_PAYMENT_LOGS, { isLoading: false })
                return false;
            }
        },
        [CLEAR_INVOICE_ITEM_PAYMENT_LOGS_ISLOADING]: ({ commit }) => {
            commit(SET_INVOICE_ITEM_PAYMENT_LOG, { isLoading: false })
            commit(SET_INVOICE_ITEM_PAYMENT_LOGS, { isLoading: false })
        }
    }
}

export default invoiceItemPaymentLog