<template>
    <div class="dashboard-body">
        <div class="row">
            <sidenav class="custom-col-width-2" />
            <div style="padding-left: 0px!important" class="custom-col-width-10 content-section dashboard-content">
                <navbar />
                <viewTemplateModal v-if="showViewTemplateModal" :selectedTemplateToView="selectedTemplateToView"
                    @close-view-template-modal="showViewTemplateModal = false" />
                <addTemplateModal v-if="showRegisterTemplateModal"
                    @close-add-template-modal="showRegisterTemplateModal = false" @refresh-templates="getTemplates()" />
                <editTemplateModal v-if="showEditTemplateModal" :selectedTemplate="selectedTemplate"
                    @close-edit-template-modal="showEditTemplateModal = false" @refresh-templates="getTemplates()" />
                <deleteTemplateModal v-if="showDeleteTemplateModal" :selectedTemplateId="selectedTemplateId"
                    @close-delete-template-modal="showDeleteTemplateModal = false"
                    @refresh-templates="getTemplates()" />
                <div class="content">
                    <br>
                    <div class="row button-grp justify-content-between">
                        <div class="col">
                            <h4>Templates</h4>
                        </div>
                        <div v-if="account.role == USER_ROLE_ADMIN" class="col">
                            <button v-on:click="showRegisterTemplateModal = true" class="btn btn-add"><i
                                    class="pi pi-plus"></i> Add
                                Template</button>
                        </div>
                    </div>
                    <div class="row filter-grp">
                        <div class="col-lg-9 col-md-7">
                            <i class="pi pi-search" aria-hidden="true"></i>
                            <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
                        </div>
                        <div class="col-lg-3 col-md-5">
                            <div class="row">
                                <div class="btn col"><img src="../assets/icons/calendar-icon.svg" alt=""> Select Date
                                </div>
                                <div class="btn col"><img src="../assets/icons/filter-icon.svg" alt="">
                                    Filters</div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div v-if="templatesIsLoading && templates == null">
                        <div class="row">
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#DFDFFAFF'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                        </div>
                    </div>
                    <div v-else class="row">
                        <div class="col-lg-12 offset-lg-0">
                            <div class="card">
                                <div class="card-body">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Name</th>
                                                <!-- <th scope="col">Content</th> -->
                                                <th scope="col">Created At</th>
                                                <th scope="col">Created By</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-bind:key="index" v-for="(template, index) in templates">
                                                <tr :class="[ isOdd(index + 1) ? 'striped' : null ]">
                                                    <th scope="row">{{ index + 1 }}</th>
                                                    <td>{{ template.name }}</td>
                                                    <!-- <td><span v-html="template.content"></span></td> -->
                                                    <td>{{ $filters.getDateOnly(template.createdAt) }}</td>
                                                    <td>{{ template.user.fname }}</td>
                                                    <td>
                                                        <i v-on:click="selectTemplateToView(template)"
                                                            class="pi pi-eye text-secondary"></i>
                                                        <i v-if="account.role == USER_ROLE_ADMIN"
                                                            v-on:click="selectTemplateToEdit(template)"
                                                            class="pi pi-pencil text-primary"></i>
                                                        <i v-if="account.role == USER_ROLE_ADMIN"
                                                            v-on:click="selectTemplateToDelete(template.id)"
                                                            class="pi pi-trash text-danger"></i>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ContentLoader } from 'vue-content-loader';

import sidenav from '@/components/Sidenav.vue'
import navbar from '@/components/Navbar.vue'
import viewTemplateModal from '@/components/modals/Template/View_Template_Modal.vue'
import addTemplateModal from '@/components/modals/Template/Add_Template_Modal.vue'
import editTemplateModal from '@/components/modals/Template/Edit_Template_Modal.vue'
import deleteTemplateModal from '@/components/modals/Template/Delete_Template_Modal.vue'
import { GET_TEMPLATES } from '@/store/action_types'
import { USER_ROLE_ADMIN } from '../utils/const';

export default {
    title: 'Templates',
    components: {
        sidenav,
        navbar,
        viewTemplateModal,
        editTemplateModal,
        deleteTemplateModal,
        addTemplateModal,
        ContentLoader
    },
    data() {
        return {
            USER_ROLE_ADMIN,
            showRegisterTemplateModal: false,
            showEditTemplateModal: false,
            showDeleteTemplateModal: false,
            showViewTemplateModal: false,
            selectedTemplate: null,
            selectedTemplateId: null,
            selectedTemplateToView: null
        }
    },
    computed: {
        templatesIsLoading() {
            return this.$store.getters.templates.isLoading
        },
        templates() {
            return this.$store.getters.templates.data
        },
        account() {
            return this.$store.getters.account.data
        }
    },
    methods: {
        isOdd(number) {
            return number % 2 !== 0;
        },
        selectTemplateToEdit(template) {
            this.selectedTemplate = template
            this.showEditTemplateModal = true
        },
        selectTemplateToDelete(templateId) {
            this.selectedTemplateId = templateId
            this.showDeleteTemplateModal = true
        },
        selectTemplateToView(template) {
            this.selectedTemplateToView = template
            this.showViewTemplateModal = true
        },
        getTemplates() {
            this.$store.dispatch(GET_TEMPLATES, { org: this.$route.params.id })
        },
        switchTemplateView(type) {
            this.selectedView = type
        }
    },
    mounted() {
        this.getTemplates()
    }
}
</script>
<style scoped>
.content .card,
.content .card-body {
    padding: 0px;
}

.content .card table small {
    margin-left: 10px;
    margin-right: 10px;
}

.content .card table .pi-eye,
.content .card table .pi-trash,
.content .card table .pi-pencil {
    cursor: pointer;
}

.custom-col-width-2 {
    width: 16.66%;
}

.custom-col-width-10 {
    width: 83.3%;
}

@media (max-width: 1000px) {
    .custom-col-width-2 {
        width: 12%;
    }

    .custom-col-width-10 {
        width: 88%;
    }
}
</style>