import baseAPI from '@/api/base';
import { GetReportFiles,  } from '@/dtos/report/report_file';
import { GET_REPORT_FILES_URL, DELETE_REPORT_FILE_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class ReportFiles {
    static getReportFiles = async(reportFiles: GetReportFiles) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(GET_REPORT_FILES_URL, {
                    params: reportFiles
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static deleteReportFile = async(reportFileId: string) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.delete(`${DELETE_REPORT_FILE_URL}/${reportFileId}`)
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default ReportFiles