import baseAPI from '@/api/base';
import { EditTemplate, GetTemplate, RegisterTemplate } from '../dtos/template.dto';
import { DELETE_TEMPLATE_URL, TEMPLATE_URL, EDIT_TEMPLATE_URL, ADD_TEMPLATE_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class Template {
    static getTemplate = async(getTemplate: GetTemplate) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(TEMPLATE_URL, {
                    params: getTemplate
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static registerTemplate = async(registerTemplate: RegisterTemplate) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(ADD_TEMPLATE_URL, {
                    ...registerTemplate
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static editTemplate = async(editTemplate: EditTemplate) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(EDIT_TEMPLATE_URL, {
                    ...editTemplate
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static deleteTemplate = async(donationId: string) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.delete(`${DELETE_TEMPLATE_URL}/${donationId}`)

                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default Template