import axios, { AxiosResponse } from 'axios'
import { SET_NOTIFICATION, SET_NOTIFICATIONS, SET_ALL_NOTIFICATIONS, SET_ERRORMSG } from '../mutation_types'
import {
    CLEAR_NOTIFICATIONS,
    CLEAR_NOTIFICATIONS_ISLOADING,
    GET_NOTIFICATIONS,
    GET_ALL_NOTIFICATIONS,
} from '../action_types'
import { StateObject } from '@/dtos/state.dto'
import Notification from '@/services/notification'
import { Module } from 'vuex'
import { ClearNotification, GetNotification } from '@/dtos/notification.dto'

const notification: Module<any, {}> = {
    state: {
        notification: {
            data: null,
            isLoading: false
        },
        notifications: {
            data: null,
            isLoading: false
        },
        allNotifications: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        notification: (state: { notification: any }) => state.notification,
        notifications: (state: { notifications: any }) => state.notifications,
        allNotifications: (state: { allNotifications: any }) => state.allNotifications
    },
    mutations: {
        [SET_NOTIFICATION]: (state: { notification: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.notification.data = payload.data : null, state.notification.isLoading = payload.isLoading },
        [SET_NOTIFICATIONS]: (state: { notifications: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.notifications.data = payload.data : null, state.notifications.isLoading = payload.isLoading },
        [SET_ALL_NOTIFICATIONS]: (state: { allNotifications: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.allNotifications.data = payload.data : null, state.allNotifications.isLoading = payload.isLoading }
    },
    actions: {
        [GET_NOTIFICATIONS]: async({ commit }: any, payload: any) => {
            commit(SET_NOTIFICATIONS, { data: null, isLoading: true })

            let result: AxiosResponse = await Notification.getNotifications(payload as GetNotification)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_NOTIFICATIONS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_NOTIFICATIONS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_NOTIFICATIONS, { isLoading: false })
                return false;
            }
        },
        [GET_ALL_NOTIFICATIONS]: async({ commit }: any, payload: any) => {
            commit(SET_ALL_NOTIFICATIONS, { data: null, isLoading: true })

            let result: AxiosResponse = await Notification.getNotifications(payload as GetNotification)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_ALL_NOTIFICATIONS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_ALL_NOTIFICATIONS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ALL_NOTIFICATIONS, { isLoading: false })
                return false;
            }
        },
        [CLEAR_NOTIFICATIONS]: async({ commit }: any, payload: any) => {
            commit(SET_NOTIFICATION, { data: null, isLoading: true })

            let result: AxiosResponse = await Notification.clearNotification(payload as ClearNotification)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_NOTIFICATION, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_NOTIFICATION, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_NOTIFICATION, { isLoading: false })
                return false;
            }
        },
        [CLEAR_NOTIFICATIONS_ISLOADING]: ({ commit }) => {
            commit(SET_NOTIFICATION, { isLoading: false })
            commit(SET_NOTIFICATIONS, { isLoading: false })
        }
    }
}

export default notification