import baseAPI from '@/api/base';
import { EditMinyanimType, AddMinyanimType, GetMinyanimType } from '../dtos/minyanim_type.dto';
import { DELETE_MINYANIM_TYPE_URL, MINYANIM_TYPE_URL, EDIT_MINYANIM_TYPE_URL, ADD_MINYANIM_TYPE_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class MinyanimType {
    static getMinyanimTypes = async(getMinyanimType: GetMinyanimType) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(MINYANIM_TYPE_URL, {
                    params: getMinyanimType
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static registerMinyanimType = async(addMinyanimType: AddMinyanimType) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(ADD_MINYANIM_TYPE_URL, {
                    ...addMinyanimType
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static editMinyanimType = async(editMinyanimType: EditMinyanimType) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(EDIT_MINYANIM_TYPE_URL, {
                    ...editMinyanimType
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static deleteMinyanimType = async(minyanimTypeId: string) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.delete(`${DELETE_MINYANIM_TYPE_URL}/${minyanimTypeId}`)

                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default MinyanimType