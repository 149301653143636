<template>
    <div>
        <div class="card">
            <div class="card-body">
                <Fullcalendar ref="fullCalendar" :options="calendarOptions">
                </Fullcalendar>
            </div>
        </div>
    </div>
</template>
<script>
import '@fullcalendar/core'
import Fullcalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import bootstrapPlugin from '@fullcalendar/bootstrap';
import { GET_CALENDAR_DONATIONS } from '@/store/action_types'
import { USER_ROLE_ADMIN } from '@/utils/const'

export default {
    components: {
        Fullcalendar
    },
    data() {
        return {
            calendarOptions: {
                plugins: [ dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, bootstrapPlugin ],
                initialView: 'dayGridMonth',
                selectable: true,
                weekends: true,
                select: this.handleSelect,
                eventClick: this.handleEventClick,
                // eventRender: this.handleMonthChange,
                events: [],
                themeSystem: 'bootstrap',
                headerToolbar: {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                },
                customButtons: { 
                    prev: { // this overrides the prev button
                        text: "PREV", 
                        click: () => {  
                            let calendarApi = this.$refs.fullCalendar.getApi();
                            calendarApi.prev();
                            this.getCalendarDonations(calendarApi.getDate())
                        }
                    },
                    next: { // this overrides the next button
                        text: "PREV",
                        click: () => {
                            let calendarApi = this.$refs.fullCalendar.getApi();
                            calendarApi.next();
                            
                            this.getCalendarDonations(calendarApi.getDate())
                        }
                    },
                    today: { // this overrides the next button
                        text: "TODAY",
                        click: () => {
                            let calendarApi = this.$refs.fullCalendar.getApi();
                            calendarApi.today();

                            this.getCalendarDonations(calendarApi.getDate())
                        }
                    }
                }
            }
        }
    },
    computed: {
        isLoading() {
            return this.$store.getters.calendarDonations.isLoading
        },
        account() {
            return this.$store.getters.account.data
        },
        calendarDonations() {
            return this.$store.getters.calendarDonations.data
        }
    },
    methods: {
        handleSelect() {

        },
        handleEventClick() {

        },
        getCalendarDonations(date) {
            if (this.account.role == USER_ROLE_ADMIN) {
                this.$store.dispatch(GET_CALENDAR_DONATIONS, { ...date != null ? { calendarDate: new Date(new Date(date).setHours(new Date(date).getHours() + (-1 * (new Date(date).getTimezoneOffset() / 60)))) } : {} }).then((e) => this.calendarOptions.events = e)
            } else {
                this.$store.dispatch(GET_CALENDAR_DONATIONS, { org: this.$route.params.id, ...date != null ? { calendarDate: new Date(new Date(date).setHours(new Date(date).getHours() + (-1 * (new Date(date).getTimezoneOffset() / 60)))) } : {} }).then((e) => this.calendarOptions.events = e)
            }
        }
    },
    mounted() {
        this.getCalendarDonations()
        this.$watch(
            () => {
                return this.$refs.cc;
            },
            (val) => {
                console.log(val);
            }
        );
    }
}
</script>