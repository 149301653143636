import axios, { AxiosResponse } from 'axios'
import { SET_SYNAGOGUE_SEATS, SET_SYNAGOGUE_SEAT, SET_ERRORMSG } from '../mutation_types'
import {
    CLEAR_SYNAGOGUE_SEATS_ISLOADING,
    GET_SYNAGOGUE_SEATS
} from '../action_types'
import { StateObject } from '@/dtos/state.dto'
import Synagogue from '@/services/synagogue_seat'
import { Module } from 'vuex'
import { GetSynagogueSeat } from '@/dtos/synagogue_seat.dto'

const synagogueSeat: Module<any, {}> = {
    state: {
        synagogueSeat: {
            data: null,
            isLoading: false
        },
        synagogueSeats: {
            data: [],
            isLoading: false
        }
    },
    getters: {
        synagogueSeat: (state: { synagogueSeat: any }) => state.synagogueSeat,
        synagogueSeats: (state: { synagogueSeats: any }) => state.synagogueSeats
    },
    mutations: {
        [SET_SYNAGOGUE_SEAT]: (state: { synagogueSeat: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.synagogueSeat.data = payload.data : null, state.synagogueSeat.isLoading = payload.isLoading },
        [SET_SYNAGOGUE_SEATS]: (state: { synagogueSeats: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.synagogueSeats.data = payload.data : null, state.synagogueSeats.isLoading = payload.isLoading }
    },
    actions: {
        [GET_SYNAGOGUE_SEATS]: async({ commit }: any, payload: any) => {
            commit(SET_SYNAGOGUE_SEATS, { data: null, isLoading: true })

            let result: AxiosResponse = await Synagogue.getSynagogueSeats(payload as GetSynagogueSeat)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_SYNAGOGUE_SEATS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_SYNAGOGUE_SEATS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_SYNAGOGUE_SEATS, { isLoading: false })
                return false;
            }
        },
        [CLEAR_SYNAGOGUE_SEATS_ISLOADING]: ({ commit }) => {
            commit(SET_SYNAGOGUE_SEAT, { isLoading: false })
            commit(SET_SYNAGOGUE_SEATS, { isLoading: false })
        }
    }
}

export default synagogueSeat