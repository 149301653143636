import baseAPI from '@/api/base';
import { EditShiurim, GetShiurim, RegisterShiurim } from '../dtos/shiurim.dto';
import { DELETE_SHIURIM_URL, SHIURIM_URL, EDIT_SHIURIM_URL, ADD_SHIURIM_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class Shiurim {
    static getShiurims = async(getShiurim: GetShiurim) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(SHIURIM_URL, {
                    params: getShiurim
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static registerShiurim = async(registerShiurim: RegisterShiurim) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(ADD_SHIURIM_URL, {
                    ...registerShiurim
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static editShiurim = async(editShiurim: EditShiurim) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(EDIT_SHIURIM_URL, {
                    ...editShiurim
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static deleteShiurim = async(donationId: string) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.delete(`${DELETE_SHIURIM_URL}/${donationId}`)

                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default Shiurim