import axios, { AxiosResponse } from 'axios'
import { SET_SYNAGOGUE_SEAT_PRICE_CATEGORIES, SET_SYNAGOGUE_SEAT_PRICE_CATEGORY, SET_ERRORMSG } from '../mutation_types'
import {
    CLEAR_SYNAGOGUE_SEAT_PRICE_CATEGORIES_ISLOADING,
    DELETE_SYNAGOGUE_SEAT_PRICE_CATEGORY,
    EDIT_SYNAGOGUE_SEAT_PRICE_CATEGORY,
    GET_SYNAGOGUE_SEAT_PRICE_CATEGORIES,
    ADD_SYNAGOGUE_SEAT_PRICE_CATEGORY,
} from '../action_types'
import { StateObject } from '@/dtos/state.dto'
import SynagogueSeatPriceCategory from '@/services/synagogue_seat_price_category'
import { Module } from 'vuex'
import { EditSynagogueSeatPriceCategory, AddSynagogueSeatPriceCategory, GetSynagogueSeatPriceCategory } from '@/dtos/synagogue_seat_price_category'

const synagogueSeatPriceCategory: Module<any, {}> = {
    state: {
        synagogueSeatPriceCategory: {
            data: null,
            isLoading: false
        },
        synagogueSeatPriceCategories: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        synagogueSeatPriceCategory: (state: { synagogueSeatPriceCategory: any }) => state.synagogueSeatPriceCategory,
        synagogueSeatPriceCategories: (state: { synagogueSeatPriceCategories: any }) => state.synagogueSeatPriceCategories
    },
    mutations: {
        [SET_SYNAGOGUE_SEAT_PRICE_CATEGORY]: (state: { synagogueSeatPriceCategory: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.synagogueSeatPriceCategory.data = payload.data : null, state.synagogueSeatPriceCategory.isLoading = payload.isLoading },
        [SET_SYNAGOGUE_SEAT_PRICE_CATEGORIES]: (state: { synagogueSeatPriceCategories: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.synagogueSeatPriceCategories.data = payload.data : null, state.synagogueSeatPriceCategories.isLoading = payload.isLoading }
    },
    actions: {
        [ADD_SYNAGOGUE_SEAT_PRICE_CATEGORY]: async({ commit }: any, payload: any) => {
            commit(SET_SYNAGOGUE_SEAT_PRICE_CATEGORY, { data: null, isLoading: true })

            let result: AxiosResponse = await SynagogueSeatPriceCategory.registerSynagogueSeatPriceCategory(payload as AddSynagogueSeatPriceCategory)
          
            if(result) {
                if (result.data.status) {
                    commit(SET_SYNAGOGUE_SEAT_PRICE_CATEGORY, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_SYNAGOGUE_SEAT_PRICE_CATEGORY, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_SYNAGOGUE_SEAT_PRICE_CATEGORY, { isLoading: false })
                return false;
            }
        },
        [GET_SYNAGOGUE_SEAT_PRICE_CATEGORIES]: async({ commit }: any, payload: any) => {
            commit(SET_SYNAGOGUE_SEAT_PRICE_CATEGORIES, { data: null, isLoading: true })

            let result: AxiosResponse = await SynagogueSeatPriceCategory.getSynagogueSeatPriceCategories(payload as GetSynagogueSeatPriceCategory)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_SYNAGOGUE_SEAT_PRICE_CATEGORIES, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_SYNAGOGUE_SEAT_PRICE_CATEGORIES, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_SYNAGOGUE_SEAT_PRICE_CATEGORIES, { isLoading: false })
                return false;
            }
        },
        [EDIT_SYNAGOGUE_SEAT_PRICE_CATEGORY]: async({ commit }: any, payload: any) => {
            commit(SET_SYNAGOGUE_SEAT_PRICE_CATEGORY, { data: null, isLoading: true })

            let result: AxiosResponse = await SynagogueSeatPriceCategory.editSynagogueSeatPriceCategory(payload as EditSynagogueSeatPriceCategory)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_SYNAGOGUE_SEAT_PRICE_CATEGORY, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_SYNAGOGUE_SEAT_PRICE_CATEGORY, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_SYNAGOGUE_SEAT_PRICE_CATEGORY, { isLoading: false })
                return false;
            }
        },
        [DELETE_SYNAGOGUE_SEAT_PRICE_CATEGORY]: async({ commit }: any, payload: string) => {
            commit(SET_SYNAGOGUE_SEAT_PRICE_CATEGORY, { data: null, isLoading: true })

            let result: AxiosResponse = await SynagogueSeatPriceCategory.deleteSynagogueSeatPriceCategory(payload)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_SYNAGOGUE_SEAT_PRICE_CATEGORY, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_SYNAGOGUE_SEAT_PRICE_CATEGORY, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_SYNAGOGUE_SEAT_PRICE_CATEGORY, { isLoading: false })
                return false;
            }
        },
        [CLEAR_SYNAGOGUE_SEAT_PRICE_CATEGORIES_ISLOADING]: ({ commit }) => {
            commit(SET_SYNAGOGUE_SEAT_PRICE_CATEGORY, { isLoading: false })
            commit(SET_SYNAGOGUE_SEAT_PRICE_CATEGORIES, { isLoading: false })
        }
    }
}

export default synagogueSeatPriceCategory