<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeEditUserModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Update Account</span>
            <br>
            <small v-if="this.type == EDIT_ACCOUNT_TYPE_SELF" class="text-center text-warning">You are updating your own
                account.</small>
            <br>
            <div class="modal__content">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <p v-if="errorMessage" class="text-center text-danger auth-status-msg">{{ errorMessage
                                    }}</p>
                                <Form @submit="editUser">
                                    <div class="form-group">
                                        <label for="fname">First Name</label>
                                        <Field v-model="editUserObj.fname" rules="required" type="text" name="fname"
                                            class="form-control" id="fname" placeholder="First Name" />
                                        <ErrorMessage name="fname" class="form-text text-danger" />
                                    </div>
                                    <div class="form-group">
                                        <label for="lname">Last Name</label>
                                        <Field v-model="editUserObj.lname" rules="required" type="text" name="lname"
                                            class="form-control" id="lname" placeholder="Last Name" />
                                        <ErrorMessage name="lname" class="form-text text-danger" />
                                    </div>
                                    <div class="form-group">
                                        <label for="email">Email</label>
                                        <Field v-model="editUserObj.email" rules="required" type="email" name="email"
                                            class="form-control" id="email" placeholder="Email Address" />
                                        <ErrorMessage name="email" class="form-text text-danger" />
                                    </div>
                                    <div class="form-group">
                                        <label for="password">Password</label>
                                        <Field v-model="editUserObj.password" rules="" type="password" name="password"
                                            class="form-control" id="password" placeholder="Password" />
                                        <ErrorMessage name="password" class="form-text text-danger" />
                                    </div>
                                    <div v-if="selectedUser.id != account.id && account.role == USER_ROLE_ADMIN"
                                        class="form-group">
                                        <label for="role">Role</label>
                                        <select v-model="editUserObj.role" name="role" class="form-control"
                                            rules="required">
                                            <option>---</option>
                                            <template v-bind:key="index" v-for="(role, index) in USER_ROLES">
                                                <option>{{ role }}</option>
                                            </template>
                                        </select>
                                        <ErrorMessage name="role" class="form-text text-danger" />
                                    </div>
                                    <div class="row justify-content-end modal__actions">
                                        <button type="submit" class="col-12 btn btn-save"
                                            :disabled="userIsLoading ? true : false">
                                            <span v-if="userIsLoading" class="spinner-border spinner-border-sm"
                                                role="status" aria-hidden="true"></span>
                                            <span v-if="!userIsLoading">Save</span>
                                        </button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import { EDIT_USER, UPDATE_ACCOUNT } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';
import { DONTATION_TYPE, EDIT_ACCOUNT_TYPE_SELF, USER_ROLES, USER_ROLE_ADMIN, USER_ROLE_USER } from '@/utils/const';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        selectedUser: Object,
        type: String
    },
    components: {
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage
    },
    data () {
        return {
            DONTATION_TYPE,
            EDIT_ACCOUNT_TYPE_SELF,
            USER_ROLES,
            USER_ROLE_ADMIN,
            USER_ROLE_USER,
            showModal: true,
            editUserObj: {
                id: null,
                fname: null,
                lname: null,
                role: null,
                email: null,
                password: null
            }
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        user() {
              return this.$store.getters.user.data
        },
        userIsLoading() {
            return this.$store.getters.user.isLoading
        },
        account() {
            return this.$store.getters.account.data
        }
    },
    methods: {
        setValues() {
            this.editUserObj.id = this.selectedUser.id
            this.editUserObj.fname = this.selectedUser.fname
            this.editUserObj.lname = this.selectedUser.lname
            this.editUserObj.email = this.selectedUser.email
            this.editUserObj.role = this.selectedUser.role
        },
        editUser() {
            if (this.editUserObj.password) {
                this.editUserObj.password = this.editUserObj.password.trim()
            }

            let payload = {
                id: this.editUserObj.id,
                fname: this.editUserObj.fname,
                lname: this.editUserObj.lname,
                role: this.editUserObj.role,
                email: this.editUserObj.email,
                ...((this.editUserObj.password != null && this.editUserObj.password.length) > 0 ? { password: this.editUserObj.password } : {}),
            }
            
            if(this.type == EDIT_ACCOUNT_TYPE_SELF) {
                this.$store.dispatch(UPDATE_ACCOUNT, payload).then((result) => {
                    if(result) {
                        this.$emit('closeEditUserModal')
                    }
                }) 
            }

            this.$store.dispatch(EDIT_USER, payload).then((result) => {
                if(result) {
                    this.$emit('closeEditUserModal')
                    this.$emit('refreshUsers')
                }
            })           
        },
    },
    created() {
        this.setValues()
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    position: relative;
    left: 35%;
    margin-top: 50px;
}
::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
}
.modal__title {
    font-size: 18px;
    font-weight: 600;
}
.modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}

.modal__content .nav-tabs .nav-link {
    font-size: 15px!important;
}

.btn-save {
    height: 40px;
    margin-top: 10px;
    background-color: #14243D;
}
</style>