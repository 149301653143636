<template>
    <div class="card organization-nav">
        <div class="card-body">
            <div class="row">
                <div class="">
                    <router-link :class="[route == MEMBER_DETAILS_ROUTE ? 'btn-add' : null, 'btn btn-link']"
                        :to="{ name: MEMBER_DETAILS_ROUTE }">Details</router-link>
                </div>
                <div class="">
                    <router-link :class="[route == MEMBER_DONATIONS_ROUTE ? 'btn-add' : null, 'btn btn-link']"
                        :to="{ name: MEMBER_DONATIONS_ROUTE }">Donations</router-link>
                </div>
                <div v-if="moduleSettings">
                    <div v-if="moduleSettings.includes(MODULE_SETTING_PURPOSE)" class="">
                        <router-link :class="[route == MEMBER_PURPOSES_ROUTE ? 'btn-add' : null, 'btn btn-link']"
                            :to="{ name: MEMBER_PURPOSES_ROUTE }">Purpose</router-link>
                    </div>
                </div>
                <div v-if="moduleSettings">
                    <div v-if="moduleSettings.includes(MODULE_SETTING_SEATS)" class="">
                        <router-link :class="[route == MEMBER_SEATS_ROUTE ? 'btn-add' : null, 'btn btn-link']"
                            :to="{ name: MEMBER_SEATS_ROUTE }">Seats</router-link>
                    </div>
                </div>
                <!-- <div>
                    <router-link :class="[[MEMBER_INVOICES_ROUTE, MEMBER_INVOICE_PAYMENTS_ROUTE].includes(route) ? 'btn-add' : null, 'btn btn-link']"
                        :to="{ name: MEMBER_INVOICES_ROUTE }">Statements</router-link>
                </div> -->
                <div v-if="moduleSettings">
                    <div v-if="moduleSettings.includes(MODULE_SETTING_PLEDGES)" class="">
                        <router-link :class="[route == MEMBER_RECEIPTS_ROUTE ? 'btn-add' : null, 'btn btn-link']"
                            :to="{ name: MEMBER_RECEIPTS_ROUTE }">Receipts</router-link>
                    </div>
                </div>
                <div v-if="moduleSettings">
                    <div v-if="moduleSettings.includes(MODULE_SETTING_PLEDGES)" class="">
                        <router-link :class="[route == MEMBER_PLEDGES_ROUTE ? 'btn-add' : null, 'btn btn-link']"
                            :to="{ name: MEMBER_PLEDGES_ROUTE }">Pledges</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { MEMBER_DETAILS_ROUTE, MEMBER_DONATIONS_ROUTE, MEMBER_PURPOSES_ROUTE, MEMBER_SEATS_ROUTE, MEMBER_INVOICES_ROUTE, MEMBER_INVOICE_PAYMENTS_ROUTE, MEMBER_RECEIPTS_ROUTE, MEMBER_PLEDGES_ROUTE } from '@/router/routes'
import { MODULE_SETTING_SCREENS, MODULE_SETTING_MEMBER, MODULE_SETTING_PURPOSE, MODULE_SETTING_SEATS, MODULE_SETTING_MINYANIM, MODULE_SETTING_PLEDGES } from '@/utils/const';

export default {
    data() {
        return {
            MEMBER_DETAILS_ROUTE,
            MEMBER_DONATIONS_ROUTE,
            MEMBER_PURPOSES_ROUTE,
            MEMBER_SEATS_ROUTE,
            MEMBER_INVOICES_ROUTE,
            MEMBER_INVOICE_PAYMENTS_ROUTE,
            MEMBER_RECEIPTS_ROUTE,
            MEMBER_PLEDGES_ROUTE,
            MODULE_SETTING_SCREENS,
            MODULE_SETTING_MEMBER,
            MODULE_SETTING_MINYANIM,
            MODULE_SETTING_SEATS,
            MODULE_SETTING_PLEDGES
        }
    },
    computed: {
        route() {
            return this.$route.name
        },
        moduleSettings() {
            if (this.$store.getters.moduleSettings.data != null) {
                return this.$store.getters.moduleSettings.data.map((e) => e.setting)
            }
        },
    }
}
</script>
<style scoped>
.content .organization-nav {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 15px;
    border: 1px solid #E0E2E7
}

.content .organization-nav .row {
    margin-left: 0px;
    margin-right: -8px;
}

.content .organization-nav .row>div {
    margin-right: 10px;
}

.content .organization-nav .btn {
    font-size: 16px;
    color: #667085 !important;
    text-decoration: unset;
    border-radius: 7px;
}

.dashboard-body .btn-add {
    font-weight: 600;
    color: #14243D !important;
    background-color: #F4F4F8 !important;
    border-color: #F4F4F8 !important;
    cursor: pointer;
}

.content .organization-nav .btn:hover {
    background-color: #F4F4F8;
}

.content .organization-nav .card-body {
    padding: 4px !important;
    border-radius: 8px;
}
</style>