import store from '../store';
import router from '../router';
import { RouteLocationNormalized } from 'vue-router';
import { CLEAR_DISPLAY_DONATIONS, GET_AUTH_ACCOUNT, GET_MODULE_SETTINGS, UPDATE_ROUTER_LOADER } from '../store/action_types'
import { LOGIN_ROUTE, DASHBOARD_ROUTE } from './routes'

export const updateAuthAccount = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: Function) => {
    store.dispatch(GET_AUTH_ACCOUNT)
    next();
}

export const updateSettings = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: Function) => {
    store.dispatch(GET_MODULE_SETTINGS)
    next()
}

export const beforeHomeRouteEnter = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: Function) => {
    if ((from.name == LOGIN_ROUTE) || (from.name == null && store.getters.sessionToken != null)) {
        router.push({ name: DASHBOARD_ROUTE })
    }
    next()
}

export const clearDisplayDonations = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: Function) => {
    console.log('Preparing to clear display donations');
    store.dispatch(CLEAR_DISPLAY_DONATIONS)
    next();
}