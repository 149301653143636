import axios, { AxiosResponse } from 'axios'
import { SET_MEMBER_STATEMENT_FILE, SET_MEMBER_STATEMENT_FILES, SET_ERRORMSG } from '../../mutation_types'
import {
    GET_MEMBER_STATEMENT_FILES,
    GENERATE_MEMBER_STATEMENT_FILE,
    CLEAR_MEMBER_STATEMENTS_ISLOADING
} from '../../action_types'
import { StateObject } from '@/dtos/state.dto'
import MemberStatement from '@/services/report/member_statement'
import { Module } from 'vuex'
import { GenerateMemberStatementFile } from '@/dtos/report/member_statement.dto'
import ReportFile from '@/services/report/report_files';
import { GetReportFiles } from '@/dtos/report/report_file'

const report: Module<any, {}> = {
    state: {
        memberStatementFile: {
            data: null,
            isLoading: false
        },
        memberStatementFiles: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        memberStatementFile: (state: { memberStatementFile: any }) => state.memberStatementFile,
        memberStatementFiles: (state: { memberStatementFiles: any }) => state.memberStatementFiles
    },
    mutations: {
        [SET_MEMBER_STATEMENT_FILE]: (state: { memberStatementFile: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.memberStatementFile.data = payload.data : null, state.memberStatementFile.isLoading = payload.isLoading },
        [SET_MEMBER_STATEMENT_FILES]: (state: { memberStatementFiles: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.memberStatementFiles.data = payload.data : null, state.memberStatementFiles.isLoading = payload.isLoading }
    },
    actions: {
        [GENERATE_MEMBER_STATEMENT_FILE]: async({ commit }: any, payload: any) => {
            commit(SET_MEMBER_STATEMENT_FILE, { data: null, isLoading: true })

            let result: AxiosResponse = await MemberStatement.generateMemberStatementFile(payload as GenerateMemberStatementFile)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_MEMBER_STATEMENT_FILE, { data: result.data.data, isLoading: false })
                    return result.data.data;
                }
                commit(SET_MEMBER_STATEMENT_FILE, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return null;
            } else {
                commit(SET_MEMBER_STATEMENT_FILE, { isLoading: false })
                return null;
            }
        },
        [GET_MEMBER_STATEMENT_FILES]: async({ commit }: any, payload: any) => {
            commit(SET_MEMBER_STATEMENT_FILES, { data: null, isLoading: true })

            let result: AxiosResponse = await ReportFile.getReportFiles(payload as GetReportFiles)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_MEMBER_STATEMENT_FILES, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_MEMBER_STATEMENT_FILES, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_MEMBER_STATEMENT_FILES, { isLoading: false })
                return false;
            }
        },
        [CLEAR_MEMBER_STATEMENTS_ISLOADING]: ({ commit }) => {
            commit(SET_MEMBER_STATEMENT_FILE, { isLoading: false })
            commit(SET_MEMBER_STATEMENT_FILES, { isLoading: false })
        }
    }
}

export default report