import baseAPI from '@/api/base';
import { EditOrganization, GetOrganization, RegisterOrganization } from '../dtos/organization.dto';
import { DELETE_ORGANIZATION_URL, ORGANIZATION_URL, EDIT_ORGANIZATION_URL, REGISTER_ORGANIZATION_URL, TOGGLE_ORGANIZATION_IS_ACTIVE_URL, HEADER_IMG_ORGANIZATION_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class Organization {
    static getOrganizations = async(getOrganization: GetOrganization | null) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(ORGANIZATION_URL, {
                    params: getOrganization
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static registerOrganization = async(formData: FormData) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(REGISTER_ORGANIZATION_URL, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static editOrganization = async(formData: FormData) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(EDIT_ORGANIZATION_URL, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static uploadHeaderImage = async(formData: FormData) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(HEADER_IMG_ORGANIZATION_URL, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static toggleOrganizationIsActive = async(editOrganization: EditOrganization) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(TOGGLE_ORGANIZATION_IS_ACTIVE_URL, {
                    ...editOrganization
                })
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static deleteOrganization = async(donationId: string) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.delete(`${DELETE_ORGANIZATION_URL}/${donationId}`)

                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default Organization