import baseAPI from '@/api/base';
import { EditMember, AddMember, GetMember } from '../dtos/member.dto';
import { DELETE_MEMBER_URL, MEMBER_URL, EDIT_MEMBER_URL, ADD_MEMBER_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class Member {
    static getMembers = async(getMember: GetMember) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(MEMBER_URL, {
                    params: getMember
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static registerMember = async(addMember: AddMember) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(ADD_MEMBER_URL, {
                    ...addMember
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static editMember = async(editMember: EditMember) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(EDIT_MEMBER_URL, {
                    ...editMember
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static deleteMember = async(donationId: string) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.delete(`${DELETE_MEMBER_URL}/${donationId}`)

                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default Member