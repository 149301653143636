import axios, { AxiosResponse } from 'axios'
import { SET_SYNAGOGUES, SET_SYNAGOGUE, SET_ERRORMSG } from '../mutation_types'
import {
    CLEAR_SYNAGOGUES_ISLOADING,
    DELETE_SYNAGOGUE,
    EDIT_SYNAGOGUE,
    GET_SYNAGOGUES,
    REGISTER_SYNAGOGUE,
} from '../action_types'
import { StateObject } from '@/dtos/state.dto'
import Synagogue from '@/services/synagogue'
import { Module } from 'vuex'
import { EditSynagogue, AddSynagogue, GetSynagogue } from '@/dtos/synagogue.dto'

const synagogue: Module<any, {}> = {
    state: {
        synagogue: {
            data: null,
            isLoading: false
        },
        synagogues: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        synagogue: (state: { synagogue: any }) => state.synagogue,
        synagogues: (state: { synagogues: any }) => state.synagogues
    },
    mutations: {
        [SET_SYNAGOGUE]: (state: { synagogue: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.synagogue.data = payload.data : null, state.synagogue.isLoading = payload.isLoading },
        [SET_SYNAGOGUES]: (state: { synagogues: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.synagogues.data = payload.data : null, state.synagogues.isLoading = payload.isLoading }
    },
    actions: {
        [REGISTER_SYNAGOGUE]: async({ commit }: any, payload: any) => {
            commit(SET_SYNAGOGUE, { data: null, isLoading: true })

            let result: AxiosResponse = await Synagogue.registerSynagogue(payload as AddSynagogue)
          
            if(result) {
                if (result.data.status) {
                    commit(SET_SYNAGOGUE, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_SYNAGOGUE, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_SYNAGOGUE, { isLoading: false })
                return false;
            }
        },
        [GET_SYNAGOGUES]: async({ commit }: any, payload: any) => {
            commit(SET_SYNAGOGUES, { data: null, isLoading: true })

            let result: AxiosResponse = await Synagogue.getSynagogues(payload as GetSynagogue)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_SYNAGOGUES, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_SYNAGOGUES, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_SYNAGOGUES, { isLoading: false })
                return false;
            }
        },
        [EDIT_SYNAGOGUE]: async({ commit }: any, payload: any) => {
            commit(SET_SYNAGOGUE, { data: null, isLoading: true })

            let result: AxiosResponse = await Synagogue.editSynagogue(payload as EditSynagogue)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_SYNAGOGUE, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_SYNAGOGUE, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_SYNAGOGUE, { isLoading: false })
                return false;
            }
        },
        [DELETE_SYNAGOGUE]: async({ commit }: any, payload: string) => {
            commit(SET_SYNAGOGUE, { data: null, isLoading: true })

            let result: AxiosResponse = await Synagogue.deleteSynagogue(payload)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_SYNAGOGUE, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_SYNAGOGUE, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_SYNAGOGUE, { isLoading: false })
                return false;
            }
        },
        [CLEAR_SYNAGOGUES_ISLOADING]: ({ commit }) => {
            commit(SET_SYNAGOGUE, { isLoading: false })
            commit(SET_SYNAGOGUES, { isLoading: false })
        }
    }
}

export default synagogue