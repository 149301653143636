import baseAPI from '@/api/base';
import { EditAlert, GetAlert, AddAlert } from '@/dtos/alert.dto';
import { DELETE_ALERT_URL, ALERT_URL, EDIT_ALERT_URL, ADD_ALERT_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class Alert {
    static getAlerts = async(getAlert: GetAlert) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(ALERT_URL, {
                    params: getAlert
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static registerAlert = async(registerAlert: AddAlert) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(ADD_ALERT_URL, {
                    ...registerAlert
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static editAlert = async(editAlert: EditAlert) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(EDIT_ALERT_URL, {
                    ...editAlert
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static deleteAlert = async(donationId: string) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.delete(`${DELETE_ALERT_URL}/${donationId}`)

                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default Alert