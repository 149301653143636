<template>
    <div class="dashboard-body">
        <div class="row">
            <sidenav class="custom-col-width-2" />
            <div style="padding-left: 0px!important" class="custom-col-width-10 content-section dashboard-content">
                <navbar />
                <viewTicketModal v-if="showViewTicketModal" :selectedTicketToView="selectedTicketToView"
                    @close-view-ticket-modal="showViewTicketModal = false" @refresh-tickets="getTickets()" />
                <addTicketModal v-if="showAddTicketModal" @close-add-ticket-modal="showAddTicketModal = false"
                    @refresh-tickets="getTickets()" />
                <editTemplateModal v-if="showEditTemplateModal" :selectedTemplate="selectedTemplate"
                    @close-edit-template-modal="showEditTemplateModal = false" @refresh-tickets="getTickets()" />
                <deleteTicketModal v-if="showDeleteTicketModal" :selectedTicketId="selectedTicketId"
                    @close-delete-ticket-modal="showDeleteTicketModal = false" @refresh-tickets="getTickets()" />
                <div class="content">
                    <br>
                    <div class="row button-grp justify-content-between">
                        <div class="col">
                            <h4>Tickets</h4>
                        </div>
                        <div class="col">
                            <button v-on:click="showAddTicketModal = true" class="btn btn-add"><i
                                    class="pi pi-plus"></i> New Ticket</button>
                        </div>
                    </div>
                    <div class="row filter-grp">
                        <div class="col-lg-9 col-md-7">
                            <i class="pi pi-search" aria-hidden="true"></i>
                            <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
                        </div>
                        <div class="col-lg-3 col-md-5">
                            <div class="row">
                                <div class="btn col-6"><img src="../assets/icons/calendar-icon.svg" alt=""> Select Date
                                </div>
                                <!-- <div class="btn col"><img src="../assets/icons/filter-icon.svg" alt="">
                                    Filters</div> -->
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 offset-lg-0">
                            <div class="card">
                                <div class="card-body">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#No.</th>
                                                <th scope="col">Subject</th>
                                                <th scope="col">Organization</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Last Modified</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-bind:key="index" v-for="(ticket, index) in tickets">
                                                <tr :class="[isOdd(index + 1) ? 'striped' : null]">
                                                    <td>{{ ticket.number }}</td>
                                                    <td>{{ ticket.subject }}</td>
                                                    <td>{{ ticket.organization.name }}, {{ ticket.user.fname }} {{ ticket.user.lname }}</td>
                                                    <td>
                                                        <span v-if="ticket.status == TICKET_STATUS_OPEN"
                                                            class="badge badge-active text-center">Open</span>
                                                        <span v-else
                                                            class="badge badge-inactive text-center">Closed</span>
                                                    </td>
                                                    <td>{{ $filters.getDateAndTime(ticket.lastModifiedAt) }}</td>
                                                    <td>
                                                        <i v-on:click="selectTicketToView(ticket)"
                                                            class="pi pi-eye text-secondary"></i>
                                                        <!-- <i v-on:click="selectTemplateToEdit(ticket)"
                                                            class="pi pi-pencil text-primary"></i> -->
                                                        <i v-on:click="selectTemplateToDelete(ticket.id)"
                                                            class="pi pi-trash text-danger"></i>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import sidenav from '@/components/Sidenav.vue'
import navbar from '@/components/Navbar.vue'
import viewTicketModal from '@/components/modals/Ticket/View_TIcket_Model.vue'
import addTicketModal from '@/components/modals/Ticket/Add_Ticket_Modal.vue'
import editTemplateModal from '@/components/modals/Template/Edit_Template_Modal.vue'
import deleteTicketModal from '@/components/modals/Ticket/Delete_Ticket_Modal.vue'
import { GET_TICKETS } from '@/store/action_types'
import { USER_ROLE_ADMIN, TICKET_STATUS_OPEN, TICKET_STATUS_CLOSED } from '../utils/const';

export default {
    title: 'Templates',
    components: {
        sidenav,
        navbar,
        viewTicketModal,
        editTemplateModal,
        deleteTicketModal,
        addTicketModal
    },
    data() {
        return {
            USER_ROLE_ADMIN,
            TICKET_STATUS_OPEN,
            TICKET_STATUS_CLOSED,
            showAddTicketModal: false,
            showEditTemplateModal: false,
            showDeleteTicketModal: false,
            showViewTicketModal: false,
            selectedTemplate: null,
            selectedTicketId: null,
            selectedTicketToView: null
        }
    },
    computed: {
        isLoading() {
            return this.$store.getters.tickets.isLoading
        },
        tickets() {
            return this.$store.getters.tickets.data
        },
        account() {
            return this.$store.getters.account.data
        }
    },
    methods: {
        isOdd(number) {
            return number % 2 !== 0;
        },
        selectTemplateToEdit(template) {
            this.selectedTemplate = template
            this.showEditTemplateModal = true
        },
        selectTemplateToDelete(templateId) {
            this.selectedTicketId = templateId
            this.showDeleteTicketModal = true
        },
        selectTicketToView(ticket) {
            this.selectedTicketToView = ticket
            this.showViewTicketModal = true
        },
        getTickets() {
            if (this.account.role == USER_ROLE_ADMIN) {
                this.$store.dispatch(GET_TICKETS)
            } else {
                this.$store.dispatch(GET_TICKETS, { org: this.account.org })
            }
        },
        switchTemplateView(type) {
            this.selectedView = type
        }
    },
    mounted() {
        this.getTickets()
    }
}
</script>
<style scoped>
.content .card,
.content .card-body {
    padding: 0px;
}

.content .card table small {
    margin-left: 10px;
    margin-right: 10px;
}

.content .card table .pi-eye,
.content .card table .pi-trash,
.content .card table .pi-pencil {
    cursor: pointer;
}

.custom-col-width-2 {
    width: 16.66%;
}

.custom-col-width-10 {
    width: 83.3%;
}

@media (max-width: 1000px) {
    .custom-col-width-2 {
        width: 12%;
    }

    .custom-col-width-10 {
        width: 88%;
    }
}
</style>