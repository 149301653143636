import axios, { AxiosResponse } from 'axios'
import { SET_INVOICE_ITEMS, SET_INVOICE_ITEM, SET_ERRORMSG, SET_INVOICE_ITEM_DETAILS } from '../mutation_types'
import {
    CLEAR_INVOICE_ITEMS_ISLOADING,
    TOGGLE_INVOICE_ITEM_ISPAID,
    GET_INVOICE_ITEMS,
    GET_INVOICE_ITEM_DETAILS
} from '../action_types'
import { StateObject } from '@/dtos/state.dto'
import InvoiceItem from '@/services/invoice_item'
import { Module } from 'vuex'
import { EditInvoiceItem, GetInvoiceItem } from '@/dtos/invoice_items.dto'

const invoiceItem: Module<any, {}> = {
    state: {
        invoiceItem: {
            data: null,
            isLoading: false
        },
        invoiceItems: {
            data: null,
            isLoading: false
        },
        invoiceItemDetails: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        invoiceItem: (state: { invoiceItem: any }) => state.invoiceItem,
        invoiceItems: (state: { invoiceItems: any }) => state.invoiceItems,
        invoiceItemDetails: (state: { invoiceItemDetails: any }) => state.invoiceItemDetails
    },
    mutations: {
        [SET_INVOICE_ITEM]: (state: { invoiceItem: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.invoiceItem.data = payload.data : null, state.invoiceItem.isLoading = payload.isLoading },
        [SET_INVOICE_ITEMS]: (state: { invoiceItems: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.invoiceItems.data = payload.data : null, state.invoiceItems.isLoading = payload.isLoading },
        [SET_INVOICE_ITEM_DETAILS]: (state: { invoiceItemDetails: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.invoiceItemDetails.data = payload.data : null, state.invoiceItemDetails.isLoading = payload.isLoading }
    },
    actions: {
        [GET_INVOICE_ITEMS]: async({ commit }: any, payload: any) => {
            commit(SET_INVOICE_ITEMS, { data: null, isLoading: true })

            let result: AxiosResponse = await InvoiceItem.getInvoiceItems(payload as GetInvoiceItem)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_INVOICE_ITEMS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_INVOICE_ITEMS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_INVOICE_ITEMS, { isLoading: false })
                return false;
            }
        },
        [GET_INVOICE_ITEM_DETAILS]: async({ commit }: any, payload: any) => {
            commit(SET_INVOICE_ITEM_DETAILS, { data: null, isLoading: true })

            let result: AxiosResponse = await InvoiceItem.getInvoiceItemDetails(payload as GetInvoiceItem)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_INVOICE_ITEM_DETAILS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_INVOICE_ITEM_DETAILS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_INVOICE_ITEM_DETAILS, { isLoading: false })
                return false;
            }
        },
        [TOGGLE_INVOICE_ITEM_ISPAID]: async({ commit, state }: any, payload: any) => {
            commit(SET_INVOICE_ITEM, { data: { id: payload.id }, isLoading: true })

            let result: AxiosResponse = await InvoiceItem.toggleInvoiceItemIsPaid(payload as EditInvoiceItem)
          
            if(result) {
                if (result.data.status) {
                    let index
                    state.invoiceItems.data.forEach((e: any, i: number) => {
                        if (e.id == result.data.data.id) {
                            console.log('e.id == result.data.data.id gotcha', e.id == result.data.data.id)
                            index = i
                        }
                    })
                    
                    if (index != null) state.invoiceItems.data[index].isPaid = result.data.data.isPaid
                    
                    commit(SET_INVOICE_ITEM, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_INVOICE_ITEM, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_INVOICE_ITEM, { isLoading: false })
                return false;
            }
        },
        [CLEAR_INVOICE_ITEMS_ISLOADING]: ({ commit }) => {
            commit(SET_INVOICE_ITEM, { isLoading: false })
            commit(SET_INVOICE_ITEMS, { isLoading: false })
        }
    }
}

export default invoiceItem