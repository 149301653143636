import baseAPI from '@/api/base';
import { EditTicket, GetTicket, RegisterTicket } from '../dtos/ticket.dto';
import { DELETE_TICKET_URL, TICKET_URL, EDIT_TICKET_URL, ADD_TICKET_URL, CLOSE_TICKET_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class Ticket {
    static getTicket = async(getTicket: GetTicket) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(TICKET_URL, {
                    params: getTicket
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static registerTicket = async(registerTicket: RegisterTicket) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(ADD_TICKET_URL, {
                    ...registerTicket
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static editTicket = async(editTicket: EditTicket) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(EDIT_TICKET_URL, {
                    ...editTicket
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static closeTicket = async(editTicket: EditTicket) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(`${CLOSE_TICKET_URL}/${editTicket.id}`, {
                    ...editTicket
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static deleteTicket = async(donationId: string) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.delete(`${DELETE_TICKET_URL}/${donationId}`)

                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default Ticket