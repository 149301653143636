<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeViewSeatDesignModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">View Shul Seats</span>
            <div class="modal__content">
                <editSynagogueModal v-if="showEditSynagogueModal" :selectedSynagogue="selectedSynagogue"
                    @close-edit-synagogue-modal="showEditSynagogueModal = false"
                    @refresh-synagogues="getSynagogues()" />
                <p v-if="errorMessage" class="text-center text-danger auth-status-msg">{{ errorMessage }}</p>
                <div class="card">
                    <div class="card-body">
                        <Form @submit="editSeatDesign">
                            <div class="row">
                                <div class="col-12">
                                    <div class="row justify-content-between">
                                        <div class="col">
                                            <label for="name">Your Shul floor plan</label>
                                        </div>
                                        <div class="col-md-auto zoom-controls">
                                            <button @click="zoomIn" class="btn"><font-awesome-icon
                                                    icon="fa-solid fa-magnifying-glass-plus" class="fa" /></button>
                                            <button @click="zoomOut" class="btn"><font-awesome-icon
                                                    icon="fa-solid fa-magnifying-glass-minus" class="fa" /></button>
                                        </div>
                                        <div class="col-md-auto">
                                            <button v-on:click="editSynagogueSeats()" class="btn btn-add">Edit</button>
                                        </div>
                                    </div>
                                    <div id="seats" class="">
                                        <h6 v-if="synagogueSeatsIsLoading" class="text-center">Loading...</h6>
                                        <div v-else :style="zoomStyle">
                                            <div v-for="(row, rowIndex) in matrix" :key="rowIndex" class="row">
                                                <div class="col p-1" v-for="(value, colIndex) in row" :key="colIndex">
                                                    <button type="button" class="btn btn-block"
                                                        :class="{ 'btn-seat-selected': isSelected(rowIndex, colIndex), 'btn-seat-not-selected': !isSelected(rowIndex, colIndex) }">
                                                        {{ value }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import editSynagogueModal from '@/components/modals/Synagogue/Edit_Synagogue_Modal.vue'

import { DELETE_SCREEN, GET_SYNAGOGUE_SEATS } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';
import { SEATS_MATRIX } from '@/utils/const';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faMagnifyingGlassPlus, faMagnifyingGlassMinus } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
library.add(faMagnifyingGlassPlus, faMagnifyingGlassMinus)

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        selectedSynagogue: String
    },
    components: {
        editSynagogueModal,
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage
    },
    data() {
        return {
            SEATS_MATRIX,
            showEditSynagogueModal: false,
            seatDesign: {
                name: null
            },
            rows: 20,
            cols: 20,
            matrix: [],
            selected: [],
            showModal: true,
            zoomLevel: 1
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        synagogue() {
            return this.$store.getters.synagogue.data
        },
        synagogueIsLoading() {
            return this.$store.getters.synagogue.isLoading
        },
        synagogueSeats() {
            return this.$store.getters.synagogueSeats.data
        },
        synagogueSeatsIsLoading() {
            return this.$store.getters.synagogueSeats.isLoading
        },
        zoomStyle() {
            return {
                transform: `scale(${this.zoomLevel})`,
                transformOrigin: '0% 0%', // Anchor zoom to the top-left corner
                transition: 'transform 0.3s ease'
            };
        }
    },
    methods: {
        zoomIn() {
            this.zoomLevel *= 1.1; // Increase the zoom level by 10%
        },
        zoomOut() {
            this.zoomLevel /= 1.1; // Decrease the zoom level by 10%
        },
        toggleSelection(rowIndex, colIndex) {
            const index = this.selected.findIndex(item => item.row === rowIndex && item.col === colIndex);
            if (index === -1) {
                this.selected.push({ row: rowIndex, col: colIndex });
            } else {
                this.selected.splice(index, 1);
            }
        },
        isSelected(rowIndex, colIndex) {
            return this.selected.some(item => item.row === rowIndex && item.col === colIndex);
        },
        generateMatrix() {
            this.matrix = []
            let value = 1;
            for (let i = 0; i < this.selectedSynagogue.rows; i++) {
                let row = [];
                for (let j = 0; j < this.selectedSynagogue.cols; j++) {
                    row.push(value);
                    value++;
                }
                this.matrix.push(row);
            }
        },
        setSelectedSeats() {
            this.selected = this.synagogueSeats.map((e) => {
                const seat = {
                    row: e.rowIndex,
                    col: e.colIndex
                };
                return seat;
            })
        },
        editSynagogueSeats() {
            this.showEditSynagogueModal = true
        },
        getSynagogueSeats() {
            this.$store.dispatch(GET_SYNAGOGUE_SEATS, { syng: this.selectedSynagogue.id }).then((res) => {
                this.setSelectedSeats()
            })
        },
        editSeatDesign() {
            this.$store.dispatch(DELETE_SCREEN, this.selectedScreenId).then((res) => {
                if (res) {
                    this.$emit('closeViewSeatDesignModal')
                    this.$emit('refreshScreens')
                }
            })
        },
        getSynagogues() {
            this.$store.dispatch(GET_SYNAGOGUES, { org: this.$route.params.id })
        }
    },
    mounted() {
        this.generateMatrix()
        this.getSynagogueSeats()
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 98%;
    position: relative;
    left: 1%;
    margin-top: 20px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
    max-height: 96vh;
    /* Set a maximum height for the modal content */
    overflow-y: auto;
}

::v-deep .modal__content {
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
}

#seats .row {
    width: 15000px;
    overflow-x: scroll;
}

#seats .row .col {
    max-width: 67px !important;
}

.modal__content .zoom-controls {
    position: relative;
    bottom: 5px;
}

.modal__content .zoom-controls .btn {
    font-size: 17px;
    color: #6489C4;
}

.modal__content .zoom-controls .btn:hover {
    color: #14243D;
}

.modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    margin-top: 0px;
    margin-right: 15px !important;
}

.modal__title {
    font-size: 18px;
    font-weight: 600;
}

.modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}

.modal__content .nav-tabs .nav-link {
    font-size: 15px !important;
}

.modal__content .btn-add {
    float: right;
    font-size: 13px;
    border-radius: 8px;
    color: #FFFFFF !important;
    padding: 0px, 14px, 0px, 14px!important;
    margin-bottom: 5px;
    background-color: #646F80;
    border-color: #646F80 !important;
}

.modal__content .btn-seat-not-selected {
    font-size: 10px;
    font-weight: 700;
    color: transparent;
    /* background-color: #F8F8FA; */
    background-color: transparent;
}

.modal__content .btn-seat-selected {
    font-size: 10px;
    font-weight: 700;
    background-color: #6489C4;
    color: #FFFFFF;
}

.modal__content .btn-block {
    cursor: default;
}

::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
* {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.floor-plan-col {
    margin-left: 0px;
    padding-left: 0px;
}

hr {
    border: 1px solid #EDEDF1;
}

.btn-save {
    margin-top: 10px;
    background-color: #14243D;
}

label {
    font-size: 13px;
}

button {
    font-size: 15px;
}
</style>