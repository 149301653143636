<template>
    <div class="dashboard-body">
        <div class="row">
            <sidenav class="custom-col-width-2" />
            <div style="padding-left: 0px!important" class="custom-col-width-10 content-section dashboard-content">
                <navbar />
                <div class="content">
                    <br>
                    <div class="row button-grp justify-content-between">
                        <div class="col">
                            <h4>Organization</h4>
                        </div>
                        <div class="col">

                        </div>
                    </div>
                    <div class="row justify-content-between">
                        <organizationNavbar class="" />
                    </div>
                    <div class="row">
                        <div class="col-lg-12 offset-lg-0">
                            <div class="card settings-card">
                                <div class="card-body">
                                    <div class="row justify-content-between">
                                        <div class="col">
                                            <h5 class="title">Module Settings <small class="desc text-secondary">(select
                                                    modules to allow access)</small></h5>
                                        </div>
                                        <div class="col-1">
                                            <button v-on:click="addModuleSettings()" class="col-12 btn btn-save"
                                                :disabled="moduleSettingIsLoading ? true : false">
                                                <span v-if="moduleSettingIsLoading"
                                                    class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                                <span v-if="!moduleSettingIsLoading"> Save</span>
                                            </button>
                                        </div>
                                    </div>
                                    <hr>
                                    <template v-bind:key="index" v-for="(option, index) in SETTING_OPTION_VALUES">
                                        <div class="form-check custom-checkbox">
                                            <label class="form-check-label" :for="`module${index + 1}`">
                                                <input v-model="settings" :value="option" type="checkbox"
                                                    class="form-check-input" :id="`module${index + 1}`"
                                                    :checked="checkIfSettionIsSelected(option)">
                                                <span class="checkmark"></span>
                                                {{ SETTING_OPTION_NAMES[index] }}
                                            </label>

                                            <!-- <label class="custom-control-label" :for="`region${index + 1}`">{{
                                                SETTING_OPTION_NAMES[index] }}</label> -->
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import sidenav from '@/components/Sidenav.vue'
import navbar from '@/components/Navbar.vue'
import organizationNavbar from '@/components/Organization_Navbar.vue'
import addShiurimModal from '@/components/modals/Shiurim/Add_Shiurim_Modal.vue'
import editShiurimModal from '@/components/modals/Shiurim/Edit_Shiurim_Modal.vue'
import deleteShiurimModal from '@/components/modals/Shiurim/Delete_Shiurim_Modal.vue'
import viewShiurimModal from '@/components/modals/Shiurim/View_Shiurim_Modal.vue'

import { ADD_MODULE_SETTING, GET_MODULE_SETTINGS } from '@/store/action_types'
import { SETTING_OPTION_VALUES, SETTING_OPTION_NAMES } from '@/utils/const';

export default {
    title: 'Settings',
    components: {
        sidenav,
        navbar,
        organizationNavbar,
        addShiurimModal,
        editShiurimModal,
        deleteShiurimModal,
        viewShiurimModal
    },
    data() {
        return {
            SETTING_OPTION_VALUES,
            SETTING_OPTION_NAMES,
            showAddShiurimModal: false,
            showEditShiurimModal: false,
            showDeleteShiurimModal: false,
            showShiurimMessageModal: false,
            selectedShiurim: null,
            selectedShiurimId: null,
            selectedShiurimToView: null,
            organizationId: null,
            settings: [],
            moduleSettingsObj: {
                settings: [],
                org: null
            }
        }
    },
    computed: {
        moduleSettingIsLoading() {
            return this.$store.getters.moduleSetting.isLoading
        },
        moduleSetting() {
            return this.$store.getters.moduleSetting.data
        },
        moduleSettingsIsLoading() {
            return this.$store.getters.moduleSettings.isLoading
        },
        moduleSettings() {
            return this.$store.getters.moduleSettings.data
        }
    },
    methods: {
        isOdd(number) {
            return number % 2 !== 0;
        },
        checkIfSettionIsSelected(setting) {
            this.settings.some(item => item == setting)
        },
        setOrganization() {
            this.moduleSettingsObj.org = this.$route.params.id
        },
        setValues() {
            if (this.moduleSettings != null) {
                this.settings = this.moduleSettings.map((e) => e.setting)
            }
        },
        getModuleSettings() {
            this.$store.dispatch(GET_MODULE_SETTINGS, { org: this.$route.params.id }).then((res) => {
                if (res) {
                    this.setValues()
                }
            })
        },
        addModuleSettings() {
            this.moduleSettingsObj.settings = this.settings
            this.$store.dispatch(ADD_MODULE_SETTING, this.moduleSettingsObj).then((res) => {
                this.getModuleSettings()
            })
        }
    },
    mounted() {
        this.setOrganization()
        this.getModuleSettings()
    }
}
</script>
<style scoped>
.content .card,
.content .card-body {
    padding: 0px;
}

.settings-card {
    padding: 20px!important;
    margin-top: 10px;
}

.settings-card .title {
    position: relative;
    top: 7px;
}

.settings-card .desc {
    font-size: 13px;
}

.btn-save {
    background-color: #14243D;
}

.form-check {
    padding-left: 0px;
    margin-top: 0px;
    margin-bottom: 10px;
    position: relative;
    top: 5px;
    cursor: pointer;
}

.form-check .form-check-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.form-check .form-check-label {
    cursor: pointer;
}

.custom-checkbox .checkmark {
    position: relative;
    top: 3px;
    height: 18px;
    width: 18px;
    background-color: #F9F9FC;
    border: 1px solid #cfd3dd;
    border-radius: 4px;
    display: inline-block;
    margin-right: 10px;

}

.custom-checkbox .form-check-input:checked+.checkmark {
    background-color: #14243D;
}

.custom-checkbox .checkmark::after {
    content: "";
    position: absolute;
    display: none;
}

.custom-checkbox .form-check-input:checked+.checkmark::after {
    display: block;
}

.custom-checkbox .checkmark::after {
    left: 6px;
    top: 3px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.custom-col-width-2 {
    width: 16.66%;
}

.custom-col-width-10 {
    width: 83.3%;
}

@media (max-width: 1000px) {
    .custom-col-width-2 {
        width: 12%;
    }

    .custom-col-width-10 {
        width: 88%;
    }
}
</style>