import baseAPI from '@/api/base';
import { RegisterDonation } from '../dtos/donation.dto';
import { GET_DASHBOARD_STATS_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';
import { DashboardStatsQuery } from '@/dtos/stats.dto';

class Stats {
    static getDashboardStats = async(dashboardStatsQuery: DashboardStatsQuery) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(GET_DASHBOARD_STATS_URL, {
                    params: dashboardStatsQuery
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default Stats