<template>
    <div class="dashboard-body">
        <div class="row">
            <sidenav class="custom-col-width-2" />
            <div style="padding-left: 0px!important" class="custom-col-width-10 content-section dashboard-content">
                <navbar />
                <addScreenModal v-if="showAddScreenModal" :organizationId="organizationId"
                    @close-add-screen-modal="showAddScreenModal = false" @refresh-screens="getScreens()" />
                <editScreenModal v-if="showEditScreenModal" :selectedScreen="selectedScreen"
                    @close-edit-screen-modal="showEditScreenModal = false" @refresh-screens="getScreens()" />
                <deleteScreenModal v-if="showDeleteScreenModal" :selectedScreenId="selectedScreenId"
                    @close-delete-screen-modal="showDeleteScreenModal = false" @refresh-screens="getScreens()" />
                <previewScreenImageModal v-if="showPreviewScreenImageModal" :selectedScreenImage="selectedScreenImage"
                    @close-preview-screen-image-modal="showPreviewScreenImageModal = false" />
                <div class="content">
                    <br>
                    <div class="row">
                        <div class="col">
                            <h4>Screens</h4>
                        </div>
                        <div v-if="account.role == USER_ROLE_ADMIN" class="col">
                            <button v-on:click="showAddScreenModal = true" class="btn btn-add"><i
                                    class="pi pi-plus"></i> Add Screen</button>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <template v-bind:key="index" v-for="(screen, index) in screens">
                            <div class="col-3">
                                <div class="card">
                                    <div class="card-overlay">
                                        <i v-on:click="selectScreenImageToPreview(screen.image)"
                                            class="pi pi-external-link"></i>
                                    </div>
                                    <img class="card-img-top" :src="`${STORAGE_URL}/${screen.image}`"
                                        alt="Card image cap">
                                    <div class="card-body">
                                        <p class="title text-center">{{ screen.name }} <i
                                                class="pi pi-external-link"></i></p>
                                        <div class="badge-container">
                                            <span v-if="screen.isActive"
                                                class="badge badge-active text-center">Active</span>
                                            <span v-else class="badge badge-inactive text-center">In-Active</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                    <br>
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import sidenav from '@/components/Sidenav.vue'
import navbar from '@/components/Navbar.vue'
import addScreenModal from '@/components/modals/Screen/Add_Screen_Modal.vue'
import editScreenModal from '@/components/modals/Screen/Edit_Screen_Modal.vue'
import deleteScreenModal from '@/components/modals/Screen/Delete_Screen_Modal.vue'
import previewScreenImageModal from '@/components/modals/Screen/Preview_Screen_Image_Modal.vue'
import { GET_SCREENS } from '@/store/action_types'
import { ORGANIZATION_DETAILS_ROUTE, ORGANIZATION_ACCOUNTS_ROUTE, ORGANIZATION_SCREENS_ROUTE } from '@/router/routes'
import { STORAGE_URL, USER_ROLE_ADMIN } from '../utils/const';

export default {
    title: 'Screens',
    components: {
        sidenav,
        navbar,
        addScreenModal,
        editScreenModal,
        deleteScreenModal,
        previewScreenImageModal
    },
    data() {
        return {
            USER_ROLE_ADMIN,
            ORGANIZATION_DETAILS_ROUTE,
            ORGANIZATION_ACCOUNTS_ROUTE,
            ORGANIZATION_SCREENS_ROUTE,
            STORAGE_URL,
            showAddScreenModal: false,
            showEditScreenModal: false,
            showDeleteScreenModal: false,
            showPreviewScreenImageModal: false,
            selectedScreen: null,
            selectedScreenId: null,
            organizationId: null,
            selectedScreenImage: null,

        }
    },
    computed: {
        route() {
            return this.$route.name
        },
        isLoading() {
            return this.$store.getters.organizations.isLoading
        },
        organizations() {
            return this.$store.getters.organizations.data
        },
        organization() {
            return this.$store.getters.organization.data
        },
        actingScreen() {
            return this.$store.getters.user.data
        },
        actingScreenIsLoading() {
            return this.$store.getters.user.isLoading
        },
        screens() {
            return this.$store.getters.screens.data
        },
        screensIsLoading() {
            return this.$store.getters.screens.isLoading
        },
        account() {
            return this.$store.getters.account.data
        }
    },
    methods: {
        isOdd(number) {
            return number % 2 !== 0;
        },
        selectScreen(screen) {
            this.selectedScreen = screen
            this.showEditScreenModal = true
        },
        selectScreenToDelete(screenId) {
            this.selectedScreenId = screenId
            this.showDeleteScreenModal = true
        },
        selectScreenImageToPreview(image) {
            this.showPreviewScreenImageModal = true
            this.selectedScreenImage = image
        },
        setOrganization() {
            this.organizationId = this.$route.params.id
        },
        getScreens() {
            this.$store.dispatch(GET_SCREENS)
        }
    },
    mounted() {
        this.getScreens()
        this.setOrganization()
    }
}
</script>
<style scoped>
.content .card {
    padding: 0px;
}

.content .card table .pi-trash,
.content .card table .pi-pencil {
    cursor: pointer;
}

.card:hover {
    cursor: pointer;
}

.card-body {
    padding: 10px;
}

.card img {
    border-top-left-radius: 8px!important;
    border-top-right-radius: 8px!important;
}

.card-body .title {
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 5px;
    color: #1D1F2C;
}

.card-body .badge-container {
    display: grid;
    place-items: center;
}

.card-body .badge-active {
    color: #1A9882;
    padding: 2px, 8px, 2px, 8px;
    background-color: #E9FAF7;
}

.card-body .badge-inactive {
    color: #dc3545;
    padding: 2px, 8px, 2px, 8px;
    background-color: #fae9eb;
}

.card-body .pi {
    margin-left: 10px;
    color: #007bff;
    font-size: 13px;
}

.card-overlay .pi {
    font-size: 15px;
}

.card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black */
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    border-radius: 10px;
    transition: opacity 0.3s ease-in-out;
}

.card:hover .card-overlay {
    opacity: 1;
}

.card-overlay i {
    font-size: 2rem;
    /* Adjust the size of the icon */
    color: #fff;
    /* White color for the icon */
}

.content .card {
    padding: 0px;
}

.content .card table thead {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #F4ECE2;
}

.content .card table thead th {
    color: #5A5A57;
}

.content .card table .striped {
    background-color: #FAF7F4 !important;
}

.content .card table tbody tr:hover {
    background-color: #FAF7F4 !important;
}

.content .card table .pi-pencil {
    cursor: pointer;
}

.form-switch {
    display: inline-block;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.form-switch i {
    position: relative;
    display: inline-block;
    margin-right: .5rem;
    width: 46px;
    height: 26px;
    background-color: #e6e6e6;
    border-radius: 23px;
    vertical-align: text-bottom;
    transition: all 0.3s linear;
}

.form-switch i::before {
    content: "";
    position: absolute;
    left: 0;
    width: 42px;
    height: 22px;
    background-color: #fff;
    border-radius: 11px;
    transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
    transition: all 0.25s linear;
}

.form-switch i::after {
    content: "";
    position: absolute;
    left: 0;
    width: 22px;
    height: 22px;
    background-color: #fff;
    border-radius: 11px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
    transform: translate3d(2px, 2px, 0);
    transition: all 0.2s ease-in-out;
}

.form-switch:active i::after {
    width: 28px;
    transform: translate3d(2px, 2px, 0);
}

.form-switch:active input:checked+i::after {
    transform: translate3d(16px, 2px, 0);
}

.form-switch input {
    display: none;
}

.form-switch input:checked+i {
    background-color: #2ecc71;
}

.form-switch input:checked+i::before {
    transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
}

.form-switch input:checked+i::after {
    transform: translate3d(22px, 2px, 0);
}

.custom-col-width-2 {
    width: 16.66%;
}

.custom-col-width-10 {
    width: 83.3%;
}

@media (max-width: 1000px) {
    .custom-col-width-2 {
        width: 12%;
    }

    .custom-col-width-10 {
        width: 88%;
    }
}
</style>