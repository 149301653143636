<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeRegisterAliasModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Register Alias</span>
            <br>
            <div class="modal__content">
                <JewishDatePicker v-if="showJewishDatePicker" @select-date="selectAliasDate"
                    @close-date-picker-modal="closeModal()" />
                <viewPledgeTypeModal v-if="showViewPledgeTypeModal"
                    @close-view-pledge-types-modal="showViewPledgeTypeModal = false" />
                <viewAliasOfferingTypeModal v-if="showViewAliasOfferingTypesModal" :organizationId="organizationId"
                    @close-view-alias-offering-types-modal="showViewAliasOfferingTypesModal = false" />
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="title">Info </h5>
                                <div class="row">
                                    <div class="col-3">
                                        <div @click="showAliasDatePicker(index)" class="form-group">
                                            <label for="aliasDate">Alias Date</label>
                                            <div class="row date-picker-row">
                                                <div class="col-10">
                                                    <Field v-model="newAliasObj.hebrewAliasDate" rules="required"
                                                        disabled type="text" name="aliasDate"
                                                        class="form-control" id="aliasDate" placeholder="Alias Date" />
                                                </div>
                                                <div class="col-2">
                                                    <i class="pi pi-calendar-plus text-primary"></i>
                                                </div>
                                            </div>
                                            <ErrorMessage name="aliasDate" class="form-text text-danger" />
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <label for="aliasDate">Parshiyot</label>
                                        <div class="form-group">
                                            <select v-model="newAliasObj.parshiyot" name="parshiyot"
                                                class="form-control" rules="required">
                                                <option>---</option>
                                                <template v-bind:key="index" v-for="(parshiyot, index) in parashatList">
                                                    <option :value="parshiyot">{{ getParashat(parshiyot) }}
                                                    </option>
                                                </template>
                                            </select>
                                            <ErrorMessage name="parshiyot" class="form-text text-danger" />
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <label for="note">Note</label>
                                        <div class="form-group">
                                            <Field v-model="newAliasObj.note" rules="required"
                                                type="text" name="note" class="form-control" id="note" placeholder="Note" />
                                            <ErrorMessage name="note" class="form-text text-danger" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-12">
                        <p v-if="errorMessage" class="text-center text-danger auth-status-msg">{{ errorMessage }}</p>
                        <Form @submit="registerPledge">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="title">Aliases </h5>
                                    <div class="row">
                                        <div class="col-11">
                                            <div class="row">
                                                <div class="col-2">
                                                    <div class="form-group">
                                                        <label for="pldgType">Offering Type <span v-on:click="showViewAliasOfferingTypesModal = true"
                                                            class="badge add-new-badge text-primary">Show Types</span></label>
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <label for="price">Member (קנה)</label>
                                                    </div>
                                                </div>
                                                <div class="col-2">
                                                    <div class="form-group">
                                                        <label for="quatity">Amount Paid</label>
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <label for="price">Member (עלה - מי שברך)</label>
                                                    </div>
                                                </div>
                                                <div class="col-2">
                                                    <div class="form-group">
                                                        <label for="quatity">Amount Offered</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <template v-bind:key="index" v-for="(item, index) in aliases">
                                        <div class="row invoice-item">
                                            <div class="col-11">
                                                <div class="row">
                                                    <div class="col-2">
                                                        <div class="form-group">
                                                            <select v-model="aliases[index].alasOffType"
                                                                :name="`alasOffType${index}`" class="form-control"
                                                                rules="required">
                                                                <option>---</option>
                                                                <template v-bind:key="index" v-for="(type, index) in aliasOfferingTypes">
                                                                    <option :value="type">{{ type.name }}</option>
                                                                </template>
                                                            </select>
                                                            <ErrorMessage :name="`alasOffType${index}`"
                                                                class="form-text text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <select v-model="aliases[index].membPaid"
                                                                :name="`membPaid${index}`" class="form-control"
                                                                rules="required">
                                                                <option>---</option>
                                                                <template v-bind:key="index"
                                                                    v-for="(member, index) in members">
                                                                    <option :value="member">{{ member.fname }} {{
                                                                        member.lname }}
                                                                    </option>
                                                                </template>
                                                            </select>
                                                            <ErrorMessage :name="`membPaid${index}`"
                                                                class="form-text text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="col-2">
                                                        <div class="form-group">
                                                            <!-- <label for="amount">Unit Price</label> -->
                                                            <Field v-model="aliases[index].memberPaidAmount"
                                                                rules="required" type="number" min="0.1" step="0.01"
                                                                :name="`memberPaidAmount${index}`" class="form-control"
                                                                id="amount" placeholder="0.00" />
                                                            <ErrorMessage :name="`memberPaidAmount${index}`"
                                                                class="form-text text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <select v-model="aliases[index].membOffering"
                                                                :name="`membOffering${index}`" class="form-control"
                                                                rules="required">
                                                                <option>---</option>
                                                                <template v-bind:key="index"
                                                                    v-for="(member, index) in members">
                                                                    <option :value="member">{{ member.fname }} {{
                                                                        member.lname }}
                                                                    </option>
                                                                </template>
                                                            </select>
                                                            <ErrorMessage :name="`membOffering${index}`"
                                                                class="form-text text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="col-2">
                                                        <div class="form-group">
                                                            <Field v-model="aliases[index].memberOfferingAmount"
                                                                rules="required" type="number" min="0.1" step="0.01"
                                                                :name="`memberOfferingAmount${index}`"
                                                                class="form-control" id="amount" placeholder="0.00" />
                                                            <ErrorMessage :name="`memberOfferingAmount${index}`"
                                                                class="form-text text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="aliases.length > 1" class="col-1">
                                                <a v-on:click="deletePledgeItem(index)"
                                                    class="btn btn-link btn-delete-invoice-item"><i
                                                        class="pi pi-trash text-danger"></i></a>
                                            </div>
                                        </div>
                                    </template>
                                    <!-- <div class="row justify-content-start">
                                        <div class="col-1">
                                            <div class="form-check custom-checkbox">
                                                <label class="form-check-label" for="paid">
                                                    <Field v-model="newAliasObj.isPaid" :value="true"
                                                        :unchecked-value="false" type="checkbox"
                                                        class="form-check-input" id="paid" name="paid" />
                                                    <span class="checkmark"></span>
                                                    Paid
                                                </label>
                                                <ErrorMessage name="useCredit" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-check custom-checkbox">
                                                <label class="form-check-label" for="useCredit">
                                                    <Field v-model="newAliasObj.useCredit" :value="true"
                                                        :unchecked-value="false" type="checkbox"
                                                        class="form-check-input" id="useCredit" name="useCredit" />
                                                    <span class="checkmark"></span>
                                                    Deduct from credit
                                                </label>
                                                <ErrorMessage name="useCredit" class="form-text text-danger" />
                                            </div>
                                        </div>
                                    </div> -->
                                    <a v-on:click="addPledgeItem()" class="btn btn-add-new-item">Add New Item</a>
                                    <hr v-if="newAliasObj.isPaid">
                                    <div class="row">
                                        <div v-if="newAliasObj.isPaid" class="col-3">
                                            <div class="form-group">
                                                <label for="paymentMethod">Payment Method</label>
                                                <select v-model="newAliasObj.paymentMethod" class="form-control"
                                                    rules="required">
                                                    <option>---</option>
                                                    <template v-bind:key="index"
                                                        v-for="(payment, index) in PAYMENT_METHODS">
                                                        <option :value="payment">{{ payment }}</option>
                                                    </template>
                                                </select>
                                                <ErrorMessage name="paymentMethod" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div v-if="newAliasObj.isPaid && newAliasObj.paymentMethod == PAYMENT_METHODS[3]"
                                            class="col-3">
                                            <div class="form-group">
                                                <label for="checkNumber">Check Number</label>
                                                <Field v-model="newAliasObj.checkNumber" rules="required" type="text"
                                                    name="checkNumber" class="form-control" id="checkNumber"
                                                    placeholder="##########" />
                                                <ErrorMessage name="checkNumber" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div v-if="newAliasObj.isPaid && newAliasObj.paymentMethod == PAYMENT_METHODS[4]"
                                            class="col-3">
                                            <div class="form-group">
                                                <label for="cardOptions">Charity Card Option</label>
                                                <select v-model="newAliasObj.charityCardOption" class="form-control"
                                                    rules="required">
                                                    <option>---</option>
                                                    <template v-bind:key="index"
                                                        v-for="(cardOptions, index) in CHARITY_CARD_OPTIONS">
                                                        <option :value="cardOptions">{{ cardOptions }}</option>
                                                    </template>
                                                </select>
                                                <ErrorMessage name="cardOptions" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div v-if="newAliasObj.isPaid && newAliasObj.paymentMethod == PAYMENT_METHODS[4] && newAliasObj.charityCardOption == CHARITY_CARD_OPTIONS[4]"
                                            class="col-4">
                                            <div class="form-group">
                                                <label for="charityCardOptionOther">Charity Card Option Other</label>
                                                <Field v-model="charityCardOptionOther" rules="required" type="text"
                                                    name="charityCardOptionOther" class="form-control"
                                                    id="charityCardOptionOther" placeholder="Type here" />
                                                <ErrorMessage name="charityCardOptionOther"
                                                    class="form-text text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div class="row justify-content-end modal__actions">
                                <button v-on:click="closePledgeModal()" class="col-1 btn btn-close">
                                    <img src="../../../assets/icons/close-icon.svg" alt=""> Cancel
                                </button>
                                <button type="submit" class="col-1 btn btn-save"
                                    :disabled="aliasIsLoading ? true : false">
                                    <span v-if="aliasIsLoading" class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                    <span v-if="!aliasIsLoading"><img src="../../../assets/icons/save-icon.svg" alt="">
                                        Save</span>
                                </button>
                            </div>
                        </Form>
                    </div>
                    <!-- <div class="col-6">
                        <br>
                        <div class="card">
                            <div class="card-body">
                                <span v-html="renderContent(newAliasObj.title, newAliasObj)"></span>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
const {HebrewCalendar, HDate, Event, ParshaEvent} = require('@hebcal/core');

import JewishDatePicker from '@/components/JewishDatePicker.vue';
import viewPledgeTypeModal from '@/components/modals/Pledge_Type/View_Pledge_Types_Modal.vue';
import viewAliasOfferingTypeModal from '@/components/modals/Alias_Offering_type/View_Alias_Offering_Type_Modal.vue';

import { ADD_ALIAS, GET_ALIAS_OFFERING_TYPES, GET_MEMBERS, GET_PLEDGE_TYPES } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';
import { DATE_PICKER_DONATION_DATE, DATE_PICKER_DONATION_DISPLAY_START_DATE, DATE_PICKER_INVOICE_ITEM_DUE_DATE, PAYMENT_METHODS, CHARITY_CARD_OPTIONS, USER_ROLE_ADMIN, PARSHIYOTS, PARSHAT_MAPS, ALIAS_OFFERING_TYPES } from '@/utils/const';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        organizationId: String,
        memberId: String,
        hasCredit: Boolean
    },
    components: {
        JewishDatePicker,
        viewPledgeTypeModal,
        viewAliasOfferingTypeModal,
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage
    },
    data() {
        return {
          PARSHIYOTS,
          PARSHAT_MAPS,
          ALIAS_OFFERING_TYPES,
          USER_ROLE_ADMIN,
          DATE_PICKER_DONATION_DATE,
          DATE_PICKER_DONATION_DISPLAY_START_DATE,
          DATE_PICKER_INVOICE_ITEM_DUE_DATE,
          ALIAS_OFFERING_TYPES,
          PAYMENT_METHODS,
          CHARITY_CARD_OPTIONS,
          selectedAliasIndex: 0,
          showJewishDatePicker: false,
          showViewPledgeTypeModal: false,
          showModal: true,
          showAddMemberModal: false,
          showViewAliasOfferingTypesModal: false,
          newAliasObj: {
              aliases: null,
              parshiyot: null,
              note: null,
              aliasDate: null,
              isPaid: true,
              useCredit: true,
              org: null
          },
          aliases: [{
              alasOffType: null,
              membPaid: null,
              memberPaidAmount: null,
              membOffering: null,
              memberOfferingAmount: null
          }],
          parashatList: []
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        pledgeTypes() {
            return this.$store.getters.pledgeTypes.data
        },
        pledgeTypesIsLoading() {
            return this.$store.getters.pledgeTypes.isLoading
        },
        members() {
            return this.$store.getters.members.data
        },
        membersIsLoading() {
            return this.$store.getters.members.isLoading
        },
        alias() {
            return this.$store.getters.alias.data
        },
        aliasIsLoading() {
            return this.$store.getters.alias.isLoading
        },
        aliasOfferingTypes() {
            return this.$store.getters.aliasOfferingTypes.data
        },
        aliasOfferingTypesIsLoading() {
            return this.$store.getters.aliasOfferingTypes.isLoading
        },
        account() {
            return this.$store.getters.account.data
        },
    },
    methods: {
        showAliasDatePicker(index) {
            this.selectedAliasIndex = index
            this.showJewishDatePicker = true
        },
        selectAliasDate(selectedDate) {
            // this.aliases[selectedAliasIndex].aliasDate = this.$filters.convertHDateToGregorian(selectedDate)
            this.newAliasObj.aliasDate = this.$filters.convertHDateToGregorian(selectedDate)
            this.newAliasObj.hebrewAliasDate = this.$filters.convertToHebrewDate(this.newAliasObj.aliasDate)
        },
        getParashat(parashatName) {
            return PARSHAT_MAPS[parashatName];
        },
        addPledgeItem() {
            this.aliases.push({
                alasOffType: null,
                membPaid: null,
                memberPaidAmount: null,
                membOffering: null,
                memberOfferingAmount: null
            })
        },
        deletePledgeItem(index) {
            if (this.aliases.length > 1) {
                this.aliases.splice(index, 1)
            }
        },
        setValues() {
            this.newAliasObj.org = this.organizationId ?? this.account.org
            this.newAliasObj.memb = this.memberId
            this.newAliasObj.useCredit = this.hasCredit
        },
        closeModal() {
            this.showJewishDatePicker = false;
        },
        closePledgeModal() {
            this.$emit('closeRegisterAliasModal')
        },
        caluclateTotalAmount() {
            return this.aliases.reduce((total, item) => {
                return total + (item.price * item.quantity);
            }, 0);
        },
        getAliasOfferingTypes() {
            this.$store.dispatch(GET_ALIAS_OFFERING_TYPES, { org: this.organizationId })
        },
        getMembers() {
            if (this.account.role == USER_ROLE_ADMIN) {
                this.$store.dispatch(GET_MEMBERS, { org: this.organizationId })
            } else {
                this.$store.dispatch(GET_MEMBERS, { org: this.account.org })
            }
        }, 
        getParshas() {
            const currentYear = new HDate().getFullYear();

            // Generate the calendar for the current Hebrew year
            const events = HebrewCalendar.calendar({year: currentYear, sedrot: true});

            // Loop through the events and filter for Parsha events
            events.forEach(event => {
                if (event instanceof ParshaEvent) {
                    // Add the Parashat name to the list
                    this.parashatList.push(event.render());
                }
            });

            console.log("List of Parashat for this year:", this.parashatList);
        },
        getPledgeTypes() {
            this.$store.dispatch(GET_PLEDGE_TYPES, { org: this.organizationId ?? this.account.org })
        },
        registerPledge() {
          this.newAliasObj.parshiyot = this.getParashat(this.newAliasObj.parshiyot)
            
          this.newAliasObj.aliases = this.aliases.map((e) => {
              const updatedItem = {
                  ...e,
                  membPaid: e.membPaid.id,
                  membOffering: e.membOffering.id,
                  alasOffType: e.alasOffType.id
                  // aliasDate: this.$filters.convertHDateToGregorian(e.aliasDate)
              };
              return updatedItem;
          })

          // if (this.memberId == null) {
          //     this.newAliasObj.memb = this.newAliasObj.memb.id
          // }

          this.$store.dispatch(ADD_ALIAS, this.newAliasObj).then((result) => {
              if (result) {
                  this.$emit('closeRegisterAliasModal')
                  this.$emit('refreshAliases')
              }
          })
        }
    },
    created() {
        this.getAliasOfferingTypes()
        this.getParshas()
        this.getMembers()
        this.setValues()
        this.getPledgeTypes()
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    position: relative;
    left: 5%;
    margin-top: 50px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
}

.modal__content .nav-tabs .nav-link {
    font-size: 15px !important;
}

.btn-add-new-item {
    border-radius: 8px;
    font-size: 13px;
    margin-top: 10px;
    color: #14243D !important;
    background-color: transparent;
    border: 1px solid #14243D;
}

.invoice-item {
    height: 50px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.add-new-badge {
    font-size: 10px;
}

.btn-delete-invoice-item {
    font-size: 15px;
}
</style>