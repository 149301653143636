import baseAPI from '@/api/base';
import { EditSynagogueSeat, AddSynagogueSeat, GetSynagogueSeat } from '../dtos/synagogue_seat.dto';
import { SYNAGOGUE_SEAT_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class SynagogueSeat {
    static getSynagogueSeats = async(getSynagogueSeat: GetSynagogueSeat) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(SYNAGOGUE_SEAT_URL, {
                    params: getSynagogueSeat
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default SynagogueSeat