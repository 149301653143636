import { HDate } from '@hebcal/core';
import { DATETIME_OPTIONS, DATE_OPTIONS, DAYS_IN_HEBREW, HEBREW_MONTHS, HEBREW_YEARS, MONTHS_IN_HEBREW, YEARS_IN_HEBREW } from '@/utils/const';

const filters = {
    firstLetter(value: string) {
        return value != null ? value[0] : ''
    },

    capitalize(value: string) {
        return value != null ? value.charAt(0).toUpperCase() + value.slice(1) : '';
    },

    uppercase(value: string) {
        return value != null ? value.toString().toUpperCase() : ''
    },

    getDateOnly(value: string) {
        return value != null ? new Date(value).toLocaleDateString("en-US", DATE_OPTIONS) : '-'
    },

    getTimeOnly(value: string) {
        return value != null ? new Date(value).toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit' }) : '-';
    },

    getTimeOnly24Hrs(value: string) {
        return value != null ? new Date(value).toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit', hour12: false }) : '-';
    },

    getDateAndTime(value: string) {
        return value != null ? new Date(value).toLocaleDateString("en-US", DATETIME_OPTIONS) : '-'
    },

    checkDateRange(startDate: Date, endDate: Date): number {
        const now = new Date();
        if (now > endDate) {
            return 0; // Past
        } else if (now > startDate && now < endDate) {
            return 1; // Within the range
        } else if (now < startDate) {
            return 0;
        } else {
            return 0
        }
    },

    formatDate(value: string) {
        let d = new Date(value),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('-');
    },

    convertToHebrewDate(value: string) {
        const gregDate = new Date(value);
        const hebrewDate = new HDate(gregDate);
        let monthIndex = HEBREW_MONTHS.indexOf(hebrewDate.getMonthName())
        let yearIndex = HEBREW_YEARS.indexOf(hebrewDate.getFullYear().toString())
        if (monthIndex != -1 && yearIndex != -1) {
            return `${DAYS_IN_HEBREW[hebrewDate.getDate() - 1]} ${MONTHS_IN_HEBREW[monthIndex]} ${YEARS_IN_HEBREW[yearIndex]}`
        }
        return '-'

        // return hebrewDate.toString();
    },

    convertToHebrewDateDayAndMonth(value: string) {
        const gregDate = new Date(value);
        const hebrewDate = new HDate(gregDate);
        return `${hebrewDate.getDate()} ${HEBREW_MONTHS[hebrewDate.getMonth() - 1]}`;
    },

    convertHDateToGregorian(hDate: HDate) {
        const gregorianDate = hDate.greg();
        
        // const date: Date = new Date(new Date(gregorianDate.getFullYear(), gregorianDate.getMonth(), gregorianDate.getDate()).setUTCHours(0, 0, 0, 0));
        const date: Date = new Date(new Date(gregorianDate.getFullYear(), gregorianDate.getMonth(), gregorianDate.getDate()));
        
        return date.toISOString();
    },

    convertHDateStringToGregorian(hebrewDateStr: String) {
        const [day, month, year] = hebrewDateStr.split(' ');
        const hebrewDate = new HDate(parseInt(day), month, parseInt(year));

        const gregorianDate = hebrewDate.greg();

        return gregorianDate;
    },

    formatPrice(value: number, currencyISO3: string | null) {
        
        let currencyObj: Intl.NumberFormatOptions = {
            style: 'currency',
            ...(currencyISO3 != null ? { currency: currencyISO3 } : {}),
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }

        const formatter = new Intl.NumberFormat('en-US', currencyObj);
        return value != null ? formatter.format(value) : '-'
    },

    round(value: number) {
        return value.toFixed(2)
    },
}

export default filters