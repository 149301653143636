import baseAPI from '@/api/base';
import { DonationReportStats, GenerateDonationReport, GetDonationReport } from '@/dtos/report/donation_report.dto';
import { GET_DONATION_REPORT_URL, GET_DONATION_REPORT_STATS_URL, GENERATE_DONATION_REPORT_FILE_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class DonationReport {
    static getDonationReportStats = async(reportDonationStats: DonationReportStats) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(GET_DONATION_REPORT_STATS_URL, {
                    params: reportDonationStats
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static getDonationReport = async(reportDonation: GetDonationReport) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(GET_DONATION_REPORT_URL, {
                    params: reportDonation
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static generateDonationReportFile = async(generateDonationReport: GenerateDonationReport) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(GENERATE_DONATION_REPORT_FILE_URL, {}, {
                    params: generateDonationReport
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default DonationReport