import baseAPI from '@/api/base';
import { EditInvoice, RegisterInvoice, GetInvoice, PayInvoice } from '../dtos/invoice.dto';
import { DELETE_INVOICE_URL, INVOICE_URL, EDIT_INVOICE_URL, REGISTER_INVOICE_URL, PAY_INVOICE_URL, MEMBER_INVOICE_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class Invoice {
    static getInvoices = async(getInvoice: GetInvoice) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(INVOICE_URL, {
                    params: getInvoice
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static getMemberInvoices = async(getInvoice: GetInvoice) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(MEMBER_INVOICE_URL, {
                    params: getInvoice
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static registerInvoice = async(addInvoice: RegisterInvoice) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(REGISTER_INVOICE_URL, {
                    ...addInvoice
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static editInvoice = async(editInvoice: EditInvoice) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(EDIT_INVOICE_URL, {
                    ...editInvoice
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static payInvoice = async(editInvoice: PayInvoice) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(PAY_INVOICE_URL, {
                    ...editInvoice
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static deleteInvoice = async(donationId: string) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.delete(`${DELETE_INVOICE_URL}/${donationId}`)

                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default Invoice