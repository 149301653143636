<template>
  <div id="app">
    <div v-if="routerIsLoading" class="spinner-overlay">
      <div class="spinner">
        <img height="60" src="./assets/images/logo_new.png" alt="">
      </div>
    </div>
    <router-view />
    <alertModal/>
  </div>
</template>
<script>
import { nextTick } from 'vue'; // Import nextTick from Vue
import alertModal from '@/components/Alert_Modal.vue'

export default {
  components: {
    alertModal
  },
  data() {
    return {
      isLoading: false
    };
  },
  watch: {
    // isLoading(newValue) {
    //   if (newValue) {
    //     this.$nprogress.start();
    //   } else {
    //     this.$nprogress.done();
    //   }
    // }
  },
  computed: {
    routerIsLoading() {
        return this.$store.getters.routerIsLoading
    },
    // isLoading() {
      // return this.$store.getters.account.isLoading ||
      //   this.$store.getters.announcement.isLoading ||
      //   this.$store.getters.announcements.isLoading ||
      //   this.$store.getters.donation.isLoading ||
      //   this.$store.getters.donations.isLoading ||
      //   this.$store.getters.calendarDonations.isLoading ||
      //   this.$store.getters.member.isLoading ||
      //   this.$store.getters.members.isLoading ||
      //   this.$store.getters.organization.isLoading ||
      //   this.$store.getters.organizations.isLoading ||
      //   this.$store.getters.purpose.isLoading ||ß
      //   this.$store.getters.purposes.isLoading ||
      //   this.$store.getters.screen.isLoading ||
      //   this.$store.getters.screens.isLoading ||
      //   this.$store.getters.ticket.isLoading ||
      //   this.$store.getters.tickets.isLoading ||
      //   this.$store.getters.ticketComment.isLoading ||
      //   this.$store.getters.ticketComments.isLoading ||
      //   this.$store.getters.dashboardStats.isLoading ||
      //   this.$store.getters.template.isLoading ||
      //   this.$store.getters.templates.isLoading ||
      //   this.$store.getters.user.isLoading ||
      //   this.$store.getters.users.isLoading
      // return false
    // }
  },
  created() {
    this.$router.beforeEach(async (to, from, next) => {
      this.isLoading = true; // Show loader before navigating
      await nextTick(); // Force Vue to apply this change immediately
      next(); // Continue to the next route
    });

    this.$router.afterEach(() => {
      // Add a small delay to ensure loader shows properly
      setTimeout(() => {
        this.isLoading = false; // Hide loader after navigation completes
      }, 300); // Optional delay for smoother UX (can be removed or adjusted)
    });
  },
}

</script>
<style lang="css">
@import './assets/style/main.css';
@import '~nprogress/nprogress.css';

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  position: relative;
  width: 140px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-left-color: #14243D;
  animation: spin 1s linear infinite;
}

.spinner img {
  height: 60px;
  z-index: 10; /* Ensures the image is above the spinner */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

