<template>
    <div>
        <div v-if="screenIsLoading" class="loading-container">
            <div class="loading-content">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <p>Loading Screen...</p>
            </div>
        </div>
        <div v-if="displayMinyanims != null" :style="backgroundStyle" class="content">
            <nav @mouseenter="showNavbar" @mouseleave="hideNavbar"
                :class="[!isNavbarVisible ? 'transparent' : null, 'navbar']">
                <a v-if="isNavbarVisible" class="navbar-brand">-</a>
                <a v-if="isNavbarVisible" class="fullscreen-toggle" v-on:click="toggleFullScreen()"><i
                        v-if="isFullScreen" class="pi pi-window-minimize"></i><i v-else
                        class="pi pi-window-maximize"></i></a>
            </nav>
            <div class="">
                <!-- -- minyanimddds --- {{ displayMinyanims.map((e) => e.minyanimTimes.map((g) => { if (g.minyanimType.name == MINYANIM_TYPE_SHACHARIS) { if (g != null) return g } } )) }} -->
                <!-- -- minyanimddds --- {{ displayMinyanims.length }}
                -- announcements --- {{ announcements }} -->

                <div class="shacharis-minyanim d-flex align-items-center justify-content-center"
                    v-if="shacarisMinyanims.length > 0">
                    <div class="w3-content w3-section" style="max-width:500px">
                        <template v-bind:key="index" v-for="(minyanim, index) in shacarisMinyanims">
                            <div v-if="minyanim != null" class="">
                                <h2 v-if="minyanim[0] != null"
                                    class="text-center shacarisDonationSlides w3-animate-top minyumin-time-room">
                                    <div class="row justify-content-between">
                                        <div class="col">
                                            <span class="time">{{ $filters.getTimeOnly24Hrs(minyanim[0].time.startTime)
                                                }} </span>
                                        </div>
                                        <div class="col">
                                            <span class="room-name">{{ minyanim[0].room.name }}</span>
                                        </div>
                                    </div>
                                </h2>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="mincha-minyanim d-flex align-items-center justify-content-center"
                    v-if="minchaMinyanims.length > 0">
                    <div class="w3-content w3-section" style="max-width:500px">
                        <template v-bind:key="index" v-for="(minyanim, index) in minchaMinyanims">
                            <div v-if="minyanim != null" class="">
                                <h2 v-if="minyanim[0] != null"
                                    class="text-center shacarisDonationSlides w3-animate-top minyumin-time-room">
                                    <div class="row justify-content-between">
                                        <div class="col">
                                            <span class="time">{{ $filters.getTimeOnly24Hrs(minyanim[0].time.startTime)
                                                }} </span>
                                        </div>
                                        <div class="col">
                                            <span class="room-name">{{ minyanim[0].room.name }}</span>
                                        </div>
                                    </div>
                                </h2>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="monthly-donations d-flex align-items-center justify-content-center"
                    v-if="maarivMinyanims.length > 0">
                    <div class="w3-content w3-section" style="max-width:500px">
                        <template v-bind:key="index" v-for="(minyanim, index) in maarivMinyanims">
                            <div v-if="minyanim != null" class="">
                                <h2 v-if="minyanim[0] != null"
                                    class="text-center shacarisDonationSlides w3-animate-top minyumin-time-room">
                                    <div class="row justify-content-between">
                                        <div class="col">
                                            <span class="time">{{ $filters.getTimeOnly24Hrs(minyanim[0].time.startTime)
                                                }} </span>
                                        </div>
                                        <div class="col">
                                            <span class="room-name">{{ minyanim[0].room.name }}</span>
                                        </div>
                                    </div>
                                </h2>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="credits-container">
                    <div class="credits" @animationiteration="onAnimationIteration">
                        <div v-for="announcement in announcements" :key="announcement.id" class="credit">
                            <h5 class="text-center" v-html="announcement.message"></h5>
                        </div>
                    </div>
                    <!-- Clone of credits for seamless looping -->
                    <div class="credits" ref="creditsClone">
                        <div v-for="announcement in announcements" :key="`clone-${announcement.id}`" class="credit">
                            <h5 class="text-center" v-html="announcement.message"></h5>
                        </div>
                    </div>
                </div>
                <!-- <div class="yearly-donations d-flex align-items-center justify-content-center"
                    v-if="yearlyDonations.length > 0">
                    <div class="w3-content w3-section" style="max-width:500px">
                        <template v-bind:key="index" v-for="(donation, index) in yearlyDonations">
                            <div v-if="minyanimTime != null" class="">
                                <h5 v-if="minyanimTime[0] != null" class="text-center yearlyDonationSlides w3-animate-top"
                                    v-html="renderContent(donation.template.content, donation)">
                                </h5>
                            </div>
                        </template>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
import io from 'socket.io-client';

import { GET_DISPLAY_DONATIONS, GET_SCREEN, GET_ANNOUNCEMENTS, GET_DISPLAY_MINYANIMS } from '@/store/action_types'
import { DISPLAY_REFRESH_DONATION_SOCKET_EVENT, MINYANIM_TYPE_SHACHARIS, MINYANIM_TYPE_MAARIV, MINYANIM_TYPE_MINCHA, DONATION_TYPE_YEARLY } from '@/utils/const';
import { STORAGE_URL, API_URL } from '@/utils/const';

export default {
    title: 'Display',
    data() {
        return {
            MINYANIM_TYPE_SHACHARIS,
            shacarisMinyanimsIndex: 0,
            minchaMinyanimsIndex: 0,
            maarivMinyanimsIndex: 0,
            yearlyDonationsIndex: 0,
            isFullScreen: false,
            isNavbarVisible: false,
            socket: null
        }
    },
    computed: {
        displayMinyanims() {
            return this.$store.getters.displayMinyanims.data
        },
        shacarisMinyanims() {
            return this.filterMinyanimByType(MINYANIM_TYPE_SHACHARIS)
        },
        minchaMinyanims() {
            return this.filterMinyanimByType(MINYANIM_TYPE_MINCHA)
        },
        maarivMinyanims() {
            return this.filterMinyanimByType(MINYANIM_TYPE_MAARIV)
        },
        account() {
            return this.$store.getters.account.data
        },
        screen() {
            return this.$store.getters.screen.data
        },
        screenIsLoading() {
            return this.$store.getters.screen.isLoading
        },
        announcements() {
            return this.$store.getters.announcements.data
        },
        announcementsIsLoading() {
            return this.$store.getters.announcements.isLoading
        },
        backgroundStyle() {
            return {
                backgroundImage: `url(${STORAGE_URL}/${this.screen != null ? this.screen.image : ''})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                height: '100vh',
                width: '100%',
                margin: '0',
                padding: '0'
            };
        }
    },
    methods: {
        filterMinyanimByType(type) {
            if (this.displayMinyanims) {
                return this.displayMinyanims.map((e) => e.minyanimTimes.map((g) => { if (g.minyanimType.name == type) { if (g != null) return { time: g, room: e.room } } }))
            }
            // return this.minyanims.map((e) => e.minyanimTimes).filter((e) => { if (e.minyanimType == type) { if (e != null) return e } })
        },
        showNavbar() {
            this.isNavbarVisible = true;
        },
        hideNavbar() {
            this.isNavbarVisible = false;
        },
        onAnimationIteration() {
            const creditsOriginal = this.$refs.creditsClone;
            if (creditsOriginal) {
                creditsOriginal.style.animationPlayState = 'running';
            }
        },
        toggleFullScreen() {
            if (document.fullscreenElement) {
                // Exit fullscreen mode
                document.exitFullscreen()
                    .then(() => {
                        this.isFullScreen = false;
                    })
                    .catch((err) => {
                        console.error(`Error attempting to exit full-screen mode: ${err.message} (${err.name})`);
                    });
            } else {
                // Enter fullscreen mode
                document.documentElement.requestFullscreen()
                    .then(() => {
                        this.isFullScreen = true;
                    })
                    .catch((err) => {
                        console.error(`Error attempting to enter full-screen mode: ${err.message} (${err.name})`);
                    });
            }
        },
        shacarisMinyanimsCarousel() {
            if (this.shacarisMinyanims.length > 0) {
                var i;
                var x = document.getElementsByClassName("shacarisDonationSlides");
                for (i = 0; i < x.length; i++) {
                    x[i].style.animation = "fadeOut 10s";
                    x[i].style.display = "none";
                }
                this.shacarisMinyanimsIndex++;
                if (this.shacarisMinyanimsIndex > x.length) { this.shacarisMinyanimsIndex = 1 }
                x[this.shacarisMinyanimsIndex - 1].style.animation = "fadeIn 7s";
                x[this.shacarisMinyanimsIndex - 1].style.display = "block";
                setTimeout(this.shacarisMinyanimsCarousel, 120000);
            }
        },
        minchaMinyanimsCarousel() {
            if (this.minchaMinyanims.length > 0) {
                var i;
                var x = document.getElementsByClassName("minchaMinyanimsSlides");
                for (i = 0; i < x.length; i++) {
                    x[i].style.animation = "fadeOut 10s";
                    x[i].style.display = "none";
                }
                this.minchaMinyanimsIndex++;
                if (this.minchaMinyanimsIndex > x.length) { this.minchaMinyanimsIndex = 1 }
                x[this.minchaMinyanimsIndex - 1].style.animation = "fadeIn 7s";
                x[this.minchaMinyanimsIndex - 1].style.display = "block";
                setTimeout(this.minchaMinyanimsCarousel, 120000);
            }
        },
        maarivMinyanimsCarousel() {
            if (this.maarivMinyanims.length > 0) {
                var i;
                var x = document.getElementsByClassName("maarivMinyanimsSlides");
                for (i = 0; i < x.length; i++) {
                    x[i].style.animation = "fadeOut 10s";
                    x[i].style.display = "none";
                }
                this.maarivMinyanimsIndex++;
                if (this.maarivMinyanimsIndex > x.length) { this.maarivMinyanimsIndex = 1 }
                x[this.maarivMinyanimsIndex - 1].style.animation = "fadeIn 7s";
                x[this.maarivMinyanimsIndex - 1].style.display = "block";
                setTimeout(this.maarivMinyanimsCarousel, 120000);
            }
        },
        reloadPage() {
            window.location.reload();
        },
        initiateSocket() {
            this.socket = io(API_URL, {
                withCredentials: true,
                transports: ['websocket', 'polling'], // Try WebSockets first, then fallback to polling
            });

            this.socket.on('connect', () => {
                console.log('SOCKET CONNECTED');
            });

            this.socket.on('DISPLAY_REFRESH_DONATION_SOCKET_EVENT', (data) => {
                if (data.screen == this.$route.params.id && data.organization == this.$route.params.org) {
                    console.log('DONATION DISPLAY REFRESH TRIGGERED');
                    this.getMinyanims();
                    this.getAnnouncements()
                }
            });

            this.socket.on('DISPLAY_REFRESH_ANNOUNCEMENT_SOCKET_EVENT', (data) => {
                if (data.screen == this.$route.params.id && data.organization == this.$route.params.org) {
                    console.log('ANNOUNCEMENT DISPLAY REFRESH TRIGGERED');
                    this.getAnnouncements()
                }
            });
        },
        getScreen() {
            this.$store.dispatch(GET_SCREEN, { id: this.$route.params.id })
        },
        getAnnouncements() {
            this.$store.dispatch(GET_ANNOUNCEMENTS, { org: this.$route.params.org, scr: 'c8df9f96-41c1-4324-8bf0-fc146f204b7e', startDate: new Date(Date.now()), endDate: new Date(Date.now()), shuffle: true, isActive: true })
            // this.$store.dispatch(GET_ANNOUNCEMENTS, { isActive: true })
        },
        getMinyanims() {
            this.$store.dispatch(GET_DISPLAY_MINYANIMS, { scr: this.$route.params.id, org: this.$route.params.org, startDate: new Date(Date.now()), endDate: new Date(Date.now()), isActive: true }).then(() => {
                // this.shacarisMinyanimsCarousel();
                // this.minchaMinyanimsCarousel();
                // this.maarivMinyanimsCarousel();
                // this.yearlyDonationCarousel();
            })
        }
    },
    mounted() {
        this.getScreen()
        this.getMinyanims();
        this.getAnnouncements()

        setInterval(() => { this.getMinyanims(); this.getAnnouncements() }, 43200000);

        // setInterval(() => { this.reloadPage() }, 43200000);

        this.hideNavbar();


        // this.shacarisMinyanimsCarousel();
        // this.minchaMinyanimsCarousel();
        // this.maarivMinyanimsCarousel();
        // this.yearlyDonationCarousel();

        this.initiateSocket()


        // setTimeout(() => {
        // this.shacarisMinyanimsCarousel();
        //     setTimeout(() => {
        //         this.minchaMinyanimsCarousel();
        //         setTimeout(() => {
        //             this.maarivMinyanimsCarousel();
        //             setTimeout(() => {
        //                 this.yearlyDonationCarousel();
        //             }, 120000);
        //         }, 120000);
        //     }, 120000);
        // }, 120000);
    },
    beforeDestroy() {
        this.socket.off(DISPLAY_REFRESH_DONATION_SOCKET_EVENT);
        this.socket.disconnect();
    }
}
</script>
<style scoped>
/*.content {
    background-image: url('../assets/images/background_img.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    margin: 0;
    padding: 0;
}*/

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Full viewport height */
    width: 100%;
}

.loading-content {
    text-align: center;
}

.navbar {
    transition: top 0.3s ease-in-out;
    background-color: transparent !important;
}

.navbar .transparent {
    background-color: transparent !important;
}

.hover-area {
    position: fixed;
    top: 0;
    height: 10%;
    width: 100%;
}

.navbar .fullscreen-toggle {
    margin-right: 1%;
}

.navbar .pi {
    font-size: 22px;
    color: #5A5A57;
    cursor: pointer;
}

/* top */
.shacharis-minyanim {
    width: 16%;
    height: 28%;
    position: absolute;
    top: 17.5%;
    left: 70%;
}

/* middle */
.mincha-minyanim {
    width: 25%;
    height: 28%;
    position: absolute;
    top: 37.5%;
    left: 65%;
}

/* bottom */
.monthly-donations {
    width: 25%;
    height: 28%;
    position: absolute;
    top: 59%;
    left: 65%;
}

/* top-right */
.yearly-donations {
    width: 30%;
    height: 28%;
    position: absolute;
    top: 18%;
    left: 64.6%;
}

.minyumin-time-room {
    font-family: Pyutim;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.minyumin-time-room .time {
    flex: 1;
    text-align: left;
    position: relative;
    right: 55%;
}

.minyumin-time-room .room-name {
    flex: 1;
    text-align: right;
    position: relative;
    left: 20%;
    white-space: nowrap;
}

.donnor-name {
    font-weight: 800;
    color: #5A5A57;
}

.ticker-wrap {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    height: 4rem;
    background-color: transparent;
    box-sizing: content-box;
}

.ticker-wrap .ticker {
    display: inline-block;
    line-height: 5rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 90s;
    animation-duration: 90s;
}

.ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 2rem;
    font-size: 18px;
    color: #000000;
}

@-webkit-keyframes ticker {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
    }

    100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

@keyframes ticker {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
    }

    100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

.center-content {
    display: flex;
    justify-content: center;
    /* Horizontally center the content */
}

.footer-banner {
    display: flex;
    justify-content: center;
    width: 100%;
    position: fixed;
    left: 0.5%;
    bottom: 2%;
}

.footer-banner h6 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rtl {
    direction: rtl !important;
}

.credits-container {
    width: 20%;
    height: 31%;
    overflow: hidden;
    position: absolute;
    top: 49%;
    left: 40%;
    font-family: Arial, sans-serif;
    font-size: 14px;
    line-height: 1.4;
    background-color: transparent;
}

.credits {
    position: absolute;
    width: 100%;
    animation: scroll linear infinite;
    animation-duration: 20s;
    /* Adjust speed here */
}

.credits {
    margin-top: 10%;
    margin-bottom: 35%;
}

@keyframes scroll {
    from {
        top: 100%;
    }

    to {
        top: -200%;
    }
}

.credit {
    padding: 10px;
}
</style>