import baseAPI from '@/api/base';
import { ClearNotification, GetNotification } from '../dtos/notification.dto';
import { CLEAR_NOTIFICATION_URL, NOTIFICATION_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class Notification {
    static getNotifications = async(getNotification: GetNotification) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(NOTIFICATION_URL, {
                    params: getNotification
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static clearNotification = async(clearNotification: ClearNotification) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(CLEAR_NOTIFICATION_URL, {
                    ...clearNotification
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default Notification