<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeAddMinyanimModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Register Minyanim</span>
            <br>
            <div class="modal__content" ref="modalContent">
                <JewishDatePicker v-if="currentlyShowingJewDatePicker == DATE_PICKER_MINYANIM_START_DATE"
                    @select-date="selectMinyanimStartDate" @close-date-picker-modal="closeModal()" />
                <JewishDatePicker v-if="currentlyShowingJewDatePicker == DATE_PICKER_MINYANIM_END_DATE"
                    @select-date="selectMinyanimEndDate" @close-date-picker-modal="closeModal()" />
                <viewMinyanimTypes v-if="showViewMinyanimTypesModal" :organizationId="organizationId"
                    @close-view-minyanim-types-modal="showViewMinyanimTypesModal = false" />
                <div class="row">
                    <div class="col-12">
                        <p v-if="errorMessage" class="text-center text-danger auth-status-msg">{{ errorMessage }}</p>
                        <Form @submit="registerMinyanim">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="title">General Info</h5>
                                    <div class="row">
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label for="screen">Screen</label>
                                                <select v-model="newMinyanimObj.scr" class="form-control"
                                                    rules="required">
                                                    <option>---</option>
                                                    <template v-bind:key="index" v-for="(screen, index) in screens">
                                                        <option :value="screen">{{ screen.name }}</option>
                                                    </template>
                                                </select>
                                                <ErrorMessage name="screen" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label for="room">Room</label>
                                                <select v-model="newMinyanimObj.rom" class="form-control"
                                                    rules="required">
                                                    <option>---</option>
                                                    <template v-bind:key="index" v-for="(room, index) in rooms">
                                                        <option :value="room">{{ room.name }}
                                                        </option>
                                                    </template>
                                                </select>
                                                <ErrorMessage name="room" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label for="room">Date Type </label>
                                                <select v-model="newMinyanimObj.dateType" class="form-control"
                                                    rules="required">
                                                    <option>---</option>
                                                    <template v-bind:key="index"
                                                        v-for="(dateType, index) in MINYANIM_DATE_TYPES">
                                                        <option :value="dateType">{{ dateType }}
                                                        </option>
                                                    </template>
                                                </select>
                                                <ErrorMessage name="room" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div @click="currentlyShowingJewDatePicker = DATE_PICKER_MINYANIM_START_DATE"
                                                class="form-group">
                                                <label for="minyanimStartDate">{{ newMinyanimObj.dateType ==
                                                    MINYANIM_DATE_TYPES[0] ? 'Start' : '' }} Date</label>
                                                <div class="row date-picker-row">
                                                    <div class="col-10">
                                                        <Field v-model="minyanimStartDate" rules="required" disabled
                                                            type="text" name="minyanimStartDate" class="form-control"
                                                            id="minyanimStartDate"
                                                            :placeholder="`Minyanim ${[MINYANIM_DATE_TYPES[4]].includes(newMinyanimObj.dateType) ? 'Start' : ''} Date`" />
                                                    </div>
                                                    <div class="col-2">
                                                        <i class="pi pi-calendar-plus text-primary"></i>
                                                    </div>
                                                </div>
                                                <ErrorMessage name="minyanimStartDate" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div v-if="[MINYANIM_DATE_TYPES[4]].includes(newMinyanimObj.dateType)"
                                            class="col-4">
                                            <div @click="currentlyShowingJewDatePicker = DATE_PICKER_MINYANIM_END_DATE"
                                                class="form-group">
                                                <label for="minyanimEndDate">End Date</label>
                                                <div class="row date-picker-row">
                                                    <div class="col-10">
                                                        <Field v-model="minyanimEndDate" rules="required" disabled
                                                            type="text" name="minyanimEndDate" class="form-control"
                                                            id="minyanimEndDate" :placeholder="`Minyanim End Date`" />
                                                    </div>
                                                    <div class="col-2">
                                                        <i class="pi pi-calendar-plus text-primary"></i>
                                                    </div>
                                                </div>
                                                <ErrorMessage name="minyanimStartDate" class="form-text text-danger" />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <br>
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="title">Times <span v-on:click="showViewMinyanimTypesModal = true"
                                            class="badge add-new-badge text-primary">Show Types</span></h5>
                                    <template v-bind:key="index" v-for="(time, index) in minyanimTimes">
                                        <div class="row">
                                            <div class="col-8">
                                                <div class="row">
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="type">Type</label>
                                                            <select v-model="minyanimTimes[index].minType"
                                                                class="form-control" rules="required">
                                                                <option>---</option>
                                                                <template v-bind:key="index"
                                                                    v-for="(type, index) in minyanimTypes">
                                                                    <option :value="type">{{ type.name }}</option>
                                                                </template>
                                                            </select>
                                                            <ErrorMessage name="type" class="form-text text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="type">Start Time</label>
                                                            <Field v-model="minyanimTimes[index].startTime"
                                                                rules="required" type="time"
                                                                :name="`minyanimStartTime${index}`" class="form-control"
                                                                id="minyanimStartTime" placeholder="Start Time" />
                                                            <ErrorMessage :name="`minyanimStartTime${index}`"
                                                                class="form-text text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="minyanimTimes.length > 1" class="col">
                                                <a v-on:click="deleteMinyanimTime(index)"
                                                    class="btn btn-link btn-delete-minyanim-time"><i
                                                        class="pi pi-trash text-danger"></i></a>
                                            </div>
                                        </div>
                                    </template>
                                    <a v-on:click="addTime()" class="btn btn-add-new-time">Add New Time</a>
                                </div>
                            </div>
                            <div class="row justify-content-end modal__actions">
                                <button v-on:click="closeMinyanimModal()" class="col-1 btn btn-close">
                                    <img src="../../../assets/icons/close-icon.svg" alt=""> Cancel
                                </button>
                                <button type="submit" class="col-1 btn btn-save"
                                    :disabled="minyanimIsLoading ? true : false">
                                    <span v-if="minyanimIsLoading" class="spinner-border spinner-border-sm"
                                        role="status" aria-hidden="true"></span>
                                    <span v-if="!minyanimIsLoading"><img src="../../../assets/icons/save-icon.svg"
                                            alt=""> Save</span>
                                </button>
                            </div>
                        </Form>
                    </div>
                    <!-- <div class="col-6">
                        <br>
                        <div class="card">
                            <div class="card-body">
                                <span v-html="renderContent(newMinyanimObj.title, newMinyanimObj)"></span>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import JewishDatePicker from '@/components/JewishDatePicker.vue';
import viewMinyanimTypes from '@/components/modals/Minyanim_Type/View_Minyanim_Types_Modal.vue';

import { ADD_MINYANIM, GET_MINYANIM_TYPES, GET_ROOMS, GET_SCREENS } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';
import { DATE_PICKER_MINYANIM_START_DATE, DATE_PICKER_MINYANIM_END_DATE, MINYANIM_TYPE_MAARIV, MINYANIM_TYPE_MINCHA, MINYANIM_TYPE_SHACHARIS, MINYANIM_TYPES, MINYANIM_DATE_TYPES } from '@/utils/const';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        organizationId: String
    },
    components: {
        JewishDatePicker,
        viewMinyanimTypes,
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage
    },
    data() {
        return {
            MINYANIM_TYPES,
            MINYANIM_DATE_TYPES,
            MINYANIM_TYPE_MAARIV,
            MINYANIM_TYPE_MINCHA,
            MINYANIM_TYPE_SHACHARIS,
            DATE_PICKER_MINYANIM_START_DATE,
            DATE_PICKER_MINYANIM_END_DATE,
            currentlyShowingJewDatePicker: null,
            showModal: true,
            showViewMinyanimTypesModal: false,
            newMinyanimObj: {
                type: null,
                scr: null,
                rom: null,
                minyanimTimes: [],
                dateType: null,
                minyanimStartDate: null,
                minyanimEndDate: null,
                org: null
            },
            minyanimTimes: [{
                minType: null,
                startTime: null,
                endTime: null
            }],
            minyanimStartDate: null,
            minyanimEndDate: null,
            minyanimStartTime: null
        }
    },
    watch: {
        // 'newMinyanimObj.type': function (newValue, oldValue) {
        //     this.updateMinyanimEndTime();
        // },
        // 'minyanimStartTime': function (newValue, oldValue) {
        //     this.setMinyanimStartTime();
        //     this.updateMinyanimEndTime()
        // }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        screens() {
            return this.$store.getters.screens.data
        },
        screensIsLoading() {
            return this.$store.getters.screens.isLoading
        },
        rooms() {
            return this.$store.getters.rooms.data
        },
        roomsIsLoading() {
            return this.$store.getters.rooms.isLoading
        },
        minyanim() {
            return this.$store.getters.minyanim.data
        },
        minyanimIsLoading() {
            return this.$store.getters.minyanim.isLoading
        },
        minyanimTypes() {
            return this.$store.getters.minyanimTypes.data
        },
        minyanimTypesIsLoading() {
            return this.$store.getters.minyanimTypes.isLoading
        }
    },
    methods: {
        selectMinyanimStartDate(selectedDate) {
            this.newMinyanimObj.minyanimStartDate = this.$filters.convertHDateToGregorian(selectedDate)
            this.minyanimStartDate = this.$filters.convertToHebrewDate(this.newMinyanimObj.minyanimStartDate)
            this.closeModal()
        },
        selectMinyanimEndDate(selectedDate) {
            this.newMinyanimObj.minyanimEndDate = this.$filters.convertHDateToGregorian(selectedDate)
            this.minyanimEndDate = this.$filters.convertToHebrewDate(this.newMinyanimObj.minyanimEndDate)
            this.closeModal()
        },
        setMinyanimStartTime() {
            if(this.minyanimStartTime != null) {
                this.newMinyanimObj.startTime = `${this.newMinyanimObj.minyanimStartDate.toString().split('T')[0]}T${this.minyanimStartTime}:00.000Z`
                this.updateMinyanimEndTime()
            }
        },
        updateMinyanimEndTime(minyanimTime) {
            // if (this.newMinyanimObj.startTime != null) {
            let newDateTime = new Date(`${this.newMinyanimObj.minyanimStartDate.toString().split('T')[0]}T${minyanimTime.startTime}:00.000Z`);
                switch (minyanimTime.minType.name) {
                    case MINYANIM_TYPE_SHACHARIS:
                        newDateTime.setHours(newDateTime.getHours() + 1);
                        break;
                    case MINYANIM_TYPE_MINCHA:
                        newDateTime.setMinutes(newDateTime.getMinutes() + 15);
                        break;
                    case MINYANIM_TYPE_MAARIV:
                        newDateTime.setMinutes(newDateTime.getMinutes() + 15);
                        break;
                    default:
                        return;
                }
                // this.donationDisplayEndDate = this.$filters.convertToHebrewDate(newDateTime.toISOString().split('T')[0])
                return newDateTime
            // }
        },
        addTime() {
            this.minyanimTimes.push({
                minType: null,
                startTime: null,
                endTime: null
            })
        },
        setValues() {
            this.newMinyanimObj.org = this.organizationId
        },
        closeModal() {
            this.currentlyShowingJewDatePicker = null;
        },
        closeMinyanimModal() {
            this.$emit('closeAddMinyanimModal')
        },
        deleteMinyanimTime(index) {
            if (this.minyanimTimes.length > 1) {
                this.minyanimTimes.splice(index, 1)
            }
        },
        getMinyanimTypes() {
            this.$store.dispatch(GET_MINYANIM_TYPES, { org: this.$route.params.id })
        },
        getScreens() {
            this.$store.dispatch(GET_SCREENS)
        },
        getRooms() {
            this.$store.dispatch(GET_ROOMS, { org: this.$route.params.id })
        },
        registerMinyanim() {
            console.log('this.newMinyanimObj', this.newMinyanimObj)

            this.newMinyanimObj.scr = this.newMinyanimObj.scr.id
            this.newMinyanimObj.rom = this.newMinyanimObj.rom.id

            if (this.newMinyanimObj.dateType != MINYANIM_DATE_TYPES[4]) {
                this.newMinyanimObj.minyanimEndDate = this.newMinyanimObj.minyanimStartDate
            }

            let minyanimTimesTemp = this.minyanimTimes.map((e) => {
                let obj = {
                    minType: e.minType.id,
                    startTime: new Date(`${this.newMinyanimObj.minyanimStartDate.toString().split('T')[0]}T${e.startTime}:00.000Z`),
                    endTime: this.updateMinyanimEndTime(e)
                }
                return obj
            })

            console.log('minyanimTimesTemp => ', minyanimTimesTemp)

            this.newMinyanimObj.minyanimTimes = minyanimTimesTemp

            this.$store.dispatch(ADD_MINYANIM, this.newMinyanimObj ).then((result) => {
                if (result) {
                    this.$emit('refreshMinyanim')
                    this.$emit('closeAddMinyanimModal')
                }
            })
        },
    },
    mounted() {
        const modalContent = this.$refs.modalContent;
        if (modalContent) {
            const scrollThumb = modalContent.querySelector('::-webkit-scrollbar-thumb');
            if (scrollThumb) {
                const newHeight = scrollThumb.offsetHeight * 0.4;
                scrollThumb.style.height = `${newHeight}px`;
            }
        }
    },
    created() {
        this.getScreens()
        this.getRooms()
        this.getMinyanimTypes()
        this.setValues()
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    position: relative;
    left: 10%;
    margin-top: 50px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid#e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
    max-height: 80vh; /* Set a maximum height for the modal content */
    overflow-y: auto;
}

::v-deep .modal__content {
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.modal__content .nav-tabs .nav-link {
    font-size: 15px !important;
}

.btn-add-new-time {
    border-radius: 8px;
    font-size: 13px;
    color: #14243D!important;
    background-color: transparent;
    border: 1px solid #14243D;
}

.add-new-badge {
    font-size: 10px;
}

.btn-delete-minyanim-time {
    margin-top: 31px;
    font-size: 15px;
}
</style>