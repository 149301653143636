<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeEditDonationModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Edit Donation</span>
            <br>
            <div class="modal__content">
                <addMemberModal v-if="showAddMemberModal" :organizationId="organizationId"
                    @close-add-member-modal="showAddMemberModal = false" @refresh-members="getMembers()" />
                <JewishDatePicker v-if="currentlyShowingJewDatePicker == DATE_PICKER_DONATION_DATE"
                    @select-date="selectDonationDate" @close-date-picker-modal="closeModal()" />
                <JewishDatePicker v-if="currentlyShowingJewDatePicker == DATE_PICKER_DONATION_DISPLAY_START_DATE"
                    @select-date="selectDonationDisplayStartDate" @close-date-picker-modal="closeModal()" />
                <JewishDatePicker v-if="currentlyShowingJewDatePicker == DATE_PICKER_DONATION_DISPLAY_END_DATE"
                    @select-date="selectDonationDisplayEndDate" @close-date-picker-modal="closeModal()" />
                <div class="row">
                    <div class="col-12">
                        <p v-if="errorMessage" class="text-center text-danger auth-status-msg">{{ errorMessage }}</p>
                        <Form @submit="editDonation">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="title">General Info</h5>
                                    <div class="row">
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label for="amount">Template</label>
                                                <select v-model="editDonationObj.title" class="form-control"
                                                    rules="required">
                                                    <option>---</option>
                                                    <template v-bind:key="index" v-for="(template, index) in templates">
                                                        <option :value="template">{{ template.name }}</option>
                                                    </template>
                                                </select>
                                                <ErrorMessage name="amount" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label for="amount">Screen</label>
                                                <select v-model="editDonationObj.scr" class="form-control"
                                                    rules="required">
                                                    <option>---</option>
                                                    <template v-bind:key="index" v-for="(screen, index) in organizationScreens">
                                                        <option :value="screen">{{ screen.name }}</option>
                                                    </template>
                                                </select>
                                                <ErrorMessage name="amount" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label for="amount">Member <span v-on:click="showAddMemberModal = true"
                                                        class="badge add-new-badge text-primary">Add
                                                        New</span></label>
                                                <select v-model="editDonationObj.memb" class="form-control"
                                                    rules="required">
                                                    <option>---</option>
                                                    <template v-bind:key="index" v-for="(member, index) in members">
                                                        <option :value="member">{{ member.fname }} {{ member.lname }}
                                                        </option>
                                                    </template>
                                                </select>
                                                <ErrorMessage name="amount" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label for="type">Type</label>
                                                <select v-model="editDonationObj.type" name="type" class="form-control"
                                                    rules="required">
                                                    <option>---</option>
                                                    <template v-bind:key="index"
                                                        v-for="(type, index) in DONTATION_TYPE">
                                                        <option>{{ type }}</option>
                                                    </template>
                                                </select>
                                                <ErrorMessage name="type" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label for="purpose">Purpose</label>
                                                <Field v-model="editDonationObj.purpose" rules="" type="text"
                                                    name="purpose" class="form-control" id="purpose"
                                                    placeholder="Purpose" />
                                                <ErrorMessage name="purpose" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label for="amount">Amount</label>
                                                <Field v-model="editDonationObj.amount" rules="" type="number" min="0.1"
                                                    step="0.01" name="amount" class="form-control" id="amount"
                                                    placeholder="0.00" />
                                                <ErrorMessage name="amount" class="form-text text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="title">Select Dates</h5>
                                    <div class="row">
                                        <div class="col-4">
                                            <div @click="currentlyShowingJewDatePicker = DATE_PICKER_DONATION_DATE"
                                                class="form-group">
                                                <label for="donationDate">Donation Date</label>
                                                <div class="row date-picker-row">
                                                    <div class="col-10">
                                                        <Field v-model="donationDate" rules="required" disabled
                                                            type="text" name="donationDate" class="form-control"
                                                            id="donationDate" placeholder="Donation Date" />
                                                    </div>
                                                    <div class="col-2">
                                                        <i class="pi pi-calendar-plus text-primary"></i>
                                                    </div>
                                                </div>
                                                <ErrorMessage name="donationDate" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div @click="currentlyShowingJewDatePicker = DATE_PICKER_DONATION_DISPLAY_START_DATE"
                                                class="form-group">
                                                <label for="donationDisplayStartDate">Display Start Date</label>
                                                <div class="row date-picker-row">
                                                    <div class="col-10">
                                                        <Field v-model="donationDisplayStartDate" rules="required"
                                                            disabled type="text" name="donationDisplayStartDate"
                                                            class="form-control" id="donationDisplayStartDate"
                                                            placeholder="Donation Display Start Date" />
                                                    </div>
                                                    <div class="col-2">
                                                        <i class="pi pi-calendar-plus text-primary"></i>
                                                    </div>
                                                </div>
                                                <ErrorMessage name="donationDisplayStartDate"
                                                    class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div @click="currentlyShowingJewDatePicker = DATE_PICKER_DONATION_DISPLAY_END_DATE"
                                                class="form-group">
                                                <label for="donationDisplayEndDate">Display End Date</label>
                                                <div class="row date-picker-row">
                                                    <div class="col-10">
                                                        <Field v-model="donationDisplayEndDate" rules="required"
                                                            disabled type="text" name="donationDisplayEndDate"
                                                            class="form-control" id="donationDisplayEndDate"
                                                            placeholder="Donation Display End Date" />
                                                    </div>
                                                    <div class="col-2">
                                                        <i class="pi pi-calendar-plus text-primary"></i>
                                                    </div>
                                                </div>
                                                <ErrorMessage name="donationDisplayEndDate"
                                                    class="form-text text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div class="row justify-content-end modal__actions">
                                <button v-on:click="closeDonationModal()" class="col-1 btn btn-close">
                                    <img src="../../../assets/icons/close-icon.svg" alt=""> Cancel
                                </button>
                                <button type="submit" class="col-1 btn btn-save"
                                    :disabled="donationIsLoading ? true : false">
                                    <span v-if="donationIsLoading" class="spinner-border spinner-border-sm"
                                        role="status" aria-hidden="true"></span>
                                    <span v-if="!donationIsLoading"><img src="../../../assets/icons/save-icon.svg"
                                            alt=""> Save</span>
                                </button>
                            </div>
                        </Form>
                    </div>
                    <!-- <div class="col-6">
                        <br>
                        <div class="card">
                            <div class="card-body">
                                <span v-html="renderContent(editDonationObj.title, editDonationObj)"></span>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import addMemberModal from '@/components/modals/Member/Add_Member_Modal.vue'
import JewishDatePicker from '@/components/JewishDatePicker.vue';

import { EDIT_DONATION, GET_MEMBERS, GET_ORGANIZATION_SCREENS, GET_SCREENS, GET_TEMPLATES } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';
import { DONTATION_TYPE, DONATION_TITLES, DONATION_TYPE_YEARLY, DONATION_TYPE_MONTHLY, DONATION_TYPE_WEEKLY, DONATION_TYPE_DAILY, DATE_PICKER_DONATION_DATE, DATE_PICKER_DONATION_DISPLAY_START_DATE, DATE_PICKER_DONATION_DISPLAY_END_DATE, USER_ROLE_ADMIN } from '@/utils/const';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        selectedDonation: Object
    },
    components: {
        addMemberModal,
        JewishDatePicker,
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage
    },
    data () {
        return {
            USER_ROLE_ADMIN,
            DONTATION_TYPE,
            DONATION_TITLES,
            DONATION_TYPE_YEARLY,
            DONATION_TYPE_MONTHLY,
            DONATION_TYPE_WEEKLY,
            DONATION_TYPE_DAILY,
            DATE_PICKER_DONATION_DATE,
            DATE_PICKER_DONATION_DISPLAY_START_DATE,
            DATE_PICKER_DONATION_DISPLAY_END_DATE,
            currentlyShowingJewDatePicker: null,
            showModal: true,
            showAddMemberModal: false,
            organizationId: null,
            editDonationObj: {
                title: null,
                scr: null,
                member: null,
                donorName: null,
                amount: null,
                purpose: null,
                donationDate: null,
                donationDisplayStartDate: null,
                donationDisplayEndDate: null
            },
            donationDate: null,
            donationDisplayStartDate: null,
            donationDisplayEndDate: null
        }
    },
    watch: {
        'editDonationObj.type': function (newValue, oldValue) {
            this.updateDonationDisplayEndDate();
        },
        'editDonationObj.donationDisplayStartDate': function (newValue, oldValue) {
            this.updateDonationDisplayEndDate();
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        screens() {
            return this.$store.getters.screens.data
        },
        screensIsLoading() {
            return this.$store.getters.screens.isLoading
        },
        templates() {
            return this.$store.getters.templates.data
        },
        templatesIsLoading() {
            return this.$store.getters.templates.isLoading
        },
        donation() {
              return this.$store.getters.donation.data
        },
        donationIsLoading() {
            return this.$store.getters.donation.isLoading
        },
        organizationScreens() {
            return this.$store.getters.organizationScreens.data
        },
        organizationScreensIsLoading() {
            return this.$store.getters.organizationScreens.isLoading
        },
        members() {
            return this.$store.getters.members.data
        },
        membersIsLoading() {
            return this.$store.getters.members.isLoading
        },
        account() {
            return this.$store.getters.account.data
        }
    },
    methods: {
        selectDonationDate(selectedDate) {
            this.editDonationObj.donationDate = this.$filters.convertHDateToGregorian(selectedDate)
            this.donationDate = this.$filters.convertToHebrewDate(this.editDonationObj.donationDate)
            this.closeModal()
        },
        selectDonationDisplayStartDate(selectedDate) {
            this.editDonationObj.donationDisplayStartDate = this.$filters.convertHDateToGregorian(selectedDate)
            this.donationDisplayStartDate = this.$filters.convertToHebrewDate(this.editDonationObj.donationDisplayStartDate)
            this.closeModal()
        },
        selectDonationDisplayEndDate(selectedDate) {
            this.editDonationObj.donationDisplayEndDate = this.$filters.convertHDateToGregorian(selectedDate)
            this.donationDisplayEndDate = this.$filters.convertToHebrewDate(this.editDonationObj.donationDisplayEndDate)
            this.closeModal()
        },
        updateDonationDisplayEndDate() {
            if (this.editDonationObj.donationDisplayStartDate) {
                let startDate = new Date(this.editDonationObj.donationDisplayStartDate);
                switch (this.editDonationObj.type) {
                    case DONATION_TYPE_DAILY:
                        startDate.setDate(startDate.getDate() + 1);
                        break;
                    case DONATION_TYPE_WEEKLY:
                        startDate.setDate(startDate.getDate() + 7);
                        break;
                    case DONATION_TYPE_MONTHLY:
                        startDate.setMonth(startDate.getMonth() + 1);
                        break;
                    case DONATION_TYPE_YEARLY:
                        startDate.setFullYear(startDate.getFullYear() + 1);
                        break;
                    default:
                        return;
                }
                this.editDonationObj.donationDisplayEndDate = startDate.toISOString().split('T')[0]; // Converts date object to YYYY-MM-DD format
            }
        },
        renderContent(template, donation) {
            let content = '';
            let replacements = []

            if(template) {
                content = template.content
                Object.keys(donation).forEach(key => {
                    const regex = new RegExp(`{${key}}`, 'g');
                    if(content) {
                        if(content.includes(`{${key}}`)) {
                            replacements.push({
                                replace: regex,
                                replaceBy: key == 'amount' ? this.$filters.formatPrice(donation[key], 'USD') : key == 'member' ? `${donation['member'].fname} ${donation['member'].lname}` : donation[key]
                            })
                        }
                    }

                });

                replacements.forEach((e) => {
                    content = content.replace(e.replace, e.replaceBy)
                })
            }
            return content;
        },
        setValues() {
            this.editDonationObj.id = this.selectedDonation.id
            this.editDonationObj.title = this.selectedDonation.template
            this.editDonationObj.member = this.selectedDonation.member
            this.editDonationObj.donorName = this.selectedDonation.donorName
            this.editDonationObj.type = this.selectedDonation.type
            this.editDonationObj.amount = this.selectedDonation.amount
            this.editDonationObj.purpose = this.selectedDonation.purpose
            this.editDonationObj.screen = this.selectedDonation.screen
            this.editDonationObj.donationDate = this.selectedDonation.donationDate
            this.editDonationObj.donationDisplayStartDate = this.selectedDonation.donationDisplayStartDate
            this.editDonationObj.donationDisplayEndDate = this.selectedDonation.donationDisplayEndDate
            this.donationDate = this.$filters.convertToHebrewDate(this.selectedDonation.donationDate)
            this.donationDisplayStartDate = this.$filters.convertToHebrewDate(this.selectedDonation.donationDisplayStartDate)
            this.donationDisplayEndDate = this.$filters.convertToHebrewDate(this.selectedDonation.donationDisplayEndDate)
            this.organizationId = this.selectedDonation.org
        },
        closeModal() {
            this.currentlyShowingJewDatePicker = null;
        },
        getTemplates() {
            this.$store.dispatch(GET_TEMPLATES, { org: this.$route.params.id })
        },
        getScreens() {
            this.$store.dispatch(GET_SCREENS)
        },
        getOrgnizationScreens() {
            this.$store.dispatch(GET_ORGANIZATION_SCREENS, { org: this.$route.params.id })
        },
        getMembers() {
            if (this.account.role == USER_ROLE_ADMIN) {
                this.$store.dispatch(GET_MEMBERS, { org: this.organizationId })
            } else {
                this.$store.dispatch(GET_MEMBERS, { org: this.account.org })
            }
        },
        closeDonationModal() {
            this.$emit('closeEditDonationModal')
        },
        editDonation() {
            this.editDonationObj.title = this.editDonationObj.title.id
            this.editDonationObj.memb = this.editDonationObj.member.id
            this.editDonationObj.scr = this.editDonationObj.scr.screen.id

            this.$store.dispatch(EDIT_DONATION, { ...this.editDonationObj }).then((result) => {
                if(result) {
                    this.$emit('refreshDonations')
                    this.$emit('closeEditDonationModal')
                }
            })           
        },
        
    },
    created() {
        this.setValues()
        this.getTemplates()
        this.getScreens()
        this.getMembers()
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    position: relative;
    left: 10%;
    margin-top: 50px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
}
</style>