<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeAssignSeatToMemberModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Assign Seat to Member (Organization)</span>
            <div class="modal__content">
                <editSynagogueModal v-if="showEditSynagogueModal" :selectedSynagogue="selectedSynagogue"
                    @close-edit-synagogue-modal="showEditSynagogueModal = false"
                    @refresh-synagogues="getSynagogues()" />
                <addSeatPriceModal v-if="showAddSeatPriceModal" :organizationId="organizationId"
                    @close-add-seat-price-modal="showAddSeatPriceModal = false" />
                <selectSeatMemberModal v-if="showSelectSeatMemberModal" :organizationId="organizationId"
                    @close-select-seat-member-modal="showSelectSeatMemberModal = false"
                    @select-member="selectSeatMember" />
                <addSeatsPledgeModal v-if="showAddSeatsPledgeModal" :organizationId="organizationId"
                    :memberSeats="memberSeats" @close-add-seat-pledge-modal="showAddSeatsPledgeModal = false"
                    @refresh-member-seats="getSynagogueSeats()" />
                <removeMemberSeatModal v-if="showRemoveMemberSeatModal" :selectedMember="selectedMember"
                    :selectedMemberSeatId="selectedMemberSeatId"
                    @close-remove-member-seat-modal="showRemoveMemberSeatModal = false"
                    @refresh-member-seats="getSynagogueSeats()" />
                <p v-if="errorMessage" class="text-center text-danger auth-status-msg">{{ errorMessage }}</p>
                <div class="card">
                    <div class="card-body">
                        <div class="row justify-content-between">
                            <div v-if="!selectedSynagogueObj" class="col">
                                <div class="dropdown seat-price-dropdown">
                                    <button class="btn btn-secondary dropdown-toggle" type="button"
                                        id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        {{ selectedSynagogue != null ? selectedSynagogue.name : 'Select Shul' }}
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <template v-bind:key="index" v-for="(synagogue, index) in synagogues">
                                            <a class="dropdown-item" v-on:click="selectSynagogue(synagogue)">{{
                                                synagogue.name }}</a>
                                        </template>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-auto zoom-controls">
                                <button @click="zoomIn" class="btn"><font-awesome-icon
                                        icon="fa-solid fa-magnifying-glass-plus" class="fa" /></button>
                                <button @click="zoomOut" class="btn"><font-awesome-icon
                                        icon="fa-solid fa-magnifying-glass-minus" class="fa" /></button>
                            </div>
                            <div class="col-md-auto">
                                <div class="toggle-view span">
                                    <span>Expanded View </span>
                                    <label class="form-switch">
                                        <input v-on:click="toggleExpandedView()" type="checkbox"
                                            :checked="isExpandedView">
                                        <i></i>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <Form @submit="saveSynagogueMemberSeat">
                            <div class="row justify-content-end modal__actions">
                                <button class="col-1 btn btn-save"
                                    :disabled="synagogueMemberSeatIsLoading ? true : false">
                                    <span v-if="synagogueMemberSeatIsLoading" class="spinner-border spinner-border-sm"
                                        role="status" aria-hidden="true"></span>
                                    <span v-if="!synagogueMemberSeatIsLoading">Register</span>
                                </button>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-12">
                                    <div id="seats" class="">
                                        <h6 v-if="synagogueSeatsIsLoading" class="text-center">Loading...</h6>
                                        <div v-else :style="zoomStyle">
                                            <div v-for="(row, rowIndex) in matrix" :key="rowIndex" class="row">
                                                <div class="col p-1" :class="{ 'expanded-view': isExpandedView }"
                                                    v-for="(value, colIndex) in row" :key="colIndex">
                                                    <!-- <button v-on:click="toggleSelection(rowIndex, colIndex)" type="button" -->
                                                    <button v-on:click="showMemberList(rowIndex, colIndex)"
                                                        type="button" class="btn btn-block"
                                                        :class="{ 'btn-seat-priced': isPriced(rowIndex, colIndex), 'btn-seat-not-priced': !isPriced(rowIndex, colIndex), 'btn-seat-selected': isSelected(rowIndex, colIndex), 'btn-seat-not-selected': !isSelected(rowIndex, colIndex), 'btn-seat-not-assigned': isSeatAssigned(rowIndex, colIndex) }"
                                                        :style="`background-color: ${selectedColor(rowIndex, colIndex)}!important`">
                                                        {{ value }}<br />
                                                        <span v-if="isExpandedView" class="member-name">{{
                                                            getSeatMemberName(rowIndex, colIndex) }}</span>
                                                        <div
                                                            class="row cancel-member-seat justify-content-center align-items-center">
                                                            <div class="col text-center">
                                                                <font-awesome-icon
                                                                    v-on:click="selectMemberSeatToDelete(rowIndex, colIndex)"
                                                                    icon="fa-solid fa-circle-xmark text-danger"
                                                                    class="fa" />
                                                            </div>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import editSynagogueModal from '@/components/modals/Synagogue/Edit_Synagogue_Modal.vue'
import addSeatPriceModal from '@/components/modals/Synagogue/Pricing/Add_New_Price.vue'
import selectSeatMemberModal from '@/components/modals/Synagogue/Seats/Select_Seat_Member_Modal.vue'
import addSeatsPledgeModal from '@/components/modals/Synagogue/Seats/Add_Seats_Pledge_Modal.vue'
import removeMemberSeatModal from '@/components/modals/Synagogue/Seats/Remove_Member_Seat_Modal.vue'

import { ADD_SYNAGOGUE_MEMBER_SEAT, GET_MEMBER, GET_SYNAGOGUES, GET_SYNAGOGUE_MEMBER_SEATS, GET_SYNAGOGUE_SEATS, GET_SYNAGOGUE_SEAT_PRICE_CATEGORIES, GET_SYNAGOGUE_SEAT_PRICINGS } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';
import { SEATS_MATRIX, TOAST_GLOBAL_CONFIG } from '@/utils/const';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircleXmark, faMagnifyingGlassPlus, faMagnifyingGlassMinus } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
library.add(faCircleXmark, faMagnifyingGlassPlus, faMagnifyingGlassMinus)

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        organizationId: String,
        selectedSynagogueObj: Object
    },
    components: {
        editSynagogueModal,
        addSeatPriceModal,
        selectSeatMemberModal,
        addSeatsPledgeModal,
        removeMemberSeatModal,
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage
    },
    data() {
        return {
            SEATS_MATRIX,
            showModal: true,
            isExpandedView: false,
            showEditSynagogueModal: false,
            showAddSeatPriceModal: false,
            showSelectSeatMemberModal: false,
            showAddSeatsPledgeModal: false,
            showRemoveMemberSeatModal: false,
            seatDesign: {
                name: null
            },
            rows: 20,
            cols: 20,
            matrix: [],
            pricedSeats: [],
            memberSeats: [],
            selected: [],
            selectedSynagogueSeatPriceCategory: null,
            selectedSynagogue: null,
            selectedMember: null,
            selectedRowIndex: null,
            selectedColIndex: null,
            selectedMemberSeatId: null,
            zoomLevel: 1
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        synagogueMemberSeatIsLoading() {
            return this.$store.getters.synagogueMemberSeat.isLoading
        },
        synagogueMemberSeat() {
            return this.$store.getters.synagogueMemberSeat.data
        },
        synagogueMemberSeatsIsLoading() {
            return this.$store.getters.synagogueMemberSeats.isLoading
        },
        synagogueMemberSeats() {
            return this.$store.getters.synagogueMemberSeats.data
        },
        synagogueSeatPricings() {
            return this.$store.getters.synagogueSeatPricings.data
        },
        synagogueSeatPricingsIsLoading() {
            return this.$store.getters.synagogueSeatPricings.isLoading
        },
        synagogues() {
            return this.$store.getters.synagogues.data
        },
        synagoguesIsLoading() {
            return this.$store.getters.synagogues.isLoading
        },
        synagogueSeats() {
            return this.$store.getters.synagogueSeats.data
        },
        synagogueSeatsIsLoading() {
            return this.$store.getters.synagogueSeats.isLoading
        },
        synagogueSeatPriceCategories() {
            return this.$store.getters.synagogueSeatPriceCategories.data
        },
        synagogueSeatPriceCategoriesIsLoading() {
            return this.$store.getters.synagogueSeatPriceCategories.isLoading
        },
        member() {
            return this.$store.getters.member.data
        },
        memberIsLoading() {
            return this.$store.getters.member.isLoading
        },
        account() {
            return this.$store.getters.account.data
        },
        zoomStyle() {
            return {
                transform: `scale(${this.zoomLevel})`,
                transformOrigin: '0% 0%', // Anchor zoom to the top-left corner
                transition: 'transform 0.3s ease'
            };
        }
    },
    methods: {
        zoomIn() {
            this.zoomLevel *= 1.1; // Increase the zoom level by 10%
        },
        zoomOut() {
            this.zoomLevel /= 1.1; // Decrease the zoom level by 10%
        },
        showMemberList(rowIndex, colIndex) {
            let isSeatAssigned = this.isSeatAssigned(rowIndex, colIndex)
            if (isSeatAssigned) {
                this.selectMemberSeatToDelete(rowIndex, colIndex)
            } else {
                if (this.isPriced(rowIndex, colIndex)) {
                    this.showSelectSeatMemberModal = true
                    this.selectedRowIndex = rowIndex
                    this.selectedColIndex = colIndex
                } else {
                    this.$toast.info('Please assign prices to the seats first.', TOAST_GLOBAL_CONFIG);
                }
            }
        },
        selectSeatMember(selectedMember) {
            this.selectedMember = selectedMember
            this.toggleSelection()
        },
        generateMatrix() {
            this.matrix = []
            let value = 1;
            for (let i = 0; i < this.selectedSynagogue.rows; i++) {
                let row = [];
                for (let j = 0; j < this.selectedSynagogue.cols; j++) {
                    row.push(value);
                    value++;
                }
                this.matrix.push(row);
            }
        },
        toggleSelection(rowIndex, colIndex) {
            let isSeatAssigned = this.isSeatAssigned(rowIndex, colIndex)
            if (isSeatAssigned) {
                this.selectMemberSeatToDelete(rowIndex, colIndex)
            } else {
                if (this.selectedSynagogue != null) {
                    const index = this.memberSeats.findIndex(item => item.row === this.selectedRowIndex && item.col === this.selectedColIndex);
                    console.log('indexindex', index)
                    if (index >= 0) {
                        this.memberSeats.splice(index, 1)
                    } else {
                        let index = this.pricedSeats.findIndex(item => item.row === this.selectedRowIndex && item.col === this.selectedColIndex);
                        let selectedSynagogueSeatPricing = this.pricedSeats[index]
                        if (selectedSynagogueSeatPricing != null) {
                            this.memberSeats.push({
                                seatPricing: selectedSynagogueSeatPricing.seatPricing,
                                price: selectedSynagogueSeatPricing.price,
                                syngSeat: selectedSynagogueSeatPricing.syngSeat,
                                syng: this.selectedSynagogue.id,
                                memb: this.selectedMember.id,
                                member: this.selectedMember,
                                org: this.account.org,
                                row: this.selectedRowIndex,
                                col: this.selectedColIndex,
                                isNew: true

                            })
                        }
                    }
                } else {
                    this.$toast.info('Please select a Shul first.', TOAST_GLOBAL_CONFIG);
                }
            }
            
        },
        getSeatMemberName(rowIndex, colIndex) {
            if (this.isExpandedView) {
                const index = this.memberSeats.findIndex(item => item.row === rowIndex && item.col === colIndex);
                console.log('indexindex', index)
                if (index >= 0) {
                    return this.memberSeats[index].member.displayName ?? `${this.memberSeats[index].member.fname} ${this.memberSeats[index].member.lname}`
                }
            }
        },
        isPriced(rowIndex, colIndex) {
            return this.pricedSeats.some(item => item.row === rowIndex && item.col === colIndex);
        },
        isSelected(rowIndex, colIndex) {
            // let index = this.memberSeats.findIndex(item => item.row === rowIndex && item.col === colIndex);
            // if (index >= 0) {
            //     return this.memberSeats.some(item => item.syngSeat === this.memberSeats[index].syngSeat);
            // }
            return this.selected.some(item => item.row === rowIndex && item.col === colIndex);
        },
        selectedColor(rowIndex, colIndex) {
            const index = this.pricedSeats.findIndex(item => item.row === rowIndex && item.col === colIndex);
            if (index >= 0) {
                return this.pricedSeats[index].colorHex
            }
        },
        setSelectedSeats() {
            this.selected = this.synagogueSeats.map((e) => {
                const seat = {
                    row: e.rowIndex,
                    col: e.colIndex
                };
                return seat;
            })
        },
        setValues() {
           
        },
        isSeatAssigned(rowIndex, colIndex) {
            const index = this.memberSeats.findIndex(item => item.row === rowIndex && item.col === colIndex);
            if (index >= 0) {
                return true
            } else {
                return false
            }
        },
        selectMemberSeatToDelete(rowIndex, colIndex) {
            const index = this.memberSeats.findIndex(item => item.row === rowIndex && item.col === colIndex);
            if (index >= 0) {
                this.selectedMemberSeatId = this.memberSeats[index].id
                this.selectedMember = this.memberSeats[index].member
                this.showRemoveMemberSeatModal = true
            }
        },
        toggleExpandedView() {
            if (this.isExpandedView == true) {
                this.isExpandedView = false
            } else {
                this.isExpandedView = true
            }
        },
        markPricedSeats() {
            this.pricedSeats = this.synagogueSeatPricings.map((e) => {
                const seat = {
                    seatPricing: e.id,
                    price: e.synagogueSeatPriceCategory != null ? e.synagogueSeatPriceCategory.price : null,
                    seatPriceCat: e.seatPriceCat,
                    colorHex: e.synagogueSeatPriceCategory != null ? e.synagogueSeatPriceCategory.colorHex : null,
                    syngSeat: e.syngSeat,
                    syng: e.syng,
                    org: e.org,
                    row: e.synagogueSeat.rowIndex,
                    col: e.synagogueSeat.colIndex
                };
                return seat;
                
            })
        },
        markSelectedSeats() {
            this.memberSeats = this.synagogueMemberSeats.map((e) => {
                const memberSeat = {
                    id: e.id,
                    seatPricing: e.seatPricing,
                    price: e.synagogueSeatPricing.synagogueSeatPriceCategory.price,
                    syngSeat: e.syngSeat,
                    syng: e.syng,
                    memb: e.memb,
                    member: e.member,
                    org: e.org,
                    row: e.synagogueSeat.rowIndex,
                    col: e.synagogueSeat.colIndex,
                    isNew: false
                }
                return memberSeat
            })
        },
        selectSynagogue(synagogue) {
            if (synagogue.id != this.selectedSynagogue.id) {
                this.selectedSynagogue = synagogue
                this.getSynagogueSeats()
            }
        },
        addSynagogeSeatPrice() {
            this.showAddSeatPriceModal = true
        },
        editSynagogueSeats() {
            this.showEditSynagogueModal = true
        },
        getSynagogues() {
            if(this.selectedSynagogueObj != null) {
                this.selectedSynagogue = this.selectedSynagogueObj
                this.getSynagogueSeats()
                return
            } else {
                this.$store.dispatch(GET_SYNAGOGUES, { org: this.account.org }).then((res) => {
                    if (res) {
                        if (this.synagogues) {
                            this.selectedSynagogue = this.synagogues[0]
                            this.getSynagogueSeats()
                        }
                    }
                })
            }
        },
        getSynagogueSeatPriceCategory() {
            this.$store.dispatch(GET_SYNAGOGUE_SEAT_PRICE_CATEGORIES, { org: this.account.org })
        },
        getSynagogueSeatPricing() {
            this.$store.dispatch(GET_SYNAGOGUE_SEAT_PRICINGS, { syng: this.selectedSynagogue.id, org: this.account.org })
        },
        getSynagogueSeats() {
            this.generateMatrix()
            this.$store.dispatch(GET_SYNAGOGUE_SEATS, { syng: this.selectedSynagogue.id, org: this.account.org }).then((res) => {
                this.markPricedSeats()
                this.getMemberSeats()
                this.setSelectedSeats()
            })
        },
        getMemberSeats() {
            this.$store.dispatch(GET_SYNAGOGUE_MEMBER_SEATS, { syng: this.selectedSynagogue.id, org: this.account.org }).then((res) => {
                this.markSelectedSeats()
            })
        },
        saveSynagogueMemberSeat() {
            // this.$store.dispatch(ADD_SYNAGOGUE_MEMBER_SEAT, { data: this.memberSeats }).then((res) => {
            //     if (res) {
            //         this.getMemberSeats()
            //     }
            // })

            let hasNewSelectedSeat = this.memberSeats.some(item => item.isNew == true);
            if (hasNewSelectedSeat) {
                this.showAddSeatsPledgeModal = true
            } else {
                this.$toast.info('You have not assigned a new seat.', TOAST_GLOBAL_CONFIG);
            }
        },
    },
    mounted() {
        this.setValues()
        this.getSynagogues()
        // this.getSynagogueSeats()
        this.getSynagogueSeatPricing()
        this.getSynagogueSeatPriceCategory()
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 98%;
    position: relative;
    left: 1%;
    margin-top: 20px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    /*margin: 0 1rem;
    padding: 1rem;*/
    padding-top: 0rem;
    padding-bottom: 0rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
    max-height: 96vh;
    overflow-x: auto;
    overflow-y: auto;
}

::v-deep .modal__content {
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
}

#seats .row {
    width: 15000px;
    overflow-x: scroll;
}

#seats .row .col {
    max-width: 67px !important;
}

.modal__content .zoom-controls {
    position: relative;
    bottom: 5px;
}

.modal__content .zoom-controls .btn {
    font-size: 17px;
    color: #6489C4;
}

.modal__content .zoom-controls .btn:hover {
    color: #14243D;
}

.modal__content .btn-seat-not-assigned {
    opacity: 0.6 !important;
}

.modal__content .btn-seat-not-assigned:hover {
    opacity: 0.9 !important;
    background-color: #EBEBF8FF!important;
    border: 2px solid #dc3545;
}

.cancel-member-seat {
    display: none;
}

.cancel-member-seat .fa {
    font-size: 20px;
    color: #dc3545!important;
    position: absolute;
    top: -17px;
    left: 21px;
}

#seats .btn-seat-not-curr-members:hover .cancel-member-seat {
    display: block;
    color: #EBEBF8FF !important;
}

.modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    margin-top: 0px;
    margin-right: 15px !important;
}

.modal__title {
    font-size: 18px;
    font-weight: 600;
}

.modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}

.modal__content .nav-tabs .nav-link {
    font-size: 15px !important;
}

.modal__content .btn-add {
    float: right;
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
    color: #14243D !important;
    padding: 0px, 14px, 0px, 14px !important;
    margin-bottom: 5px;
}

.modal__content .seat-price-dropdown .dropdown-toggle {
    font-size: 13px;
    border-radius: 8px;
    color: #14243D;
    border-radius: 8px;
    border: 1px solid transparent;
    background-color: #D9E2F1;
    margin-bottom: 10px;
}

.modal__content .seat-price-dropdown .dropdown-menu {
    border-radius: 8px;
    border: 1px solid #E0E2E7;
}

.modal__content .seat-price-dropdown .dropdown-item {
    font-size: 14px;
    cursor: pointer;
}

.modal__content .seat-price-dropdown .dropdown-item:hover {
    background-color: #F5F5FB;
}

.modal__content .seat-price-dropdown .dropdown-item:active {
    color: #212529;
    background-color: #edf4ff;
}

.modal__content .btn-seat-not-priced {
    font-size: 10px;
    font-weight: 700;
    color: transparent;
}

.modal__content .btn-seat-priced {
    font-size: 10px;
    font-weight: 700;
    background-color: #6489C4;
    color: #FFFFFF;
    cursor: pointer
}

.modal__content .btn-seat-priced:hover {
    cursor: pointer
}

.modal__content .btn-seat-not-selected {
    font-size: 10px;
    font-weight: 700;
    color: transparent;
    background-color: #F8F8FA;
}

.modal__content .btn-seat-selected {
    font-size: 10px;
    font-weight: 700;
    background-color: #6489C4!important;
    color: #FFFFFF;
}

.modal__content .btn-block {
    cursor: default;
}

::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
* {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.floor-plan-col {
    margin-left: 0px;
    padding-left: 0px;
}

hr {
    border: 1px solid #EDEDF1;
}

.btn-save {
    margin-top: 10px;
    background-color: #14243D;
}

.toggle-view span {
    font-size: 13px;
    position: relative;
    right: 10px;
    bottom: 3px;
    color: #314057;
}

#seats .expanded-view {
    height: 64px;
    max-width: 90px!important;
    margin-right: 5px;
    padding: 0px 5px 0px 5px;
}

#seats .expanded-view .btn {
    height: 60px;
    width: 70px !important;
    margin-right: 5px;
    padding: 0px 3px 0px 3px;
}

#seats .member-name {
    font-weight: 400;
    font-size: 9px;
    padding-bottom: 0px;
    margin-top: 2px;
    margin-bottom: 0px;
    /* cut overflow */
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Number of lines you want to display */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 30px;
    line-height: 15px;
}


button {
    font-size: 15px;
}
</style>