<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeAddAnnouncementModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Add Announcement</span>
            <br>
            <div class="modal__content">
                <JewishDatePicker v-if="currentlyShowingJewDatePicker == DATE_PICKER_ANNOUNCEMENT_DISPLAY_START_DATE"
                    @select-date="selectAnnouncementDisplayStartDate" @close-date-picker-modal="closeModal()" />
                <JewishDatePicker v-if="currentlyShowingJewDatePicker == DATE_PICKER_ANNOUNCEMENT_DISPLAY_END_DATE"
                    @select-date="selectAnnouncementDisplayEndDate" @close-date-picker-modal="closeModal()" />
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <p v-if="errorMessage" class="text-center text-danger auth-status-msg">{{ errorMessage
                                    }}</p>
                                <Form @submit="addAnnouncement">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="title">Title</label>
                                                <Field v-model="addAnnouncementObj.title" rules="required" type="text"
                                                    name="Title" class="form-control" id="title" placeholder="Title" />
                                                <ErrorMessage name="title" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="amount">Screen</label>
                                                <select v-model="addAnnouncementObj.scr" class="form-control"
                                                    rules="required">
                                                    <option>---</option>
                                                    <template v-bind:key="index" v-for="(screen, index) in screens">
                                                        <option :value="screen">{{ screen.name }}</option>
                                                    </template>
                                                </select>
                                                <ErrorMessage name="amount" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div @click="currentlyShowingJewDatePicker = DATE_PICKER_ANNOUNCEMENT_DISPLAY_START_DATE"
                                                class="form-group">
                                                <label for="announcementDisplayStartDate">Display Start Date</label>
                                                <div class="row date-picker-row">
                                                    <div class="col-10">
                                                        <Field v-model="announcementDisplayStartDate" rules="required"
                                                            disabled type="text" name="announcementDisplayStartDate"
                                                            class="form-control" id="announcementDisplayStartDate"
                                                            placeholder="Announcement Display Start Date" />
                                                    </div>
                                                    <div class="col-2">
                                                        <i class="pi pi-calendar-plus text-primary"></i>
                                                    </div>
                                                </div>
                                                <ErrorMessage name="announcementDisplayStartDate"
                                                    class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div @click="currentlyShowingJewDatePicker = DATE_PICKER_ANNOUNCEMENT_DISPLAY_END_DATE"
                                                class="form-group">
                                                <label for="announcementDisplayEndDate">Display End Date</label>
                                                <div class="row date-picker-row">
                                                    <div class="col-10">
                                                        <Field v-model="announcementDisplayEndDate" rules="required"
                                                            disabled type="text" name="announcementDisplayEndDate"
                                                            class="form-control" id="announcementDisplayEndDate"
                                                            placeholder="Announcement Display End Date" />
                                                    </div>
                                                    <div class="col-2">
                                                        <i class="pi pi-calendar-plus text-primary"></i>
                                                    </div>
                                                </div>
                                                <ErrorMessage name="announcementDisplayEndDate"
                                                    class="form-text text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <label for="announcement">Announcement</label>
                                    <QuillEditor ref="myQuillEditor" :options="editorOption" style="height: 250px;">
                                    </QuillEditor>
                                    <br>
                                    <div class="row justify-content-end modal__actions">
                                        <button class="col-12 btn btn-save"
                                            :disabled="announcementIsLoading ? true : false">
                                            <span v-if="announcementIsLoading" class="spinner-border spinner-border-sm"
                                                role="status" aria-hidden="true"></span>
                                            <span v-if="!announcementIsLoading"> Save</span>
                                        </button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import Quill from 'quill';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import { ADD_ANNOUNCEMENT, GET_SCREENS } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';

import JewishDatePicker from '@/components/JewishDatePicker.vue';
import { DATE_PICKER_ANNOUNCEMENT_DISPLAY_START_DATE, DATE_PICKER_ANNOUNCEMENT_DISPLAY_END_DATE } from '@/utils/const';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        organizationId: String
    },
    components: {
        JewishDatePicker,
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage,
        QuillEditor
    },
    data () {
        return {
            DATE_PICKER_ANNOUNCEMENT_DISPLAY_START_DATE,
            DATE_PICKER_ANNOUNCEMENT_DISPLAY_END_DATE,
            showModal: true,
            addAnnouncementObj: {
                title: null,
                message: null,
                scr: null,
                org: null,
                announcementDisplayStartDate: null,
                announcementDisplayEndDate: null
            },
            announcementDisplayStartDate: null,
            announcementDisplayEndDate: null,
            currentlyShowingJewDatePicker: null,
            editorOption: {
                modules: {
                    toolbar: [
                        [{ 'font': ['TzlilimPM', 'Pyutim'] }],
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
                        [{ 'direction': 'rtl' }],                         // text direction
                        [{ 'align': [] }],
                        ['link', 'image', 'video', 'formula'],            // add's image support
                        ['clean']
                    ]
                }
            }
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        screens() {
            return this.$store.getters.screens.data
        },
        screensIsLoading() {
            return this.$store.getters.screens.isLoading
        },
        announcement() {
              return this.$store.getters.announcement.data
        },
        announcementIsLoading() {
            return this.$store.getters.announcement.isLoading
        }
    },
    methods: {
        setValues() {
            this.addAnnouncementObj.org = this.organizationId
        },
        registerFonts() {
            const Font = Quill.import('formats/font');
            Font.whitelist = ['TzlilimPM', 'Pyutim']; // add custom fonts to the whitelist
            Quill.register(Font, true);
        },
        closeModal() {
            this.currentlyShowingJewDatePicker = null;
        },
        selectAnnouncementDisplayStartDate(selectedDate) {
            this.addAnnouncementObj.announcementDisplayStartDate = this.$filters.convertHDateToGregorian(selectedDate)
            this.announcementDisplayStartDate = this.$filters.convertToHebrewDate(this.addAnnouncementObj.announcementDisplayStartDate)
            this.closeModal()
        },
        selectAnnouncementDisplayEndDate(selectedDate) {
            this.addAnnouncementObj.announcementDisplayEndDate = this.$filters.convertHDateToGregorian(selectedDate)
            this.announcementDisplayEndDate = this.$filters.convertToHebrewDate(this.addAnnouncementObj.announcementDisplayEndDate)
            this.closeModal()
        },
        getScreens() {
            this.$store.dispatch(GET_SCREENS)
        },
        addAnnouncement() {   
            this.addAnnouncementObj.message = this.$refs.myQuillEditor.getHTML()

            this.$store.dispatch(ADD_ANNOUNCEMENT, this.addAnnouncementObj).then((result) => {
                if(result) {
                    this.$emit('closeAddAnnouncementModal')
                    this.$emit('refreshAnnouncements')
                }
            })           
        },
    },
    mounted() {
        this.setValues()
        this.getScreens()
        this.$nextTick(() => {
            if (this.$refs.myQuillEditor && this.$refs.myQuillEditor.getQuill) {
                console.log("Quill instance:", this.$refs.myQuillEditor.getQuill());
            } else {
                console.error("Quill editor is not available.");
            }
        });
        this.registerFonts()
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    position: relative;
    left: 25%;
    margin-top: 60px;
}
::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
}
.modal__title {
    font-size: 18px;
    font-weight: 600;
}
.modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}

.modal__content {
}

.modal__content .nav-tabs .nav-link {
    font-size: 15px!important;
}

.btn-save {
    background-color: #14243D;
}
</style>