import axios, { AxiosResponse } from 'axios'
import { SET_TICKETS, SET_TICKET, SET_ERRORMSG } from '../mutation_types'
import {
    CLEAR_TICKETS_ISLOADING,
    DELETE_TICKET,
    EDIT_TICKET,
    GET_TICKETS,
    ADD_TICKET,
    CLOSE_TICKET,
} from '../action_types'
import { StateObject } from '@/dtos/state.dto'
import Ticket from '@/services/ticket'
import { Module } from 'vuex'
import { EditTicket, GetTicket, RegisterTicket } from '@/dtos/ticket.dto'

const ticket: Module<any, {}> = {
    state: {
        ticket: {
            data: null,
            isLoading: false
        },
        tickets: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        ticket: (state: { ticket: any }) => state.ticket,
        tickets: (state: { tickets: any }) => state.tickets
    },
    mutations: {
        [SET_TICKET]: (state: { ticket: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.ticket.data = payload.data : null, state.ticket.isLoading = payload.isLoading },
        [SET_TICKETS]: (state: { tickets: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.tickets.data = payload.data : null, state.tickets.isLoading = payload.isLoading }
    },
    actions: {
        [ADD_TICKET]: async({ commit }: any, payload: any) => {
            commit(SET_TICKET, { data: null, isLoading: true })

            let result: AxiosResponse = await Ticket.registerTicket(payload as RegisterTicket)
          
            if(result) {
                if (result.data.status) {
                    commit(SET_TICKET, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_TICKET, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_TICKET, { isLoading: false })
                return false;
            }
        },
        [GET_TICKETS]: async({ commit }: any, payload: any) => {
            commit(SET_TICKETS, { data: null, isLoading: true })

            let result: AxiosResponse = await Ticket.getTicket(payload as GetTicket)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_TICKETS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_TICKETS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_TICKETS, { isLoading: false })
                return false;
            }
        },
        [EDIT_TICKET]: async({ commit }: any, payload: any) => {
            commit(SET_TICKET, { data: null, isLoading: true })

            let result: AxiosResponse = await Ticket.editTicket(payload as EditTicket)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_TICKET, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_TICKET, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_TICKET, { isLoading: false })
                return false;
            }
        },
        [CLOSE_TICKET]: async({ commit }: any, payload: any) => {
            commit(SET_TICKET, { data: null, isLoading: true })

            let result: AxiosResponse = await Ticket.closeTicket(payload as EditTicket)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_TICKET, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_TICKET, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_TICKET, { isLoading: false })
                return false;
            }
        },
        [DELETE_TICKET]: async({ commit }: any, payload: string) => {
            commit(SET_TICKET, { data: null, isLoading: true })

            let result: AxiosResponse = await Ticket.deleteTicket(payload)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_TICKET, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_TICKET, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_TICKET, { isLoading: false })
                return false;
            }
        },
        [CLEAR_TICKETS_ISLOADING]: ({ commit }) => {
            commit(SET_TICKET, { isLoading: false })
            commit(SET_TICKETS, { isLoading: false })
        }
    }
}

export default ticket