import axios, { AxiosResponse } from 'axios'
import { SET_PLEDGE_ITEM_PAYMENT_LOGS, SET_PLEDGE_ITEM_PAYMENT_LOG, SET_ERRORMSG } from '../mutation_types'
import {
    CLEAR_PLEDGE_ITEM_PAYMENT_LOGS_ISLOADING,
    GET_PLEDGE_ITEM_PAYMENT_LOGS
} from '../action_types'
import { StateObject } from '@/dtos/state.dto'
import PledgeItemPurchaseLog from '@/services/pledge_item_payment_log'
import { Module } from 'vuex'
import { GetPledgeItemPaymentLog } from '@/dtos/pledge_item_payment_log.dto'

const pledgeItemPaymentLog: Module<any, {}> = {
    state: {
        pledgeItemPaymentLog: {
            data: null,
            isLoading: false
        },
        pledgeItemPaymentLogs: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        pledgeItemPaymentLog: (state: { pledgeItemPaymentLog: any }) => state.pledgeItemPaymentLog,
        pledgeItemPaymentLogs: (state: { pledgeItemPaymentLogs: any }) => state.pledgeItemPaymentLogs
    },
    mutations: {
        [SET_PLEDGE_ITEM_PAYMENT_LOG]: (state: { pledgeItemPaymentLog: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.pledgeItemPaymentLog.data = payload.data : null, state.pledgeItemPaymentLog.isLoading = payload.isLoading },
        [SET_PLEDGE_ITEM_PAYMENT_LOGS]: (state: { pledgeItemPaymentLogs: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.pledgeItemPaymentLogs.data = payload.data : null, state.pledgeItemPaymentLogs.isLoading = payload.isLoading }
    },
    actions: {
        [GET_PLEDGE_ITEM_PAYMENT_LOGS]: async({ commit }: any, payload: any) => {
            commit(SET_PLEDGE_ITEM_PAYMENT_LOGS, { data: null, isLoading: true })

            let result: AxiosResponse = await PledgeItemPurchaseLog.getPledgeItemPaymentLogs(payload as GetPledgeItemPaymentLog)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_PLEDGE_ITEM_PAYMENT_LOGS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_PLEDGE_ITEM_PAYMENT_LOGS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_PLEDGE_ITEM_PAYMENT_LOGS, { isLoading: false })
                return false;
            }
        },
        [CLEAR_PLEDGE_ITEM_PAYMENT_LOGS_ISLOADING]: ({ commit }) => {
            commit(SET_PLEDGE_ITEM_PAYMENT_LOG, { isLoading: false })
            commit(SET_PLEDGE_ITEM_PAYMENT_LOGS, { isLoading: false })
        }
    }
}

export default pledgeItemPaymentLog