import axios, { AxiosResponse } from 'axios'
import { SET_TEMPLATES, SET_TEMPLATE, SET_ERRORMSG } from '../mutation_types'
import {
    CLEAR_TEMPLATE_ISLOADING,
    DELETE_TEMPLATE,
    EDIT_TEMPLATE,
    GET_TEMPLATES,
    ADD_TEMPLATE,
} from '../action_types'
import { StateObject } from '@/dtos/state.dto'
import Template from '@/services/template'
import { Module } from 'vuex'
import { EditTemplate, GetTemplate, RegisterTemplate } from '@/dtos/template.dto'

const template: Module<any, {}> = {
    state: {
        template: {
            data: null,
            isLoading: false
        },
        templates: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        template: (state: { template: any }) => state.template,
        templates: (state: { templates: any }) => state.templates
    },
    mutations: {
        [SET_TEMPLATE]: (state: { template: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.template.data = payload.data : null, state.template.isLoading = payload.isLoading },
        [SET_TEMPLATES]: (state: { templates: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.templates.data = payload.data : null, state.templates.isLoading = payload.isLoading }
    },
    actions: {
        [ADD_TEMPLATE]: async({ commit }: any, payload: any) => {
            commit(SET_TEMPLATE, { data: null, isLoading: true })

            let result: AxiosResponse = await Template.registerTemplate(payload as RegisterTemplate)
          
            if(result) {
                if (result.data.status) {
                    commit(SET_TEMPLATE, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_TEMPLATE, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_TEMPLATE, { isLoading: false })
                return false;
            }
        },
        [GET_TEMPLATES]: async({ commit }: any, payload: any) => {
            commit(SET_TEMPLATES, { data: null, isLoading: true })

            let result: AxiosResponse = await Template.getTemplate(payload as GetTemplate)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_TEMPLATES, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_TEMPLATES, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_TEMPLATES, { isLoading: false })
                return false;
            }
        },
        [EDIT_TEMPLATE]: async({ commit }: any, payload: any) => {
            commit(SET_TEMPLATE, { data: null, isLoading: true })

            let result: AxiosResponse = await Template.editTemplate(payload as EditTemplate)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_TEMPLATE, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_TEMPLATE, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_TEMPLATE, { isLoading: false })
                return false;
            }
        },
        [DELETE_TEMPLATE]: async({ commit }: any, payload: string) => {
            commit(SET_TEMPLATE, { data: null, isLoading: true })

            let result: AxiosResponse = await Template.deleteTemplate(payload)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_TEMPLATE, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_TEMPLATE, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_TEMPLATE, { isLoading: false })
                return false;
            }
        },
        [CLEAR_TEMPLATE_ISLOADING]: ({ commit }) => {
            commit(SET_TEMPLATE, { isLoading: false })
            commit(SET_TEMPLATES, { isLoading: false })
        }
    }
}

export default template