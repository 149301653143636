import axios, { AxiosResponse } from 'axios'
import { SET_ORGANIZATION_SCREENS, SET_ORGANIZATION_SCREEN, SET_ERRORMSG } from '../mutation_types'
import {
    CLEAR_ORGANIZATION_SCREENS_ISLOADING,
    DELETE_ORGANIZATION_SCREEN,
    EDIT_ORGANIZATION_SCREEN,
    GET_ORGANIZATION_SCREENS,
    REGISTER_ORGANIZATION_SCREEN
} from '../action_types'
import { StateObject } from '@/dtos/state.dto'
import OrganizationScreen from '@/services/organization_screen'
import { Module } from 'vuex'
import { EditOrganizationScreen, RegisterOrganizationScreen, GetOrganizationScreen } from '@/dtos/organization_screen.dto'

const organizationScreen: Module<any, {}> = {
    state: {
        organizationScreen: {
            data: null,
            isLoading: false
        },
        organizationScreens: {
            data: null,
            isLoading: false
        },
    },
    getters: {
        organizationScreen: (state: { organizationScreen: any }) => state.organizationScreen,
        organizationScreens: (state: { organizationScreens: any }) => state.organizationScreens,
    },
    mutations: {
        [SET_ORGANIZATION_SCREEN]: (state: { organizationScreen: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.organizationScreen.data = payload.data : null, state.organizationScreen.isLoading = payload.isLoading },
        [SET_ORGANIZATION_SCREENS]: (state: { organizationScreens: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.organizationScreens.data = payload.data : null, state.organizationScreens.isLoading = payload.isLoading }
    },
    actions: {
        [REGISTER_ORGANIZATION_SCREEN]: async({ commit }: any, payload: any) => {
            commit(SET_ORGANIZATION_SCREEN, { data: null, isLoading: true })

            let result: AxiosResponse = await OrganizationScreen.registerOrganizationScreen(payload as RegisterOrganizationScreen)
          
            if(result) {
                if (result.data.status) {
                    commit(SET_ORGANIZATION_SCREEN, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_ORGANIZATION_SCREEN, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ORGANIZATION_SCREEN, { isLoading: false })
                return false;
            }
        },
        [GET_ORGANIZATION_SCREENS]: async({ commit }: any, payload: any) => {
            commit(SET_ORGANIZATION_SCREENS, { data: null, isLoading: true })

            let result: AxiosResponse = await OrganizationScreen.getOrganizationScreens(payload as GetOrganizationScreen)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_ORGANIZATION_SCREENS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_ORGANIZATION_SCREENS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ORGANIZATION_SCREENS, { isLoading: false })
                return false;
            }
        },
        [EDIT_ORGANIZATION_SCREEN]: async({ commit }: any, payload: any) => {
            commit(SET_ORGANIZATION_SCREEN, { data: null, isLoading: true })

            let result: AxiosResponse = await OrganizationScreen.editOrganizationScreen(payload as EditOrganizationScreen)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_ORGANIZATION_SCREEN, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_ORGANIZATION_SCREEN, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ORGANIZATION_SCREEN, { isLoading: false })
                return false;
            }
        },
        [DELETE_ORGANIZATION_SCREEN]: async({ commit }: any, payload: string) => {
            commit(SET_ORGANIZATION_SCREEN, { data: null, isLoading: true })

            let result: AxiosResponse = await OrganizationScreen.deleteOrganizationScreen(payload)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_ORGANIZATION_SCREEN, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_ORGANIZATION_SCREEN, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ORGANIZATION_SCREEN, { isLoading: false })
                return false;
            }
        },
        [CLEAR_ORGANIZATION_SCREENS_ISLOADING]: ({ commit }) => {
            commit(SET_ORGANIZATION_SCREEN, { isLoading: false })
            commit(SET_ORGANIZATION_SCREENS, { isLoading: false })
        }
    }
}

export default organizationScreen