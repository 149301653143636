<template>
    <div class="dashboard-body">
        <div class="row">
            <sidenav class="custom-col-width-2" />
            <div style="padding-left: 0px!important" class="custom-col-width-10 content-section dashboard-content">
                <navbar />
                <designSeatModal v-if="showDesignSeatModal" @close-design-seat-modal="showDesignSeatModal = false"
                    @refresh-design-seats="getDetails()" @refresh-synagogues="getSynagogues()" />
                <viewSeatDesignModal v-if="showViewSeatDesignModal" :selectedSynagogue="selectedSynagogue"
                    @close-view-seat-design-modal="showViewSeatDesignModal = false" />
                <editSynagogueModal v-if="showEditSynagogueModal" :selectedSynagogue="selectedSynagogue"
                    @close-edit-synagogue-modal="showEditSynagogueModal = false"
                    @refresh-synagogues="getSynagogues()" />
                <deleteSynagogueModal v-if="showDeleteSynagogueModal" :selectedSynagogueId="selectedSynagogueId"
                    @close-delete-synagogue-modal="showDeleteSynagogueModal = false"
                    @refresh-synagogues="getSynagogues()" />
                <setSynagogueSeatPriceModal v-if="showSynagogueSeatPriceModal" :selectedSynagogue="selectedSynagogue"
                    @close-synagogue-seat-price-modal="showSynagogueSeatPriceModal = false" />
                <assignMemberSeatModal v-if="showAssignMemberSeatModal" :selectedSynagogueObj="selectedSynagogue"
                    :organizationId="organizationId"
                    @close-assign-seat-to-member-modal="showAssignMemberSeatModal = false" />
                <div class="content">
                    <br>
                    <div class="row button-grp justify-content-between">
                        <div class="col">
                            <h4>Seats</h4>
                        </div>
                        <div class="col">
                            <button v-on:click="showDesignSeatModal = true" class="btn btn-add"><i
                                    class="pi pi-plus"></i>
                                Add Design</button>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <template v-bind:key="index" v-for="(synagogue, index) in synagogues">
                            <div class="col-4">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row synagogue-actions">
                                            <div class="col">
                                                <font-awesome-icon v-on:click="selectAssignSeatToMember(synagogue)"
                                                    icon="fa-solid fa-people-arrows" class="fa" />
                                                <font-awesome-icon v-on:click="selectSynagogueToSetSeatPrice(synagogue)"
                                                    icon="fa-solid fa-chair" class="fa text-secondary" />
                                                <i v-on:click="selectSynagogueToEdit(synagogue)"
                                                    class="pi pi-pencil text-primary"></i>
                                                <i v-on:click="selectSynagogueToDelete(synagogue.id)"
                                                    class="pi pi-trash text-danger"></i>
                                            </div>
                                        </div>
                                        <div class="status-container">
                                            <div v-if="synagogue.isActive" class="status-active-bullet"></div>
                                            <div v-else class="status-inactive-bullet"></div>
                                            <span class="status-text">
                                                <h6 class="title">Shul</h6>
                                            </span>
                                        </div>
                                        <h4 class="syngogue-name">{{ synagogue.name }}</h4>
                                        <h5 class="seats">Seats:  {{ synagogue.seatCount }} <i v-on:click="selectSynagogueToView(synagogue)"
                                                class="pi pi-external-link"></i></h5>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ContentLoader } from 'vue-content-loader';

import sidenav from '@/components/Sidenav.vue'
import navbar from '@/components/Navbar.vue'
import viewSeatDesignModal from '@/components/modals/Synagogue/Seats/View_Seat_Design_Modal.vue'
import assignMemberSeatModal from '@/components/modals/Synagogue/Seats/Organization_Assign_Member_Seat_Modal.vue'
import setSynagogueSeatPriceModal from '@/components/modals/Synagogue/Pricing/Set_Seat_Price.vue'
import designSeatModal from '@/components/modals/Synagogue/Register_Synagogue_Modal.vue'
import editSynagogueModal from '@/components/modals/Synagogue/Edit_Synagogue_Modal.vue'
import deleteSynagogueModal from '@/components/modals/Synagogue/Delete_Synagogue_Modal.vue'
import { GET_SYNAGOGUES } from '@/store/action_types'
import { SEATS_MATRIX } from '@/utils/const'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faSynagogue, faChair, faPeopleArrows } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
library.add(faSynagogue, faChair, faPeopleArrows)

export default {
    title: 'Details',
    components: {
        sidenav,
        navbar,
        viewSeatDesignModal,
        assignMemberSeatModal,
        designSeatModal,
        editSynagogueModal,
        deleteSynagogueModal,
        setSynagogueSeatPriceModal,
        ContentLoader
    },
    data() {
        return {
            SEATS_MATRIX,
            selectedSynagogue: null,
            showDesignSeatModal: false,
            showViewSeatDesignModal: false,
            showEditSynagogueModal: false,
            showDeleteSynagogueModal: false,
            showSynagogueSeatPriceModal: false,
            showAssignMemberSeatModal: false,
            selectedOrganization: null,
            selectedSynagogueId: null,
            organizationId: null,
            selected: []
        }
    },
    computed: {
        route() {
            return this.$route.name
        },
        isLoading() {
            return this.$store.getters.synagogues.isLoading
        },
        synagogues() {
            return this.$store.getters.synagogues.data
        },
        organizationIsLoading() {
            return this.$store.getters.organization.isLoading
        },
        organization() {
            return this.$store.getters.organization.data
        },
        account() {
            return this.$store.getters.account.data
        }
    },
    methods: {
        toggleSelection(rowIndex, colIndex) {
            const index = this.selected.findIndex(item => item.row === rowIndex && item.col === colIndex);
            if (index === -1) {
                this.selected.push({ row: rowIndex, col: colIndex });
            } else {
                this.selected.splice(index, 1);
            }
        },
        selectSynagogueToView(synagogue) {
            this.selectedSynagogue = synagogue
            this.showViewSeatDesignModal = true
        },
        isSelected(rowIndex, colIndex) {
            return this.selected.some(item => item.row === rowIndex && item.col === colIndex);
        },
        selectAssignSeatToMember(synagogue) {
            this.selectedSynagogue = synagogue
            this.organizationId = this.account.org
            this.showAssignMemberSeatModal = true
        },
        selectSynagogueToSetSeatPrice(synagogue) {
            this.selectedSynagogue = synagogue
            this.showSynagogueSeatPriceModal = true
        },
        selectSynagogueToEdit(synagogue) {
            this.selectedSynagogue = synagogue
            this.showEditSynagogueModal = true
        },
        selectSynagogueToDelete(synagogueId) {
            this.showDeleteSynagogueModal = true
            this.selectedSynagogueId = synagogueId
        },
        getSynagogues() {
            this.$store.dispatch(GET_SYNAGOGUES, { org: this.account.org })
        }
    },
    mounted() {
        this.getSynagogues()
    }
}
</script>
<style scoped>
.content .card {
    margin-bottom: 20px;
}

.content .card .title {
    color: #5D84C2;
}

.content .card .syngogue-name {
    color: #14243D;
}

.content .card .seats {
    font-size: 18px;
    color: #868D9D;
}

.content .card .seats .pi {
    font-size: 12px;
    color: #434343;
    cursor: pointer;
    margin-left: 4px;
    position: relative;
    bottom: 1px;
}

.content .card .status-container {
    display: flex;
    align-items: center;
    padding: 0px;
}

.content .card .status-active-bullet {
    width: 9px;
    height: 9px;
    background-color: green;
    border-radius: 50%;
    animation: blink 1s infinite;
    margin-right: 10px;
    position: relative;
    bottom: 4px;
}

.content .card .status-inactive-bullet {
    width: 9px;
    height: 9px;
    background-color: red;
    border-radius: 50%;
    margin-right: 10px;
    position: relative;
    bottom: 4px;
}

.content .card .synagogue-actions {
    position: absolute;
    top: 12px;
    right: 15px;
}

.content .card .synagogue-actions .pi {
    margin-left: 13px;
    font-size: 14px;
    cursor: pointer;
}

.content .btn-seat-not-selected {
    background-color: #dddde4;
}

.content .btn-seat-selected {
    background-color: #6489C4;
    color: #FFFFFF;
}

.content .fa-chair {
    cursor: pointer;
}

.content .fa-people-arrows {
    margin-right: 10px;
    font-size: 16px;
    cursor: pointer;
    color: #14243D;
}

.custom-col-width-2 {
    width: 16.66%;
}

.custom-col-width-10 {
    width: 83.3%;
}

@media (max-width: 1000px) {
    .custom-col-width-2 {
        width: 12%;
    }

    .custom-col-width-10 {
        width: 88%;
    }
}
</style>