<template>
    <div class="dashboard-body">
        <div class="row">
            <sidenav class="custom-col-width-2" />
            <div style="padding-left: 0px!important" class="custom-col-width-10 content-section dashboard-content">
                <navbar />
                <addMemberModal v-if="showAddMemberModal" :organizationId="organizationId"
                    @close-add-member-modal="showAddMemberModal = false" @refresh-members="getMembers()" />
                <editMemberModal v-if="showEditMemberModal" :selectedMember="selectedMember"
                    @close-edit-member-modal="showEditMemberModal = false" @refresh-members="getMembers()" />
                <deleteMemberModal v-if="showDeleteMemberModal" :selectedMemberId="selectedMemberId"
                    @close-delete-member-modal="showDeleteMemberModal = false" @refresh-members="getMembers()" />
                <memberPurposeModal v-if="showMemberPurposeModal" :selectedMember="selectedMember"
                    @close-member-purpose-modal="showMemberPurposeModal = false" />
                <registerBulkMembersPledgeItemsModal v-if="showRegisterBulkMembersPledgeItemsModal" :selectedMember="selectedMember"
                    @close-register-bulk-members-pledge-items-modal="showRegisterBulkMembersPledgeItemsModal = false" />
                <div class="content">
                    <br>
                    <div class="row button-grp justify-content-between">
                        <div class="col">
                            <h4>Members</h4>
                        </div>
                        <div class="col">
                            <button v-on:click="showAddMemberModal = true" class="btn btn-add"><i
                                    class="pi pi-plus"></i> Add Member</button>
                            <button v-on:click="showRegisterBulkMembersPledgeItemsModal = true" class="btn btn-add btn-register-bulk-pledges"> Add
                                Bulk Pledges</button>
                        </div>
                    </div>
                    <div class="row justify-content-between">
                        <!-- <organizationNavbar class="" /> -->
                    </div>
                    <div class="row filter-grp">
                        <div class="col-lg-9 col-md-9">
                            <i class="pi pi-search" aria-hidden="true"></i>
                            <input v-model="searchInput" @input="getMembers" class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
                        </div>
                        <div class="col-lg-2 col-md-2">
                            <div class="row">
                                <div class="btn col-lg-7 col-md-10" style="display: block"><img
                                        src="../../assets/icons/filter-icon.svg" alt=""> Filters</div>
                            </div>
                        </div>
                    </div>
                    <div v-if="membersIsLoading && members == null">
                        <div class="row">
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#DFDFFAFF'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                        </div>
                    </div>
                    <div v-else class="row">
                        <div class="col-lg-12">
                            <div class="card">
                                <div class="card-body">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Phone No.</th>
                                                <th scope="col">Created At</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-bind:key="index" v-for="(member, index) in members">
                                                <tr :class="[isOdd(index + 1) ? 'striped' : null]">
                                                    <th scope="row">{{ index + 1 }}</th>
                                                    <td>{{ member.fname }} {{ member.lname }}</td>
                                                    <td>{{ member.memberType != null ? member.memberType.name : '-' }}
                                                    </td>
                                                    <td>{{ member.email }}</td>
                                                    <td>{{ member.phoneNumber }}</td>
                                                    <td>{{ $filters.getDateOnly(member.createdAt) }}</td>
                                                    <td>
                                                        <i v-on:click="selectMemberToView(member)"
                                                            class="pi pi-eye text-secondary"></i>
                                                        <i v-on:click="selectMember(member)"
                                                            class="pi pi-pencil text-primary"></i>
                                                        <i v-on:click="selectMemberToDelete(member.id)"
                                                            class="pi pi-trash text-danger"></i>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ContentLoader } from 'vue-content-loader';

import sidenav from '@/components/Sidenav.vue'
import navbar from '@/components/Navbar.vue'
import organizationNavbar from '@/components/Organization_Navbar.vue'
import addMemberModal from '@/components/modals/Member/Add_Member_Modal.vue'
import editMemberModal from '@/components/modals/Member/Edit_Member_Modal.vue'
import deleteMemberModal from '@/components/modals/Member/Delete_Member_Modal.vue'
import memberPurposeModal from '@/components/modals/Member/Member_Purpose_Modal.vue'
import registerBulkMembersPledgeItemsModal from '@/components/modals/Pledge/Register_Bulk_Members_Pledge_Items_Modal.vue'
import { GET_MEMBERS } from '@/store/action_types'
import { MEMBER_DETAILS_ROUTE } from '@/router/routes'

export default {
    title: 'Members',
    components: {
        sidenav,
        navbar,
        addMemberModal,
        editMemberModal,
        deleteMemberModal,
        memberPurposeModal,
        organizationNavbar,
        registerBulkMembersPledgeItemsModal,
        ContentLoader
    },
    data() {
        return {
            searchInput: null,
            showAddMemberModal: false,
            showEditMemberModal: false,
            showDeleteMemberModal: false,
            showMemberPurposeModal: false,
            showRegisterBulkMembersPledgeItemsModal: false,
            selectedMember: null,
            selectedMemberId: null,
            organizationId: null,
            timeout: null
        }
    },
    computed: {
        route() {
            return this.$route.name
        },
        isLoading() {
            return this.$store.getters.organizations.isLoading
        },
        organizations() {
            return this.$store.getters.organizations.data
        },
        organization() {
            return this.$store.getters.organization.data
        },
        actingMember() {
            return this.$store.getters.member.data
        },
        actingMemberIsLoading() {
            return this.$store.getters.member.isLoading
        },
        members() {
            return this.$store.getters.members.data
        },
        membersIsLoading() {
            return this.$store.getters.members.isLoading
        },
        account() {
            return this.$store.getters.account.data
        }
    },
    methods: {
        isOdd(number) {
            return number % 2 !== 0;
        },
        selectMember(member) {
            this.showEditMemberModal = true
            this.selectedMember = member
        },
        selectMemberToView(member) {
            this.$router.push({ name: MEMBER_DETAILS_ROUTE, params: { membId: member.id, orgId: member.org, name: `${member.fname} ${member.lname}` }})
        },
        selectMemberToDelete(id) {
            this.selectedMemberId = id
            this.showDeleteMemberModal = true
        },
        selectMemberPurpose(member) {
            this.selectedMember = member
            this.showMemberPurposeModal = true
        },
        toggleMemberIsActive(id) {
            this.$store.dispatch(TOGGLE_MEMBER_IS_ACTIVE, { id })
        },
        setOrganization() {
            this.organizationId = this.account.org
        },
        searchMember() {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.getMembers();
            }, 200); 
        },
        getMembers() {
            this.$store.dispatch(GET_MEMBERS, { ...(this.searchInput ? { fname: this.searchInput } : {}), org: this.account.org })
        }
    },
    mounted() {
        this.getMembers()
        this.setOrganization()
    }
}
</script>
<style scoped>
.content .card,
.content .card-body {
    padding: 0px;
}

.btn-register-bulk-pledges {
    float: right;
    margin-right: 15px;
}

.btn-register-bulk-pledges .pi {
    margin-right: 3px;
}

.custom-col-width-2 {
    width: 16.66%;
}

.custom-col-width-10 {
    width: 83.3%;
}

@media (max-width: 1000px) {
    .custom-col-width-2 {
        width: 12%;
    }

    .custom-col-width-10 {
        width: 88%;
    }
}
</style>