<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeEditOrganizationModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Edit Organization</span>
            <hr>
            <div class="modal__content">
                <div class="row">
                    <div class="col-12">
                        <p v-if="errorMessage" class="text-center text-danger auth-status-msg">{{ errorMessage }}</p>
                        <Form @submit="registerOrganization">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="title">General Info</h5>
                                    <div class="row">
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label for="name">Organization Name</label>
                                                <Field v-model="editOrganizationObj.name" rules="required" type="text"
                                                    name="name" class="form-control" id="name" placeholder="Name" />
                                                <ErrorMessage name="name" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label for="Email">Email</label>
                                                <Field v-model="editOrganizationObj.email" rules="" type="email"
                                                    name="Email" class="form-control" id="Email" placeholder="Email" />
                                                <ErrorMessage name="Email" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label for="phoneNumber">Phone Number</label>
                                                <Field v-model="editOrganizationObj.phoneNumber" rules="" type="tel"
                                                    name="phoneNumber" class="form-control" id="phoneNumber"
                                                    placeholder="" />
                                                <ErrorMessage name="phoneNumber" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label for="taxId">Tax Id</label>
                                                <Field v-model="editOrganizationObj.taxId" rules="" type="text"
                                                    name="taxId" class="form-control" id="taxId"
                                                    placeholder="000000000" />
                                                <ErrorMessage name="taxId" class="form-text text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="title">Address Details</h5>
                                    <div class="row">
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label for="address1">Address 1</label>
                                                <Field v-model="editOrganizationObj.address1" rules="" type="text"
                                                    name="address1" class="form-control" id="address1"
                                                    placeholder="Address 1" />
                                                <ErrorMessage name="address1" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label for="address2">Address 2</label>
                                                <Field v-model="editOrganizationObj.address2" rules="" type="text"
                                                    name="address2" class="form-control" id="address2"
                                                    placeholder="Address 2" />
                                                <ErrorMessage name="address2" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label for="city">City</label>
                                                <Field v-model="editOrganizationObj.city" rules="" type="text"
                                                    name="city" class="form-control" id="city" placeholder="City" />
                                                <ErrorMessage name="city" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label for="state">State</label>
                                                <Field v-model="editOrganizationObj.state" rules="" type="text"
                                                    name="state" class="form-control" id="state" placeholder="State" />
                                                <ErrorMessage name="state" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label for="postCode">Post Code</label>
                                                <Field v-model="editOrganizationObj.postCode" rules="" type="text"
                                                    name="postCode" class="form-control" id="postCode"
                                                    placeholder="Post Code" />
                                                <ErrorMessage name="postCode" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label for="country">Country</label>
                                                <Field v-model="editOrganizationObj.country" rules="" type="text"
                                                    name="country" class="form-control" id="country"
                                                    placeholder="Country" />
                                                <ErrorMessage name="country" class="form-text text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <div class="row justify-content-between modal__actions">
                                <div class="col-3">
                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group">
                                                <label v-if="screenImgPreviewUrl == null && this.logoURL == null"
                                                    class="form-label image-label col-12" for="idScan">
                                                    <div class="card image-scan-card">
                                                        <div class="card-body">
                                                            <div class="upload-text">
                                                                <img src="../../../assets/icons/upload-icon.svg" alt="">
                                                                <!-- <h3 class="text-center"><strong><i class="pi pi-plus"></i></strong></h3>
                                                        <h4 class="text-center"><strong>Upload Image</strong></h4> -->
                                                            </div>
                                                            <input v-on:change="handleImageUpload()" class="d-none"
                                                                ref="file" type="file" id="idScan" name="idScan">
                                                        </div>
                                                    </div>
                                                </label>
                                                <div v-else class="">
                                                    <img v-if="screenImgPreviewUrl != null" class="col-12 scanned-image"
                                                        :src="screenImgPreviewUrl" alt="">
                                                    <img v-else class="col-12 scanned-image" :src="logoURL" alt="">
                                                    <br>
                                                    <a v-if="screenImgPreviewUrl != null" v-on:click="removeImage()"
                                                        class="btn text-danger remove-img-btn"><small>Remove</small></a>
                                                    <a v-if="logoURL != null && screenImgPreviewUrl == null"
                                                        v-on:click="removeImage()"
                                                        class="btn text-primary remove-img-btn"><small>Replace</small></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col image-scan-desc">
                                            <h5 class="title">Upload Logo</h5>
                                            <small class="sub-title">File should be 5MB max.</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-5 d-flex align-items-center">
                                    <button v-on:click="closeModal()"
                                        class="col-lg-3 offset-lg-5 col-md-2 btn btn-close">
                                        <img src="../../../assets/icons/close-icon.svg" alt=""> Cancel
                                    </button>
                                    <button type="submit" class="col-lg-3 col-md-4 btn btn-save"
                                        :disabled="organizationIsLoading ? true : false">
                                        <span v-if="organizationIsLoading" class="spinner-border spinner-border-sm"
                                            role="status" aria-hidden="true"></span>
                                        <span v-if="!organizationIsLoading"><img
                                                src="../../../assets/icons/save-icon.svg" alt=""> Save</span>
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import { EDIT_ORGANIZATION } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';
import { STORAGE_URL } from '@/utils/const';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        selectedOrganization: Object
    },
    components: {
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage
    },
    data() {
        return {
            STORAGE_URL,
            showModal: true,
            editOrganizationObj: {
                id: null,
                name: null,
                email: null,
                phoneNumber: null,
                address1: null,
                address2: null,
                city: null,
                state: null,
                postCode: null,
                country: null,
                taxId: null,
                logo: null
            },
            logoURL: null
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        organization() {
            return this.$store.getters.organization.data
        },
        organizationIsLoading() {
            return this.$store.getters.organization.isLoading
        }
    },
    methods: {
        setValues() {
            this.editOrganizationObj.id = this.selectedOrganization.id
            this.editOrganizationObj.name = this.selectedOrganization.name
            this.editOrganizationObj.email = this.selectedOrganization.email
            this.editOrganizationObj.phoneNumber = this.selectedOrganization.phoneNumber
            this.editOrganizationObj.address1 = this.selectedOrganization.address1
            this.editOrganizationObj.address2 = this.selectedOrganization.address2
            this.editOrganizationObj.city = this.selectedOrganization.city
            this.editOrganizationObj.state = this.selectedOrganization.state
            this.editOrganizationObj.postCode = this.selectedOrganization.postCode
            this.editOrganizationObj.country = this.selectedOrganization.country
            this.editOrganizationObj.taxId = this.selectedOrganization.taxId
            this.logoURL = this.selectedOrganization.logo != null ? `${STORAGE_URL}/${this.selectedOrganization.logo}` : null
        },
        handleImageUpload() {
            this.logoURL = this.$refs.file.files[0];
            this.editOrganizationObj.logo = this.$refs.file.files[0];
            this.screenImgPreviewUrl = URL.createObjectURL(this.editOrganizationObj.logo);
        },
        removeImage() {
            this.logoURL = null
            this.editOrganizationObj.logo = null
            this.screenImgPreviewUrl = null
        },
        closeModal() {
            this.$emit('closeEditOrganizationModal')
        },
        registerOrganization() {
            let formData = new FormData();
            formData.append('id', this.editOrganizationObj.id)
            formData.append('name', this.editOrganizationObj.name)
            formData.append('email', this.editOrganizationObj.email)
            formData.append('phoneNumber', this.editOrganizationObj.phoneNumber)
            formData.append('address1', this.editOrganizationObj.address1)
            formData.append('address2', this.editOrganizationObj.address2)
            formData.append('city', this.editOrganizationObj.city)
            formData.append('state', this.editOrganizationObj.state)
            formData.append('postCode', this.editOrganizationObj.postCode)
            formData.append('taxId', this.editOrganizationObj.taxId)
            formData.append('country', this.editOrganizationObj.country)
            if(this.editOrganizationObj.logo != null) {
                formData.append('logo', this.editOrganizationObj.logo)
            }

            this.$store.dispatch(EDIT_ORGANIZATION, formData).then((result) => {
                if (result) {
                    this.$emit('refreshOrganization')
                    this.$emit('closeEditOrganizationModal')
                }
            })
        },
    },
    mounted() {
        this.setValues()
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    position: relative;
    left: 10%;
    margin-top: 50px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
}

.image-label {
    padding: 0px;
}

.image-scan-card {
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 50%;
    height: 103px;
    width: 103px;
    border: 1px solid #A0A0A0;
    cursor: pointer
}

.image-scan-card .upload-text {
    display: flex;
    justify-content: center;
}

.image-scan-card img {
    align-self: center;
}

.scanned-image {
    padding: 0px;
    height: 103px;
    width: 103px;
    border-radius: 50%;
    border: 1px solid #A0A0A0;
}

.remove-img-btn {
    margin-left: 13%;
}

.image-scan-desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px;
    position: relative;
    right: 15px;
    bottom: 10px;
}

.image-scan-desc .title {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 5px;
}

.image-scan-desc .sub-title {
    font-size: 10px;
    line-height: 10px;
    margin-bottom: 0px;
}
</style>