<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeHeaderImageUploadModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Header Image</span>
            <div class="modal__content">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label v-if="headerImagePreviewUrl == null"
                                class="form-label image-label col-12" for="idScan">
                                <div class="card image-scan-card">
                                    <div class="card-body">
                                        <div class="upload-text">
                                            <img v-if="!selectedHeaderImage" src="../../../assets/icons/upload-icon.svg" alt="">
                                            <img v-else :src="`${STORAGE_URL}/${selectedHeaderImage}`" class="scanned-image" alt="">
                                        </div>
                                        <input v-on:change="handleImageUpload()" class="d-none"
                                            ref="file" :accept="`${imageTypes.join(', ')}`" type="file" id="idScan" name="idScan">
                                    </div>
                                </div>
                            </label>
                            <div v-else class="">
                                <img class="col-12 scanned-image" :src="headerImagePreviewUrl" alt="">
                                <br>
                                <br>
                                <div class="row">
                                    <div class="col-1">
                                        <button v-on:click="removeImage()" type="submit"
                                            class="btn text-danger remove-img-btn">Remove</button>
                                    </div>
                                    <div class="col-1">
                                        <button v-on:click="uploadHeaderImage()" class="btn text-primary remove-img-btn" :disabled="organizationIsLoading ? true : false">
                                            <span v-if="organizationIsLoading" class="spinner-border spinner-border-sm"
                                                role="status" aria-hidden="true"></span>
                                            <span v-if="!organizationIsLoading"> Save</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import { UPLOAD_HEADER_IMAGE } from '@/store/action_types';
import { STORAGE_URL, TOAST_GLOBAL_CONFIG } from '@/utils/const';
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'
import { imageTypes } from '@/utils/file_types'
import validateMimeTypes from '@/mixin/fileValidator'

export default {
    props: {
        selectedHeaderImage: String
    },
    mixins: [ validateMimeTypes ],
    components: {
        VueFinalModal,
        ModalsContainer
    },
    data() {
        return {
            STORAGE_URL,
            imageTypes,
            showModal: true,
            headerImagePreviewUrl: null,
            headerImage: null
        }
    },
    computed: {
        organizations() {
            return this.$store.getters.organizations.data
        },
        organizationIsLoading() {
            return this.$store.getters.organization.isLoading
        },
        errorMessage() {
            return this.$store.getters.errorMsg
        }
    },
    methods: {
        closeModal() {
            this.$emit('closeHeaderImageUploadModal')
        },
        handleImageUpload() {
            const files = Array.from(this.$refs.file.files);
            
            const validationResult = this.validateMimeTypes(files, imageTypes);

            if (!validationResult.valid) {
                this.$toast.error('One or more files have unsupported formats. Please check and try again.');
            } else {
                this.headerImage = this.$refs.file.files[0];
                this.headerImagePreviewUrl = URL.createObjectURL(this.headerImage);
            }
        },
        removeImage() {
            this.headerImage = null
            this.headerImagePreviewUrl = null
        },
        uploadHeaderImage() {
            let formData = new FormData();
            formData.append('headerImage', this.headerImage)

            this.$store.dispatch(UPLOAD_HEADER_IMAGE, formData).then((res) => {
                if(res) {
                    this.$toast.success(`Header image has been successfuly uploaded!`, TOAST_GLOBAL_CONFIG);
                    this.closeModal()
                }
            })
        }
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    position: relative;
    left: 10%;
    margin-top: 50px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
}

.modal__title {
    margin-bottom: 10px;
}

.modal__content img {
    border-radius: 10px;
}

.image-label {
    padding: 0px;
}

.image-scan-card {
    padding-top: 0px;
    padding-bottom: 20px;
    height: 20px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #A0A0A0;
    cursor: pointer
}

.image-scan-card .card-body {
    padding: 0px;
}

.image-scan-card .upload-text {
    display: flex;
    justify-content: center;
}

.image-scan-card img {
    align-self: center;
}

.scanned-image {
    padding: 0px;
    height: 250px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #A0A0A0;
}

.remove-img-btn {
    display: flex;
    justify-content: center;
    margin: 0 auto;
}

.image-scan-desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px;
    position: relative;
    right: 15px; 
    bottom: 10px;
}

.image-scan-desc .title {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 5px;
}

.image-scan-desc .sub-title {
    font-size: 10px;
    line-height: 10px;
    margin-bottom: 0px;
}
</style>