<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeMemberPurposeModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Member Purposes</span>
            <br>
            <addPurposeModal v-if="showAddPurposeModal" :selectedMemberId="selectedMember.id" :selectedOrgId="selectedMember.org"
                @close-add-purpose-modal="showAddPurposeModal = false" @refresh-purposes="getMemberPurposes()" />
            <editPurposeModal v-if="showEditPurposeModal" :selectedPurpose="selectedPurpose"
                @close-edit-purpose-modal="showEditPurposeModal = false" @refresh-purposes="getMemberPurposes()" />
            <deletedPurposeModal v-if="showDeletePurposeModal" :selectedPurposeId="selectedPurposeId"
                @close-delete-purpose-modal="showDeletePurposeModal = false" @refresh-purposes="getMemberPurposes()" />
            <div class="modal__content content">
                <div class="row justify-content-end">
                    <div class="col-lg-3 col-md-5 col-sm-7">
                        <button v-on:click="showAddPurposeModal = true" class="btn btn-add"><i class="pi pi-plus"></i>
                            Add Purpose</button>
                    </div>
                </div>
                <p v-if="errorMessage" class="text-center text-danger auth-status-msg">{{ errorMessage }}</p>
                <br>
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Purpose</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Reminder Date</th>
                                            <th scope="col">Recurring Annually</th>
                                            <!-- <th scope="col">Date (Gregorian)</th> -->
                                            <!-- <th scope="col">Created At</th> -->
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-bind:key="index" v-for="(purpose, index) in purposes">
                                            <tr :class="[isOdd(index + 1) ? 'striped' : null]">
                                                <th scope="row">{{ index + 1 }}</th>
                                                <td>{{ purpose.name }}</td>
                                                <td><span class="frankRuhl-font">{{ $filters.convertToHebrewDate(purpose.eventDate) }}</span></td>
                                                <td><span class="frankRuhl-font">{{ $filters.convertToHebrewDateDayAndMonth(purpose.reminderDate) }}</span></td>
                                                <td>
                                                    <span v-if="purpose.isRecurringAnnually" class="badge badge-active text-center">YES</span>
                                                    <span v-else class="badge badge-inactive text-center">NO</span>
                                                </td>
                                                <!-- <td>{{ $filters.getDateOnly(purpose.eventDate) }}</td> -->
                                                <!-- <td>{{ $filters.convertToHebrewDate(purpose.createdAt) }}</td> -->
                                                <td>
                                                    <i v-on:click="selectPurposeToEdit(purpose)"
                                                        class="pi pi-pencil text-primary"></i>
                                                    <i v-on:click="selectPurposeToDelete(purpose.id)"
                                                        class="pi pi-trash text-danger"></i>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import addPurposeModal from '@/components/modals/Purpose/Add_Purpose_Modal.vue'
import editPurposeModal from '@/components/modals/Purpose/Edit_Purpose_Modal.vue'
import deletedPurposeModal from '@/components/modals/Purpose/Delete_Purpose_Modal.vue'

import { DELETE_MEMBER, GET_MEMBERS, GET_PURPOSES } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        selectedMember: String
    },
    components: {
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage,
        addPurposeModal,
        editPurposeModal,
        deletedPurposeModal
    },
    data () {
        return {
            showModal: true,
            showAddPurposeModal: false,
            showEditPurposeModal: false,
            showDeletePurposeModal: false,
            selectedPurpose: null,
            selectedPurposeId: null
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        purposes() {
              return this.$store.getters.purposes.data
        },
        purposesIsLoading() {
            return this.$store.getters.purposes.isLoading
        }
    },
    methods: {
        isOdd(number) {
            return number % 2 !== 0;
        },
        selectPurposeToEdit(purpose) {
            this.selectedPurpose = purpose
            this.showEditPurposeModal = true
        },
        selectPurposeToDelete(purposeId) {
            this.selectedPurposeId = purposeId
            this.showDeletePurposeModal = true
        },
        getMemberPurposes() {
            this.$store.dispatch(GET_PURPOSES, { memb: this.selectedMember.id })
        }
    },
    mounted() {
        this.getMemberPurposes()
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    position: relative;
    left: 20%;
    margin-top: 50px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
}

.content {
    height: auto;
}
</style>