import axios, { AxiosResponse } from 'axios'
import { SET_MEMBERS, SET_MEMBER, SET_ERRORMSG, SET_FILTERED_MEMBERS } from '../mutation_types'
import {
    CLEAR_MEMBERS_ISLOADING,
    DELETE_MEMBER,
    EDIT_MEMBER,
    GET_MEMBERS,
    GET_MEMBER,
    ADD_MEMBER,
    GET_FILTERED_MEMBERS
} from '../action_types'
import { StateObject } from '@/dtos/state.dto'
import Member from '@/services/member'
import { Module } from 'vuex'
import { EditMember, AddMember, GetMember } from '@/dtos/member.dto'

const member: Module<any, {}> = {
    state: {
        member: {
            data: null,
            isLoading: false
        },
        members: {
            data: null,
            isLoading: false
        },
        filteredMembers: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        member: (state: { member: any }) => state.member,
        members: (state: { members: any }) => state.members,
        filteredMembers: (state: { filteredMembers: any }) => state.filteredMembers
    },
    mutations: {
        [SET_MEMBER]: (state: { member: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.member.data = payload.data : null, state.member.isLoading = payload.isLoading },
        [SET_MEMBERS]: (state: { members: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.members.data = payload.data : null, state.members.isLoading = payload.isLoading },
        [SET_FILTERED_MEMBERS]: (state: { filteredMembers: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.filteredMembers.data = payload.data : null, state.filteredMembers.isLoading = payload.isLoading }
    },
    actions: {
        [ADD_MEMBER]: async({ commit }: any, payload: any) => {
            commit(SET_MEMBER, { data: null, isLoading: true })

            let result: AxiosResponse = await Member.registerMember(payload as AddMember)
          
            if(result) {
                if (result.data.status) {
                    commit(SET_MEMBER, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_MEMBER, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_MEMBER, { isLoading: false })
                return false;
            }
        },
        [GET_MEMBERS]: async({ commit }: any, payload: any) => {
            commit(SET_MEMBERS, { data: null, isLoading: true })

            let result: AxiosResponse = await Member.getMembers(payload as GetMember)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_MEMBERS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_MEMBERS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_MEMBERS, { isLoading: false })
                return false;
            }
        },
        [GET_FILTERED_MEMBERS]: async({ commit }: any, payload: any) => {
            commit(SET_FILTERED_MEMBERS, { data: null, isLoading: true })

            let result: AxiosResponse = await Member.getMembers(payload as GetMember)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_FILTERED_MEMBERS, { data: result.data.data, isLoading: false })
                    return result.data.data;
                }
                commit(SET_FILTERED_MEMBERS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_FILTERED_MEMBERS, { isLoading: false })
                return false;
            }
        },
        [GET_MEMBER]: async({ commit }: any, payload: any) => {
            commit(SET_MEMBER, { data: null, isLoading: true })

            let result: AxiosResponse = await Member.getMembers(payload as GetMember)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_MEMBER, { data: result.data.data[0], isLoading: false })
                    return true;
                }
                commit(SET_MEMBER, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_MEMBER, { isLoading: false })
                return false;
            }
        },
        [EDIT_MEMBER]: async({ commit }: any, payload: any) => {
            commit(SET_MEMBER, { data: null, isLoading: true })

            let result: AxiosResponse = await Member.editMember(payload as EditMember)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_MEMBER, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_MEMBER, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_MEMBER, { isLoading: false })
                return false;
            }
        },
        [DELETE_MEMBER]: async({ commit }: any, payload: string) => {
            commit(SET_MEMBER, { data: null, isLoading: true })

            let result: AxiosResponse = await Member.deleteMember(payload)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_MEMBER, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_MEMBER, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_MEMBER, { isLoading: false })
                return false;
            }
        },
        [CLEAR_MEMBERS_ISLOADING]: ({ commit }) => {
            commit(SET_MEMBER, { isLoading: false })
            commit(SET_MEMBERS, { isLoading: false })
        }
    }
}

export default member