import baseAPI from '@/api/base';
import { GetAlertNotification, ViewAlertNotification } from '@/dtos/alert_notification.dto';
import { DELETE_ALERT_URL, ALERT_URL, EDIT_ALERT_URL, ADD_ALERT_URL, VIEW_ALERT_NOTIFICATION_URL, ALERT_NOTIFICATION_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class AlertNotification {
    static getAlertNotification = async(getAlertNotification: GetAlertNotification) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(ALERT_NOTIFICATION_URL, {
                    params: getAlertNotification
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static viewAlertNotification = async(viewAlertNotification: ViewAlertNotification) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(VIEW_ALERT_NOTIFICATION_URL, {
                    ...viewAlertNotification
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default AlertNotification