<template>
    <div class="dashboard-body">
        <div class="row">
            <sidenav class="custom-col-width-2" />
            <div style="padding-left: 0px!important" class="custom-col-width-10 content-section dashboard-content">
                <navbar />
                <registerOrganizationModal v-if="showRegisterOrganizationModal"
                    @close-register-organization-modal="showRegisterOrganizationModal = false"
                    @refresh-organizations="getDetails()" />
                <editOrganizationModal v-if="showEditOrganizationModal" :selectedOrganization="selectedOrganization"
                    @close-edit-organization-modal="showEditOrganizationModal = false"
                    @refresh-organization="getOrganization()" />
                <deleteOrganizationModal v-if="showDeleteOrganizationModal"
                    :selectedOrganizationId="selectedOrganizationId"
                    @close-delete-template-modal="showDeleteOrganizationModal = false"
                    @refresh-organizations="getOrganizations()" />
                <div class="content">
                    <br>
                    <div class="row button-grp justify-content-between">
                        <div class="col">
                            <h4>Member <small v-if="route.params.name">({{ route.params.name }})</small></h4>
                        </div>
                    </div>
                    <div class="row justify-content-between">
                        <memberNavbar class="" />
                    </div>
                    <br>
                    <div v-if="memberReceiptsIsLoading && memberReceipts == null">
                        <div class="row">
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#DFDFFAFF'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                        </div>
                    </div>
                    <div v-else class="row">
                        <div class="col-lg-8">
                            <div class="card">
                                <div class="card-body">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Amount</th>
                                                <th scope="col">Payment Info</th>
                                                <th scope="col">Payment Date</th>
                                                <th scope="col">Created By</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-bind:key="index" v-for="(receipt, index) in memberReceipts">
                                                <tr :class="[isOdd(index + 1) ? 'striped' : null]">
                                                    <th scope="row">{{ index + 1 }}</th>
                                                    <td>{{ $filters.formatPrice(receipt.paidAmount, 'USD') }}</td>
                                                    <td>{{ receipt.paymentMethod }} <span v-if="receipt.checkNumber">{{ ` - ${receipt.checkNumber}` }}</span> <span v-if="receipt.charityCardOption">{{ ` - ${receipt.charityCardOption}` }}</span></td>
                                                    <td>{{ $filters.getDateOnly(receipt.paymentDate) }}</td>
                                                    <td>{{ receipt.user.fname }}</td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ContentLoader } from 'vue-content-loader';

import sidenav from '@/components/Sidenav.vue'
import navbar from '@/components/Navbar.vue'
import memberNavbar from '@/components/Member_Navbar.vue'
import registerOrganizationModal from '@/components/modals/Organization/Register_Organization_Modal.vue'
import editOrganizationModal from '@/components/modals/Organization/Edit_Organization_Modal.vue'
// import deleteOrganizationModal from '@/components/modals/Delete_Organization_Modal.vue'
import { GET_ORGANIZATION, GET_MEMBER_RECEIPTS } from '@/store/action_types'
import { ORGANIZATION_DETAILS_ROUTE, ORGANIZATION_ACCOUNTS_ROUTE, ORGANIZATION_SCREENS_ROUTE } from '@/router/routes'

export default {
    title: 'Details',
    components: {
        sidenav,
        navbar,
        memberNavbar,
        registerOrganizationModal,
        editOrganizationModal,
        // deleteOrganizationModal,
        // addOrganizationModal,
        ContentLoader
    },
    data() {
        return {
            ORGANIZATION_DETAILS_ROUTE,
            ORGANIZATION_ACCOUNTS_ROUTE,
            ORGANIZATION_SCREENS_ROUTE,
            showRegisterOrganizationModal: false,
            showEditOrganizationModal: false,
            showDeleteOrganizationModal: false,
            selectedOrganization: null,
            selectedOrganizationId: null
        }
    },
    computed: {
        route() {
            return this.$route
        },
        memberReceiptsIsLoading() {
            return this.$store.getters.memberReceipts.isLoading
        },
        memberReceipts() {
            return this.$store.getters.memberReceipts.data
        }
    },
    methods: {
        isOdd(number) {
            return number % 2 !== 0;
        },
        selectOrganizationToEdit(organization) {
            this.selectedOrganization = this.organization
            this.showEditOrganizationModal = true
        },
        selectOrganizationToDelete(templateId) {
            this.selectedOrganizationId = templateId
            this.showDeleteOrganizationModal = true
        },
        getReceipts() {
            this.$store.dispatch(GET_MEMBER_RECEIPTS, { memb: this.$route.params.membId })
        }
    },
    mounted() {
        this.getReceipts()
    }
}
</script>
<style scoped>
.content .card,
.content .card-body {
    padding: 0px;
}

.content .info-card {
    margin-bottom: 20px;
}

.content .card .card-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #F4ECE2;
}

.content .card .header {
    font-weight: 700;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #1D1F2C;
}

.content .card .card-content {
    padding: 15px 35px 15px 35px;
}

.content .card .card-content>.row {
    margin-bottom: 7px
}

.content .card .card-content .title {
    font-weight: 600;
    color: #1D1F2C;
}

.content .card .card-content .title img {
    height: 40px !important;
    margin-right: 6px;
}

.content .card .card-content .value {
    font-weight: 600;
    color: #1D1F2C;
    margin-top: 6px
}

.content .btn-add {
    height: 40px;
    float: right;
    font-size: 14px;
    border-radius: 8px;
    color: #FFFFFF !important;
    padding: 10px, 14px, 10px, 14px;
    background-color: #646F80;
    border-color: #646F80 !important;
}

.content .card table small {
    margin-left: 10px;
    margin-right: 10px;
}

.content .card table .pi-trash,
.content .card table .pi-pencil {
    cursor: pointer;
}

/*.content .organization-nav .btn {
    color: #5A5A57;
    padding: 0px;
    border-radius: 0px;
    text-decoration: unset;
    margin-left: 10px;
    font-size: 18px;
}

.content .organization-nav .btn:hover,
.content .organization-nav .btn-selected {
    padding-bottom: 5px;
    color: #cf9904;
    border-bottom: 2px solid #cf9904;
}
*/

.content .organization-nav .card-body {
    padding: 15px !important;
}

.custom-col-width-2 {
    width: 16.66%;
}

.custom-col-width-10 {
    width: 83.3%;
}

@media (max-width: 1000px) {
    .custom-col-width-2 {
        width: 12%;
    }

    .custom-col-width-10 {
        width: 88%;
    }
}
</style>