import axios, { AxiosResponse } from 'axios'
import { SET_SYNAGOGUE_MEMBER_SEATS, SET_SYNAGOGUE_MEMBER_SEAT, SET_ERRORMSG } from '../mutation_types'
import {
    CLEAR_SYNAGOGUE_MEMBER_SEATS_ISLOADING,
    DELETE_SYNAGOGUE_MEMBER_SEAT,
    EDIT_SYNAGOGUE_MEMBER_SEAT,
    GET_SYNAGOGUE_MEMBER_SEATS,
    ADD_SYNAGOGUE_MEMBER_SEAT,
} from '../action_types'
import { StateObject } from '@/dtos/state.dto'
import SynagogueMemberSeat from '@/services/synagogue_member_seat'
import { Module } from 'vuex'
import { EditSynagogueMemberSeat, AddSynagogueMemberSeat, GetSynagogueMemberSeat } from '@/dtos/synagogue_member_seat.dto'

const synagogueMemberSeat: Module<any, {}> = {
    state: {
        synagogueMemberSeat: {
            data: null,
            isLoading: false
        },
        synagogueMemberSeats: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        synagogueMemberSeat: (state: { synagogueMemberSeat: any }) => state.synagogueMemberSeat,
        synagogueMemberSeats: (state: { synagogueMemberSeats: any }) => state.synagogueMemberSeats
    },
    mutations: {
        [SET_SYNAGOGUE_MEMBER_SEAT]: (state: { synagogueMemberSeat: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.synagogueMemberSeat.data = payload.data : null, state.synagogueMemberSeat.isLoading = payload.isLoading },
        [SET_SYNAGOGUE_MEMBER_SEATS]: (state: { synagogueMemberSeats: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.synagogueMemberSeats.data = payload.data : null, state.synagogueMemberSeats.isLoading = payload.isLoading }
    },
    actions: {
        [ADD_SYNAGOGUE_MEMBER_SEAT]: async({ commit }: any, payload: any) => {
            commit(SET_SYNAGOGUE_MEMBER_SEAT, { data: null, isLoading: true })

            let result: AxiosResponse = await SynagogueMemberSeat.addSynagogueMemberSeat(payload as AddSynagogueMemberSeat)
          
            if(result) {
                if (result.data.status) {
                    commit(SET_SYNAGOGUE_MEMBER_SEAT, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_SYNAGOGUE_MEMBER_SEAT, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_SYNAGOGUE_MEMBER_SEAT, { isLoading: false })
                return false;
            }
        },
        [GET_SYNAGOGUE_MEMBER_SEATS]: async({ commit }: any, payload: any) => {
            commit(SET_SYNAGOGUE_MEMBER_SEATS, { data: null, isLoading: true })

            let result: AxiosResponse = await SynagogueMemberSeat.getSynagogueMemberSeats(payload as GetSynagogueMemberSeat)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_SYNAGOGUE_MEMBER_SEATS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_SYNAGOGUE_MEMBER_SEATS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_SYNAGOGUE_MEMBER_SEATS, { isLoading: false })
                return false;
            }
        },
        [EDIT_SYNAGOGUE_MEMBER_SEAT]: async({ commit }: any, payload: any) => {
            commit(SET_SYNAGOGUE_MEMBER_SEAT, { data: null, isLoading: true })

            let result: AxiosResponse = await SynagogueMemberSeat.editSynagogueMemberSeat(payload as EditSynagogueMemberSeat)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_SYNAGOGUE_MEMBER_SEAT, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_SYNAGOGUE_MEMBER_SEAT, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_SYNAGOGUE_MEMBER_SEAT, { isLoading: false })
                return false;
            }
        },
        [DELETE_SYNAGOGUE_MEMBER_SEAT]: async({ commit }: any, payload: string) => {
            commit(SET_SYNAGOGUE_MEMBER_SEAT, { data: null, isLoading: true })

            let result: AxiosResponse = await SynagogueMemberSeat.deleteSynagogueMemberSeat(payload)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_SYNAGOGUE_MEMBER_SEAT, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_SYNAGOGUE_MEMBER_SEAT, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_SYNAGOGUE_MEMBER_SEAT, { isLoading: false })
                return false;
            }
        },
        [CLEAR_SYNAGOGUE_MEMBER_SEATS_ISLOADING]: ({ commit }) => {
            commit(SET_SYNAGOGUE_MEMBER_SEAT, { isLoading: false })
            commit(SET_SYNAGOGUE_MEMBER_SEATS, { isLoading: false })
        }
    }
}

export default synagogueMemberSeat