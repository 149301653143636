import axios, { AxiosResponse } from 'axios'
import { SET_RECEIPTS, SET_RECEIPT, SET_ERRORMSG, SET_MEMBER_RECEIPTS } from '../mutation_types'
import {
    CLEAR_RECEIPTS_ISLOADING,
    GET_MEMBER_RECEIPTS,
    GET_RECEIPTS
} from '../action_types'
import { StateObject } from '@/dtos/state.dto'
import Receipt from '@/services/receipt'
import { Module } from 'vuex'
import { GetReceipt } from '@/dtos/receipt.dto'

const receipt: Module<any, {}> = {
    state: {
        receipt: {
            data: null,
            isLoading: false
        },
        receipts: {
            data: null,
            isLoading: false
        },
        memberReceipts: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        receipt: (state: { receipt: any }) => state.receipt,
        receipts: (state: { receipts: any }) => state.receipts,
        memberReceipts: (state: { memberReceipts: any }) => state.memberReceipts
    },
    mutations: {
        [SET_RECEIPT]: (state: { receipt: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.receipt.data = payload.data : null, state.receipt.isLoading = payload.isLoading },
        [SET_RECEIPTS]: (state: { receipts: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.receipts.data = payload.data : null, state.receipts.isLoading = payload.isLoading },
        [SET_MEMBER_RECEIPTS]: (state: { memberReceipts: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.memberReceipts.data = payload.data : null, state.memberReceipts.isLoading = payload.isLoading }
    },
    actions: {
        [GET_RECEIPTS]: async({ commit }: any, payload: any) => {
            commit(SET_RECEIPTS, { data: null, isLoading: true })

            let result: AxiosResponse = await Receipt.getReceipts(payload as GetReceipt)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_RECEIPTS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_RECEIPTS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_RECEIPTS, { isLoading: false })
                return false;
            }
        },
        [GET_MEMBER_RECEIPTS]: async({ commit }: any, payload: any) => {
            commit(SET_MEMBER_RECEIPTS, { data: null, isLoading: true })

            let result: AxiosResponse = await Receipt.getReceipts(payload as GetReceipt)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_MEMBER_RECEIPTS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_MEMBER_RECEIPTS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_MEMBER_RECEIPTS, { isLoading: false })
                return false;
            }
        },
        [CLEAR_RECEIPTS_ISLOADING]: ({ commit }) => {
            commit(SET_RECEIPT, { isLoading: false })
            commit(SET_RECEIPTS, { isLoading: false })
        }
    }
}

export default receipt