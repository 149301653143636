import { App } from 'vue';
import NProgress from 'nprogress';

const nprogressPlugin = {
  install(app: App): void {
    app.config.globalProperties.$nprogress = NProgress;
  }
};

NProgress.configure({ color: '#14243D' });

export default nprogressPlugin;
