<template>
    <div class="dashboard-body">
        <div class="row">
            <sidenav class="custom-col-width-2" />
            <div style="padding-left: 0px!important" class="custom-col-width-10 content-section dashboard-content">
                <navbar />
                <addAlertModal v-if="showAddAlertModal" :organizationId="organizationId"
                    @close-add-alert-modal="showAddAlertModal = false" @refresh-alerts="getAlerts()" />
                <div class="content">
                    <br>
                    <div class="row button-grp justify-content-between">
                        <div class="col">
                            <h4>Alerts</h4>
                        </div>
                        <div v-if="account.role == USER_ROLE_ADMIN" class="col">
                            <button v-on:click="showAddAlertModal = true" class="btn btn-add"><i class="pi pi-plus"></i>
                                Add Alert</button>
                        </div>
                    </div>
                    <div class="row filter-grp">
                        <div class="col-9">
                            <i class="pi pi-search" aria-hidden="true"></i>
                            <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
                        </div>
                        <div class="col-2">
                            <div class="row">
                                <div class="btn col-lg-7 col-mg-9 col-sm-12" style="display: block"><img
                                        src="../assets/icons/filter-icon.svg" alt=""> Filters</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-10 offset-lg-0">
                            <div class="card">
                                <div class="card-body">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Message</th>
                                                <th scope="col">Created At</th>
                                                <th scope="col">Created By</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-bind:key="index" v-for="(alert, index) in alerts">
                                                <tr :class="[isOdd(index + 1) ? 'striped' : null]">
                                                    <th scope="row">{{ index + 1 }}</th>
                                                    <td>{{ alert.message }}</td>
                                                    <td><span class="frankRuhl-font">{{ $filters.convertToHebrewDate(alert.createdAt) }}</span></td>
                                                    <td>{{ alert.user.fname }}</td>
                                                    <td>
                                                        <i v-on:click="selectAlertToEdit(alert)"
                                                            class="pi pi-pencil text-primary"></i>
                                                        <i v-on:click="selectAlertToDelete(alert.id)"
                                                            class="pi pi-trash text-danger"></i>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import sidenav from '@/components/Sidenav.vue'
import navbar from '@/components/Navbar.vue'
import organizationNavbar from '@/components/Organization_Navbar.vue'
import addAlertModal from '@/components/modals/Alert/Add_Alert_Modal.vue'
import { GET_ALERTS, GET_ALL_NOTIFICATIONS } from '@/store/action_types'
import { USER_ROLE_ADMIN } from '@/utils/const';

export default {
    title: 'Templates',
    components: {
        sidenav,
        navbar,
        organizationNavbar,
        addAlertModal
    },
    data() {
        return {
            USER_ROLE_ADMIN,
            showAddAlertModal: false,
            selectedAlert: null,
            showEditAlertModal: null,
            selectedAlertId: null,
            showDeleteAlertModal: null,
            organizationId: null
        }
    },
    computed: {
        alertsIsLoading() {
            return this.$store.getters.alerts.isLoading
        },
        alerts() {
            return this.$store.getters.alerts.data
        },
        account() {
            return this.$store.getters.account.data
        }
    },
    methods: {
        isOdd(number) {
            return number % 2 !== 0;
        },
        setValues() {
            this.organizationId = this.$route.params.id
        },
        selectMember(member) {
            this.selectedMemberToView = member
            this.showViewNotificationMemberModal = true
        },
        selectAlertToEdit(alery) {
            this.selectedAlert = alery
            this.showEditAlertModal = true
        },
        selectAlertToDelete(aleryId) {
            this.selectedAlertId = aleryId
            this.showDeleteAlertModal = true
        },
        getAlerts() {
            this.$store.dispatch(GET_ALERTS)
        }
    },
    mounted() {
        this.setValues()
        this.getAlerts()
    }
}
</script>
<style scoped>
.content .card,
.content .card-body {
    padding: 0px;
}

.content .card table small {
    margin-left: 10px;
    margin-right: 10px;
}

.content .card table .pi-eye,
.content .card table .pi-trash,
.content .card table .pi-pencil {
    cursor: pointer;
}

.custom-col-width-2 {
    width: 16.66%;
}

.custom-col-width-10 {
    width: 83.3%;
}

.pi-info-circle {
    margin-left: 10px;
    cursor: pointer;
}

@media (max-width: 1000px) {
    .custom-col-width-2 {
        width: 12%;
    }

    .custom-col-width-10 {
        width: 88%;
    }
}
</style>