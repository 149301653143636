import baseAPI from '@/api/base';
import { EditAliasOfferingType, AddAliasOfferingType, GetAliasOfferingType } from '../dtos/alias_offering_type.dto';
import { DELETE_ALIAS_OFFERING_TYPE_URL, ALIAS_OFFERING_TYPE_URL, EDIT_ALIAS_OFFERING_TYPE_URL, ADD_ALIAS_OFFERING_TYPE_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class AliasOfferingType {
    static getAliasOfferingTypes = async(getAliasOfferingType: GetAliasOfferingType) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(ALIAS_OFFERING_TYPE_URL, {
                    params: getAliasOfferingType
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static registerAliasOfferingType = async(addAliasOfferingType: AddAliasOfferingType) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(ADD_ALIAS_OFFERING_TYPE_URL, {
                    ...addAliasOfferingType
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static editAliasOfferingType = async(editAliasOfferingType: EditAliasOfferingType) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(EDIT_ALIAS_OFFERING_TYPE_URL, {
                    ...editAliasOfferingType
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static deleteAliasOfferingType = async(minyanimTypeId: string) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.delete(`${DELETE_ALIAS_OFFERING_TYPE_URL}/${minyanimTypeId}`)

                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default AliasOfferingType