import { SET_ROUTER_LOADER } from '../mutation_types'
import { UPDATE_ROUTER_LOADER } from '../action_types'

const routerLoader = {
    state: {
        routerIsLoading: false
    },
    getters: {
        routerIsLoading: (state: { routerIsLoading: String }) => state.routerIsLoading
    },
    mutations: {
        [SET_ROUTER_LOADER]: (state: { routerIsLoading: String }, payload: any) => { state.routerIsLoading = payload }
    },
    actions: {
        [UPDATE_ROUTER_LOADER]: ({ commit } : any, payload: boolean) => {
            commit(SET_ROUTER_LOADER, payload)
        }
    }
}

export default routerLoader