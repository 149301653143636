import baseAPI from '@/api/base';
import { GoogleSigninCredentals, LoginCredentals, RegistrationCredentals } from '../dtos/auth.dto';
import { EDIT_USER_URL, LOGIN_URL, SIGNUP_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';
import { EditUser } from '@/dtos/users.dto';

class Auth {
    static login = async(loginCredentals: LoginCredentals) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(LOGIN_URL, {
                    ...loginCredentals,

                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

   
    static signup = async(registrationCredentals: RegistrationCredentals) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(SIGNUP_URL, {
                    ...registrationCredentals
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static updateAccount = async(editUser: EditUser) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(EDIT_USER_URL, {
                    ...editUser
                })
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default Auth