<template>
    <div class="dashboard-body">
        <div class="row">
            <sidenav class="custom-col-width-2" />
            <div style="padding-left: 0px!important" class="custom-col-width-10 content-section dashboard-content">
                <navbar />
                <addMinyanimModal v-if="showAddMinyanimModal" :organizationId="organizationId"
                    @close-add-minyanim-modal="showAddMinyanimModal = false" @refresh-minyanim="refreshMinyanims()" />
                <editMinyanimModal v-if="showEditMinyanimModal" :selectedMinyanim="selectedMinyanim"
                    @close-edit-minyanim-modal="showEditMinyanimModal = false" @refresh-minyanim="refreshMinyanims()" />
                <deleteMinyanimModal v-if="showDeleteMinyanimModal" :selectedMinyanimId="selectedMinyanimId"
                    @close-delete-minyanim-modal="showDeleteMinyanimModal = false" @refresh-minyanim="getMinyanim()" />

                <minyanimTimesModal v-if="showMinyanimTimesModel" :selectedMinyanimTimes="selectedMinyanimTimes"
                    @close-minyanim-times-modal="showMinyanimTimesModel = false" />

                <registerRoomModal v-if="showRegisterRoomModal" :organizationId="organizationId"
                    @close-register-room-modal="showRegisterRoomModal = false" @refresh-rooms="getRooms()" />
                <editRoomModal v-if="showEditRoomModal" :selectedRoom="selectedRoom"
                    @close-edit-room-modal="showEditRoomModal = false" @refresh-rooms="getRooms()" />
                <deleteRoomModal v-if="showDeleteRoomModal" :selectedRoomId="selectedRoomId"
                    @close-delete-room-modal="showDeleteRoomModal = false" @refresh-rooms="getRooms()" />

                <div class="content">
                    <br>
                    <div class="row button-grp justify-content-between">
                        <div class="col">
                            <h4>Organization</h4>
                        </div>
                        <div class="col">
                            <button v-on:click="showAddMinyanimModal = true" class="btn btn-add"> <i
                                    class="pi pi-plus"></i> Add Minyanim</button>
                        </div>
                    </div>
                    <div class="row justify-content-between">
                        <organizationNavbar class="" />
                    </div>
                    <div class="row filter-grp">
                        <div class="col-lg-9 col-md-7">
                            <i class="pi pi-search" aria-hidden="true"></i>
                            <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
                        </div>
                        <div class="col-lg-3 col-md-5">
                            <div class="row">
                                <div class="btn col"><img src="../../assets/icons/calendar-icon.svg" alt=""> Select Date
                                </div>
                                <div class="btn col"><img src="../../assets/icons/filter-icon.svg" alt="">
                                    Filters</div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-between view-controls">
                        <div class="col-lg-4 col-md-6">
                            <div class="btn-group" role="group" aria-label="Basic example">
                                <button v-on:click="switchMinyanimView(MINYANIM_VIEW_ROOMS)" type="button"
                                    :class="[selectedView == MINYANIM_VIEW_ROOMS ? 'btn-selected' : null, 'btn btn-list-view']">Rooms</button>
                                <button v-on:click="switchMinyanimView(MINYANIM_VIEW_SCHEDULES)" type="button"
                                    :class="[selectedView == MINYANIM_VIEW_SCHEDULES ? 'btn-selected' : null, 'btn btn-calendar-view']">Schedules</button>
                            </div>
                        </div>
                        <div class="col">
                            <button v-on:click="showRegisterRoomModal = true" class="btn btn-add btn-register-room">
                                <i class="pi pi-plus"></i> Register Room</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12" v-if="selectedView == MINYANIM_VIEW_ROOMS">
                            <div class="row">
                                <template v-bind:key="index" v-for="(room, index) in rooms">
                                    <div class="col-4">
                                        <div class="card rooms-card">
                                            <div class="card-img-overlay">
                                                <br>
                                                <br>
                                                <br>
                                                <br>
                                                <div class="row room-actions">
                                                    <div class="col">
                                                        <i v-on:click="selectRoomToEdit(room)"
                                                            class="pi pi-pencil text-primary"></i>
                                                        <i v-on:click="selectRoomToDelete(room.id)"
                                                            class="pi pi-trash text-danger"></i>
                                                    </div>
                                                </div>
                                                <div class="overlay-text">{{ room.name }}</div>
                                                <div class="card-content" style="color: #000000!important">
                                                    <div v-if="room.minyanims.length > 0">
                                                        <div v-if="(mapMinyanimTimes(room.minyanims)[0]).length > 0">
                                                            <template v-bind:key="index"
                                                                v-for="(minyanimTime, index) in mapMinyanimTimes(room.minyanims)[0]">
                                                                <div class="status-container">
                                                                    <div v-if="$filters.checkDateRange(minyanimTime.startTime, minyanimTime.endTime) == 0"
                                                                        class="status-inactive-bullet"></div>
                                                                    <div v-if="$filters.checkDateRange(minyanimTime.startTime, minyanimTime.endTime) == 1"
                                                                        class="status-active-bullet"></div>
                                                                    <div v-if="$filters.checkDateRange(minyanimTime.startTime, minyanimTime.endTime) == 2"
                                                                        class="status-pending-bullet"></div>
                                                                    <span class="status-text">
                                                                        <span v-if="minyanimTime.minyanimType"> {{
                                                                            minyanimTime.minyanimType.name }}</span> |
                                                                        {{ $filters.getTimeOnly(minyanimTime.startTime)
                                                                        }}
                                                                        - {{ $filters.getTimeOnly(minyanimTime.endTime)
                                                                        }}</span>
                                                                </div>
                                                            </template>
                                                        </div>
                                                        <span v-else class="status-text text-secondary">No Minyanim
                                                            Times Available.</span>
                                                    </div>
                                                    <span v-else class="status-text text-secondary">No Minyanim
                                                        Today.</span>
                                                </div>
                                            </div>
                                            <img class="card-img-top" src="../../assets/images/minynim-room.png" alt="">
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="col-lg-12 offset-lg-0" v-if="selectedView == MINYANIM_VIEW_SCHEDULES">
                            <div class="card">
                                <div class="card-body">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <!-- <th scope="col">Title</th> -->
                                                <th scope="col">Type</th>
                                                <th scope="col">Screen</th>
                                                <th scope="col">Room</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Times</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Registered By</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-bind:key="index" v-for="(minyanim, index) in minyanims">
                                                <tr :class="[isOdd(index + 1) ? 'striped' : null]">
                                                    <th scope="row">{{ index + 1 }}</th>
                                                    <!-- <td><span v-html="renderContent(donation.template.content, donation)"></span></td> -->
                                                    <td>{{ minyanim.dateType }}</td>
                                                    <td>{{ minyanim.screen.name }}</td>
                                                    <td>{{ minyanim.room.name }}</td>
                                                    <td><span class="frankRuhl-font">{{
                                                            $filters.convertToHebrewDate(minyanim.minyanimStartDate) }}
                                                        </span>
                                                        <!-- - {{ $filters.convertToHebrewDate(minyanim.minyanimEndDate) }} -->
                                                    </td>
                                                    <td><i v-on:click="selectMinyanimTime(minyanim.minyanimTimes)"
                                                            class="pi pi-clock text-primary"></i></td>
                                                    <td>
                                                        <label v-on:click="toggleMinyanimIsActive(minyanim.id)"
                                                            class="form-switch">
                                                            <input
                                                                v-if="!(actingMinyanim != null && actingMinyanimIsLoading && actingMinyanim.id == minyanim.id)"
                                                                type="checkbox" :checked="minyanim.isActive">
                                                            <span v-else class="spinner-border spinner-border-sm"
                                                                role="status" aria-hidden="true"></span>
                                                            <i></i>
                                                        </label>
                                                    </td>
                                                    <td>{{ minyanim.user.fname }}</td>
                                                    <td>
                                                        <i v-on:click="selectMinyanimToEdit(minyanim)"
                                                            class="pi pi-pencil text-primary"></i>
                                                        <i v-on:click="selectMinyanimToDelete(minyanim.id)"
                                                            class="pi pi-trash text-danger"></i>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import sidenav from '@/components/Sidenav.vue'
import navbar from '@/components/Navbar.vue'
import organizationNavbar from '@/components/Organization_Navbar.vue'
import minyanimTimesModal from '@/components/modals/Minyanim/Minyanim_Times_Modal.vue'
import addMinyanimModal from '@/components/modals/Minyanim/Add_Minyanim_Modal.vue'
import editMinyanimModal from '@/components/modals/Minyanim/Edit_Minyanim_Modal.vue'
import deleteMinyanimModal from '@/components/modals/Minyanim/Delete_Minyanim_Modal.vue'
import editRoomModal from '@/components/modals/Room/Edit_Room_Modal.vue'
import deleteRoomModal from '@/components/modals/Room/Delete_Room_Modal.vue'
import calendarDonationsView from '@/components/Calendar_View.vue'
import registerRoomModal from '@/components/modals/Room/Register_Room_Modal.vue'
import { GET_MINYANIMS, GET_ROOMS, TOGGLE_DONATION_IS_ACTIVE, TOGGLE_MINYANIM_IS_ACTIVE } from '@/store/action_types'
import { MINYANIM_VIEW_SCHEDULES, MINYANIM_VIEW_ROOMS } from '@/utils/const'
import { USER_ROLE_ADMIN } from '../../utils/const';

export default {
    title: 'Members',
    components: {
        sidenav,
        navbar,
        organizationNavbar,
        addMinyanimModal,
        editMinyanimModal,
        deleteMinyanimModal,
        minyanimTimesModal,
        registerRoomModal,
        editRoomModal,
        deleteRoomModal
    },
    data() {
        return {
            USER_ROLE_ADMIN,
            MINYANIM_VIEW_ROOMS,
            MINYANIM_VIEW_SCHEDULES,
            showAddMinyanimModal: false,
            showEditMinyanimModal: false,
            showRegisterRoomModal: false,
            showMinyanimTimesModel: false,
            selectedView: MINYANIM_VIEW_ROOMS,
            selectedRoomId: null,
            selectedRoom: null,
            showEditRoomModal: false,
            showDeleteRoomModal: false,
            organizationId: null,
            showDeleteMinyanimModal: false,
            selectedMinyanim: null,
            selectedMinyanimTimes: null
        }
    },
    computed: {
        minyanimIsLoading() {
            return this.$store.getters.minyanims.isLoading
        },
        minyanims() {
            return this.$store.getters.minyanims.data
        },
        roomsIsLoading() {
            return this.$store.getters.rooms.isLoading
        },
        rooms() {
            return this.$store.getters.rooms.data
        },
        actingMinyanim() {
            return this.$store.getters.minyanim.data
        },
        actingMinyanimIsLoading() {
            return this.$store.getters.minyanim.isLoading
        },
        account() {
            return this.$store.getters.account.data
        }
    },
    methods: {
        isOdd(number) {
            return number % 2 !== 0;
        },
        mapMinyanimTimes(minyanim) {
            return minyanim.map((e) => e.minyanimTimes)
        },
        selectMinyanimToEdit(minyanim) {
            this.selectedMinyanim = minyanim
            this.showEditMinyanimModal = true
        },
        selectMinyanimToDelete(minyanimId) {
            this.selectedMinyanimId = minyanimId
            this.showDeleteMinyanimModal = true
        },
        selectRoomToEdit(room) {
            this.selectedRoom = room
            this.showEditRoomModal = room
        },
        selectRoomToDelete(roomId) {
            this.selectedRoomId = roomId
            this.showDeleteRoomModal = true
        },
        selectDontationToView(donation) {
            this.selectedMinyanimToView = donation
            this.showViewDonationModal = true
        },
        renderContent(template, donation) {
            let replacements = []

            Object.keys(donation).forEach(key => {
                const regex = new RegExp(`{${key}}`, 'g');
                if (template.includes(`{${key}}`)) {
                    replacements.push({
                        replace: regex,
                        replaceBy: donation[key]
                    })
                }

            });

            replacements.forEach((e) => {
                template = template.replace(e.replace, e.replaceBy)
            })
            return template;
        },
        setOrganization() {
            this.organizationId = this.$route.params.id
        },
        toggleDonationIsActive(id) {
            this.$store.dispatch(TOGGLE_DONATION_IS_ACTIVE, { id })
        },
        selectMinyanimTime(minyanimTime) {
            this.selectedMinyanimTimes = minyanimTime
            this.showMinyanimTimesModel = true
        },
        toggleMinyanimIsActive(id) {
            this.$store.dispatch(TOGGLE_MINYANIM_IS_ACTIVE, { id })
        },
        getRooms() {
            this.$store.dispatch(GET_ROOMS, { org: this.$route.params.id })
        },
        getMinyanim() {
            this.$store.dispatch(GET_MINYANIMS, { org: this.$route.params.id })
        },
        refreshMinyanims() {
            this.getRooms()
            this.getMinyanim()
        },
        switchMinyanimView(type) {
            this.selectedView = type
        }
    },
    mounted() {
        this.getMinyanim()
        this.getRooms()
        this.setOrganization()
    }
}
</script>
<style scoped>
.content .card,
.content .card-body {
    padding: 0px;
}

.content .card .card-content {
    padding: 15px;
}

.content .card .card-content .value {
    font-weight: 300 !important;
}

.content .card,
.content .card-body {
    padding: 0px;
}

.custom-col-width-2 {
    width: 16.66%;
}

.custom-col-width-10 {
    width: 83.3%;
}

.rooms-card {
    position: relative;
    border: 2px solid #E0E2E7;
    margin-bottom: 20px;
}

.status-container {
    display: flex;
    align-items: center;
    padding: 0px;
    color: #000000;
    font-size: 13px;
}

.status-text {
    font-size: 13px;
}

.card-img-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    opacity: 1.0;    
    align-items: center;
    justify-content: center;
    color: white;
    transition: opacity 0.3s ease;
    border-radius: 10px;
    padding: 0px;
}

.card-img-overlay:hover {
    opacity: 1.0;
}

.card .card-img-top {
    border-radius: 10px;
}

.card-img-overlay .card-content {
    width: 100%;
    background-color: #FFFFFF;
    position: absolute;
    bottom: 0px;
    opacity: 1.0;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
}

.card-content {
    padding-top: 7px!important;
    padding-bottom: 7px!important;
}

.overlay-text {
    font-size: 1.5rem;
    text-align: center;
    color: #FFFFFF;
}

.status-active-bullet {
    width: 9px;
    height: 9px;
    background-color: green;
    border-radius: 50%;
    animation: blink 1s infinite;
    margin-right: 10px;
}

.status-inactive-bullet {
    width: 9px;
    height: 9px;
    background-color: red;
    border-radius: 50%;
    /*animation: blink 1s infinite;*/
    margin-right: 10px;
}

.status-pending-bullet {
    width: 9px;
    height: 9px;
    background-color: #6c757d;
    border-radius: 50%;
    /*animation: blink 1s infinite;*/
    margin-right: 10px;
}

.btn-register-room {
    color: #14243D!important;
    background-color: transparent;
    border: 1px solid #14243D;
}

.btn-register-room:hover {
    color: #FFFFFF !important;
    background-color: #14243D;
    border: 1px solid #14243D;
}

.pi-clock {
    cursor: pointer
}

.room-actions {
    position: absolute;
    top: 10px;
    right: 10px;
}

.room-actions .pi {
    margin-left: 13px;
    font-size: 14px;
    cursor: pointer;
    color: #ddd8d8!important;
}

.rooms-card .room-actions {
    display: none;
}

.rooms-card:hover .room-actions {
    display: block;
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

@media (max-width: 1000px) {
    .custom-col-width-2 {
        width: 12%;
    }

    .custom-col-width-10 {
        width: 88%;
    }
}
</style>