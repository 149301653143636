import store from '@/store'
import router from '@/router'
import moment from 'moment-timezone';
import { CLEAR_ERRORMSG, LOGOUT, UPDATE_ERRORMSG } from '@/store/action_types'
import { AXIOS_TIMEOUT, SERVER_ERR_MSG } from '@/utils/const'
import { resetLoaders } from '@/utils/loaders'
import axios, { AxiosInstance } from 'axios'
import { LOGIN_ROUTE } from '@/router/routes'

const baseAPI: AxiosInstance = axios.create({
    baseURL: `${process.env.NODE_ENV == 'development' ? process.env.VUE_APP_DEV_SERVER_URL : process.env.VUE_APP_SERVER_URL}/${process.env.VUE_APP_VERSION}`,
    // baseURL: `${process.env.VUE_APP_SERVER_URL}/${process.env.VUE_APP_VERSION}`,
    headers: {
        "Content-type": "application/json"
    },
    timeout: AXIOS_TIMEOUT
})

baseAPI.interceptors.request.use(
    (request) => {
        const userTimeZone = moment.tz.guess();
        request.headers['X-User-Timezone'] = userTimeZone;

        const token = store.getters.sessionToken
        if(token) request.headers['Authorization'] = `Bearer ${token}`
        return request
    },
    (requestError) =>{
        // TODO: add error handling for when network disappears
        return requestError
    }
)

baseAPI.interceptors.response.use((response) => {
        store.dispatch(CLEAR_ERRORMSG)
        return response;
    }, function(error) {
        try {
            console.log('error => ', error)
            console.log('error.code => ', error.code === 'ECONNABORTED')
            
            // clear error messages
            resetLoaders()

            // Do something with response error
            if (error.code === 'ECONNABORTED') {
                store.dispatch(UPDATE_ERRORMSG, { errorMsg: 'Error! Please check your connection. ECONNABORTED.' })
            }

            if (error.response?.status === 400) {
                store.dispatch(UPDATE_ERRORMSG, { errorMsg: error.response.data.message })
                // store.dispatch(LOGOUT_LOCALLY)
            }

            if (error.response.status === 401) {
                store.dispatch(UPDATE_ERRORMSG, { errorMsg: error.response.data.message })
                store.dispatch(LOGOUT)
                router.push({ name: LOGIN_ROUTE })
            }

            if (error.response.status === 403) {
                store.dispatch(UPDATE_ERRORMSG, { errorMsg: error.response.data.message })
                store.dispatch(LOGOUT)
                router.push({ name: LOGIN_ROUTE })
            }

            if (error.response.status === 404) {
                store.dispatch(UPDATE_ERRORMSG, { errorMsg: error.response.data.message })
                    // router.push({ name: '404' })
            }

            if (error.response.status === 409) {
                store.dispatch(UPDATE_ERRORMSG, { errorMsg: error.response.data.message })
            }

            if (error.response.status === 500) {
                store.dispatch(UPDATE_ERRORMSG, { errorMsg: error.response.data.message || SERVER_ERR_MSG })
            }
        } catch(e) {
            return Promise.reject(error)
        }
    } 
);

export default baseAPI