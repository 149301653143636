export const LARGE_SCREEN_SIZE = 992
export const AXIOS_TIMEOUT = 30000

export const SERVER_ERR_MSG = 'Server Error!'

export const DEFAULT_ERROR_MSG = 'Error! Something went wrong.'
export const TOAST_GLOBAL_CONFIG = { position: 'top-right', dismissible: true }

export const LOCAL_STORAGE_KEY = 'local'

export const DATE_OPTIONS : Intl.DateTimeFormatOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
export const TIME_OPTIONS : Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric' };
export const DATETIME_OPTIONS : Intl.DateTimeFormatOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };

export const DONATION_TYPE_DAILY = 'daily'
export const DONATION_TYPE_WEEKLY = 'weekly'
export const DONATION_TYPE_MONTHLY = 'monthly'
export const DONATION_TYPE_YEARLY = 'yearly'

export const DEFAULT_LAST_X_DAYS_USER_STATS_FETCH: number = 10

export const DONTATION_TYPE = [
    'daily',
    'weekly',
    'monthly',
    'yearly'
]

export const HEBREW_MONTHS = [
    "Nisan",
    "Iyar",
    "Sivan",
    "Tammuz",
    "Av",
    "Elul",
    "Tishrei",
    "Cheshvan",
    "Kislev",
    "Tevet",
    "Shevat",
    "Adar",
    "Adar I",
    "Adar II"
]

export const MONTHS_IN_HEBREW = [
    "ניסן",
    "אייר",
    "סיון",
    "תמוז",
    "אב",
    "אלול",
    "תשרי",
    "חשון",
    "כסלו",
    "טבת",
    "שבט",
    "אדר",
    "אדר א",
    "אדר ב"
]

export const DAYS_IN_HEBREW = [
    "א",
    "ב",
    "ג",
    "ד",
    "ה",
    "ו",
    "ז",
    "ח",
    "ט",
    "י",
    'י"א',
    'י"ב',
    'י"ג',
    'י"ד',
    'ט"ו',
    'ט"ז',
    'י"ז',
    'י"ח',
    'י"ט',
    'כ',
    'כ"א',
    'כ"ב',
    'כ"ג',
    'כ"ד',
    'כ"ה',
    'כ"ו',
    'כ"ז',
    'כ"ח',
    'כ"ט',
    'ל',
    'ל"א'
]

export const HEBREW_YEARS = [
    '5774',
    '5775',
    '5776',
    '5777',
    '5778',
    '5779',
    '5780',
    '5781',
    '5782',
    '5783',
    '5784',
    '5785',
    '5786',
    '5787',
    '5788',
    '5789',
    '5790',
    '5791',
    '5792',
    '5793',
    '5794',
    '5795',
    '5796',
    '5797',
    '5798',
    '5799',
    '5800',
    '5801',
    '5802',
    '5803',
    '5804',
]

export const YEARS_IN_HEBREW = [
    'תשע"ד',
    'תשע"ה',
    'תשע"ו',
    'תשע"ז',
    'תשע"ח',
    'תשע"ט',
    'תש"פ',
    'תשפ"א',
    'תשפ"ב',
    'תשפ"ג',
    'תשפ"ד',
    'תשפ"ה',
    'תשפ"ו',
    'תשפ"ז',
    'תשפ"ח',
    'תשפ"ט',
    'תש"צ',
    'תשצ"א',
    'תשצ"ב',
    'תשצ"ג',
    'תשצ"ד',
    'תשצ"ה',
    'תשצ"ו',
    'תשצ"ז',
    'תשצ"ח',
    'תשצ"ט',
    'ת"ת',
    'תת"א',
    'תת"ב',
    'תת"ג',
    'תת"ד'
]

export const DONATION_TITLES = [
    'נתנדב על ידי ידידינו הנכבד, רודף צדקה וחסד, שמו מפארים',
    'נתנדב על ידי ידידינו הרבני הנגיד, מפורסם לשם ולתהלה, בתוך עם סגולה, כש”ת',
    'נתנדב על ידי ידידינו הרה”ג המפואר, צנמ”ס, כבוד שמו תפארתו'
]

export const WEBSITE_URL = 'https://persim.us/'
export const API_URL = 'https://api.persim.us'
// export const API_URL = 'http://localhost:8080'

export const DONATION_VIEW_LIST = 'DONATION_VIEW_LIST'
export const DONATION_VIEW_CALENDAR = 'DONATION_VIEW_CALENDAR'

export const MINYANIM_VIEW_ROOMS = 'MINYANIM_VIEW_ROOMS'
export const MINYANIM_VIEW_SCHEDULES = 'MINYANIM_VIEW_SCHEDULES'

export const EDIT_ACCOUNT_TYPE_USER = 'EDIT_ACCOUNT_TYPE_USER'
export const EDIT_ACCOUNT_TYPE_SELF = 'EDIT_ACCOUNT_TYPE_SELF'

export const USER_ROLES = [
    'admin',
    'user'
]

export const USER_ROLE_ADMIN = 'admin'
export const USER_ROLE_USER = 'user'

export const STORAGE_URL = 'https://persim-screens.nyc3.digitaloceanspaces.com'

export const VUE_PROGRESSBAR_OPTIONS = {
    color: '#bffaf3',
    failedColor: '#874b4b',
    thickness: '5px',
    transition: {
        speed: '0.2s',
        opacity: '0.6s',
        termination: 300
    },
    autoRevert: true,
    location: 'left',
    inverse: false
}

export const DISPLAY_REFRESH_DONATION_SOCKET_EVENT = 'DISPLAY_REFRESH_DONATION_SOCKET_EVENT'

export const DISPLAY_SCREEN_ONE_ID = '9717ca74-f5ac-4cc5-bd66-1d8d5b60d41e'
export const DISPLAY_SCREEN_TWO_ID = '1f3d1081-d159-4c82-b4cb-9d63dde22def'
export const DISPLAY_SCREEN_THREE_ID = 'c8df9f96-41c1-4324-8bf0-fc146f204b7e'

export const DATE_PICKER_DONATION_DATE = 'DATE_PICKER_DONATION_DATE'
export const DATE_PICKER_DONATION_DISPLAY_START_DATE = 'DATE_PICKER_DONATION_DISPLAY_START_DATE'
export const DATE_PICKER_DONATION_DISPLAY_END_DATE = 'DATE_PICKER_DONATION_DISPLAY_END_DATE'

export const DATE_PICKER_INVOICE_ITEM_DUE_DATE = 'DATE_PICKER_INVOICE_ITEM_DUE_DATE'

export const DATE_PICKER_ANNOUNCEMENT_DISPLAY_START_DATE = 'DATE_PICKER_ANNOUNCEMENT_DISPLAY_START_DATE'
export const DATE_PICKER_ANNOUNCEMENT_DISPLAY_END_DATE = 'DATE_PICKER_ANNOUNCEMENT_DISPLAY_END_DATE'

export const DATE_PICKER_SHIURIM_DISPLAY_START_DATE = 'DATE_PICKER_SHIURIM_DISPLAY_START_DATE'
export const DATE_PICKER_SHIURIM_DISPLAY_END_DATE = 'DATE_PICKER_SHIURIM_DISPLAY_END_DATE'

export const DATE_PICKER_PURPOSE_DATE = 'DATE_PICKER_PURPOSE_DATE'
export const DATE_PICKER_PURPOSE_CUSTOM_DATE = 'DATE_PICKER_PURPOSE_CUSTOM_DATE'

export const PURPOSE_REMINDER_DATES = [
    'a month before',
    'a week before',
    '3 days before',
    'a day before',
    'add custom date'
]

export const MINYANIM_TYPES = [
    'Shacharis',
    'Mincha',
    'Maariv'
]

export const MINYANIM_DATE_TYPES = [
    'Sunday',
    'Monday - Thursday',
    'Friday',
    'Shabbas',
    'Custom Date'
]

export const CURRENCIES = [
    'USD'
]

export const DATE_PICKER_MINYANIM_START_DATE = 'DATE_PICKER_MINYANIM_START_DATE'
export const DATE_PICKER_MINYANIM_END_DATE = 'DATE_PICKER_MINYANIM_END_DATE'

export const DATE_PICKER_PLEDGE_START_DATE = 'DATE_PICKER_PLEDGE_START_DATE'
export const DATE_PICKER_PLEDGE_END_DATE = 'DATE_PICKER_PLEDGE_END_DATE'

export const MINYANIM_TYPE_SHACHARIS = 'Shacharis'
export const MINYANIM_TYPE_MINCHA = 'Mincha'
export const MINYANIM_TYPE_MAARIV = 'Maariv'

export const TICKET_STATUS_OPEN = 'open'
export const TICKET_STATUS_CLOSED = 'closed'

export const INVOICE_ITEM_MODAL_ITEMS_TAB = 'INVOICE_ITEM_MODAL_ITEMS_TAB'
export const INVOICE_ITEM_MODAL_PAYMENTS_TAB = 'INVOICE_ITEM_MODAL_PAYMENTS_TAB'

export const SYNAGOGUE_SEAT_PRICE_CATEGORY_ADD_NEW_TAB = 'SYNAGOGUE_SEAT_PRICE_CATEGORY_ADD_NEW_TAB'
export const SYNAGOGUE_SEAT_PRICE_CATEGORY_EDIT_TAB = 'SYNAGOGUE_SEAT_PRICE_CATEGORY_EDIT_TAB'
export const SYNAGOGUE_SEAT_PRICE_CATEGORY_DELETE_TAB = 'SYNAGOGUE_SEAT_PRICE_CATEGORY_DELETE_TAB'

export const TICKET_COMMENT_TYPES = [
    'ticket-close',
    'ticket-reopen',
    'ticket-comment'
]

export const SEATS_MATRIX = [
  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
  [21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40],
  [41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60],
  [61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80],
  [81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100],
  [101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120],
  [121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140],
  [141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 159, 160],
  [161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180],
  [181, 182, 183, 184, 185, 186, 187, 188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200],
  [201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220],
  [221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240],
  [241, 242, 243, 244, 245, 246, 247, 248, 249, 250, 251, 252, 253, 254, 255, 256, 257, 258, 259, 260],
  [261, 262, 263, 264, 265, 266, 267, 268, 269, 270, 271, 272, 273, 274, 275, 276, 277, 278, 279, 280],
  [281, 282, 283, 284, 285, 286, 287, 288, 289, 290, 291, 292, 293, 294, 295, 296, 297, 298, 299, 300],
  [301, 302, 303, 304, 305, 306, 307, 308, 309, 310, 311, 312, 313, 314, 315, 316, 317, 318, 319, 320],
  [321, 322, 323, 324, 325, 326, 327, 328, 329, 330, 331, 332, 333, 334, 335, 336, 337, 338, 339, 340],
  [341, 342, 343, 344, 345, 346, 347, 348, 349, 350, 351, 352, 353, 354, 355, 356, 357, 358, 359, 360],
  [361, 362, 363, 364, 365, 366, 367, 368, 369, 370, 371, 372, 373, 374, 375, 376, 377, 378, 379, 380],
  [381, 382, 383, 384, 385, 386, 387, 388, 389, 390, 391, 392, 393, 394, 395, 396, 397, 398, 399, 400]
]

export const PAYMENT_METHODS = [
    'Credit Card',
    'Zelle',
    'Cash',
    'Check',
    'Charity Card'
]

export const CHARITY_CARD_OPTIONS = [
    'OJC',
    'The donnors font',
    'Pledger',
    'Matbia',
    'Other'
]

export const SEAT_COLOR_OPTION_HEX_VALUES = [
    '#0079bf',
    '#D29034',
    '#519839',
    '#B04632',
    '#CD5A91',
    '#89609E',
]

export const MEMBER_TITLES = [
    'Rabbi and Reb',
    'Mr and Ms',
    'Mr',
    'Rabbi'
]

export const REPORT_FILE_TYPE_DONATION = 'donation'
export const REPORT_FILE_TYPE_MEMBER_STATEMENT = 'member_statement'

export const MODULE_SETTING_MEMBER = 'MODULE_SETTING_MEMBER'
export const MODULE_SETTING_PLEDGES = 'MODULE_SETTING_PLEDGES'
export const MODULE_SETTING_PURPOSE = 'MODULE_SETTING_PURPOSE'
export const MODULE_SETTING_MINYANIM = 'MODULE_SETTING_MINYANIM'
export const MODULE_SETTING_SEATS = 'MODULE_SETTING_SEATS'
export const MODULE_SETTING_SCREENS = 'MODULE_SETTING_SCREENS'

export const SETTING_OPTION_VALUES = [
    'MODULE_SETTING_MEMBER',
    'MODULE_SETTING_PLEDGES',
    'MODULE_SETTING_PURPOSE',
    'MODULE_SETTING_MINYANIM',
    'MODULE_SETTING_SEATS',
    'MODULE_SETTING_SCREENS'
]

export const SETTING_OPTION_NAMES = [
    'Member',
    'Pledges',
    'Purpose',
    'Minyanim',
    'Seats',
    'Screens'
]

export const ALIAS_OFFERING_TYPES = [
    'כהן',
    'לוי',
    'ישראל',
    'רביעי',
    'חמישי',
    'שישי',
    'אחרון',
    'מפטיר',
    'הוספה',
    'הגבה גלילה',
    'הוצאה והכנסה',
    'מי שברך'
]

export const PARSHIYOTS = [
  "בְּרֵאשִׁית",
  "נֹחַ",
  "לֶךְ-לְךָ",
  "וַיֵּרָא",
  "חַיֵּי שָׂרָה",
  "תּוֹלְדוֹת",
  "וַיֵּצֵא",
  "וַיִּשְׁלַח",
  "וַיֵּשֶׁב",
  "מִקֵּץ",
  "וַיִּגַּשׁ",
  "וַיְחִי",
  "שְׁמוֹת",
  "וָאֵרָא",
  "בֹּא",
  "בְּשַׁלַּח",
  "יִתְרוֹ",
  "מִּשְׁפָּטִים",
  "תְּרוּמָה",
  "תְּצַוֶּה",
  "כִּי תִשָּׂא",
  "וַיַּקְהֵל",
  "פְקוּדֵי",
  "וַיִּקְרָא",
  "צַו",
  "שְּׁמִינִי",
  "תַזְרִיעַ",
  "מְּצֹרָע",
  "אַחֲרֵי מוֹת",
  "קְדֹשִׁים",
  "אֱמוֹר",
  "בְּהַר",
  "בְּחֻקֹּתַי",
  "בְּמִדְבַּר",
  "נָשֹׂא",
  "בְּהַעֲלֹתְךָ",
  "שְׁלַח",
  "קֹרַח",
  "חֻקַּת",
  "בָּלָק",
  "פִּינְחָס",
  "מַּטּוֹת",
  "מַסְעֵי",
  "דְּבָרִים",
  "וָאֶתְחַנַּן",
  "עֵקֶב",
  "רְאֵה",
  "שֹׁפְטִים",
  "כִּי-תֵצֵא",
  "כִּי-תָבוֹא",
  "נִצָּבִים",
  "וַיֵּלֶךְ",
  "הַאֲזִינוּ",
  "וְזֹאת הַבְּרָכָה"
];

export const PARSHATS = ['Parashat Vayeshev', 'Parashat Miketz', 'Parashat Vayigash', 'Parashat Vayechi', 'Parashat Shemot', 'Parashat Vaera', 'Parashat Bo', 'Parashat Beshalach', 'Parashat Yitro', 'Parashat Mishpatim', 'Parashat Terumah', 'Parashat Tetzaveh', 'Parashat Ki Tisa', 'Parashat Vayakhel-Pekudei', 'Parashat Vayikra', 'Parashat Tzav', 'Parashat Shmini', 'Parashat Tazria-Metzora', 'Parashat Achrei Mot-Kedoshim', 'Parashat Emor', 'Parashat Behar-Bechukotai', 'Parashat Bamidbar', 'Parashat Nasso', 'Parashat Beha’alotcha', 'Parashat Sh’lach', 'Parashat Korach', 'Parashat Chukat-Balak', 'Parashat Pinchas', 'Parashat Matot-Masei', 'Parashat Devarim', 'Parashat Vaetchanan', 'Parashat Eikev', 'Parashat Re’eh', 'Parashat Shoftim', 'Parashat Ki Teitzei', 'Parashat Ki Tavo', 'Parashat Nitzavim-Vayeilech', 'Parashat Ha’azinu', 'Parashat Bereshit', 'Parashat Noach', 'Parashat Lech-Lecha', 'Parashat Vayera', 'Parashat Chayei Sara', 'Parashat Toldot', 'Parashat Vayetzei', 'Parashat Vayishlach', 'Parashat Vayeshev', 'Parashat Miketz']
export const PARSHAT_MAPS = {
    'Parashat Vayeshev': 'וישב',
    'Parashat Vayigash': 'ויגש',
    'Parashat Vayechi': 'ויחי',
    'Parashat Shemot': 'שמות',
    'Parashat Vaera': 'וארא',
    'Parashat Bo': 'בא',
    'Parashat Beshalach': 'בשלח',
    'Parashat Yitro': 'יתרו',
    'Parashat Mishpatim': 'משפטים',
    'Parashat Terumah': 'תרומה',
    'Parashat Tetzaveh': 'תצוה',
    'Parashat Ki Tisa': 'כי תשא',
    'Parashat Vayakhel-Pekudei': 'ויקהל פקודי',
    'Parashat Vayikra': 'ויקרא',
    'Parashat Tzav': 'צו',
    'Parashat Shmini': 'שמיני',
    'Parashat Tazria-Metzora': 'תזריע מצורע',
    'Parashat Achrei Mot-Kedoshim': 'אחרי קדושים',
    'Parashat Emor': 'אמור',
    'Parashat Behar-Bechukotai': 'בהר בחקותי',
    'Parashat Bamidbar': 'במדבר',
    'Parashat Nasso': 'נשא',
    'Parashat Beha’alotcha': 'בהעלותך',
    'Parashat Sh’lach': 'שלח',
    'Parashat Korach': 'קרח',
    'Parashat Chukat-Balak': 'חוקת  בלק',
    'Parashat Pinchas': 'פנחס',
    'Parashat Matot-Masei': 'מטות מסעי',
    'Parashat Devarim': 'דברים',
    'Parashat Vaetchanan': 'ואתחנן',
    'Parashat Eikev': 'עקב',
    'Parashat Re’eh': 'ראה',
    'Parashat Shoftim': 'שופטים',
    'Parashat Ki Teitzei': 'כי תצא',
    'Parashat Ki Tavo': 'כי תבוא',
    'Parashat Nitzavim-Vayeilech': 'נצבים וילך',
    'Parashat Ha’azinu': 'האזינו',
    'Parashat Bereshit': 'בראשית',
    'Parashat Noach': 'נח',
    'Parashat Lech-Lecha': 'לך לך ',
    'Parashat Vayera': 'וירא',
    'Parashat Chayei Sara': 'חי שרה',
    'Parashat Toldot': 'תולדות',
    'Parashat Vayetzei': 'ויצא',
    'Parashat Vayishlach': 'וישלח',
    'Parashat Miketz': 'מקץ',
    'Vayakhel': 'ויקהל',
    'Pekudei': 'פקודי',
    'Tazria': 'מצורע ',
    'Metzora': 'תזריע  ',
    'Acharei': 'אחרי ',
    'Kedoshim': 'קדושים ',
    'Behar': 'בהר',
    'Bechukotai': "'בחוקתי",
    'Chukat': 'חוקת',
    'Balak': 'בלק',
    'Matot': 'חוקת',
    'Masei': 'מסעי',
    'Nitzavim': 'נצבים',
    'Vayelech': 'וילך'
}