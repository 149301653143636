import axios, { AxiosResponse } from 'axios'
import { SET_DONATIONS, SET_SESSION_TOKEN, SET_ERRORMSG, SET_DONATION, SET_CALENDAR_DONATIONS, SET_DISPLAY_DONATIONS, SET_MEMBER_DONATIONS } from '../mutation_types'
import {
    CLEAR_DISPLAY_DONATIONS,
    CLEAR_DONATION_ISLOADING,
    DELETE_DONATION,
    EDIT_DONATION,
    GET_CALENDAR_DONATIONS,
    GET_DISPLAY_DONATIONS,
    GET_DONATIONS,
    GET_MEMBER_DONATIONS,
    REGISTER_DONATION,
    TOGGLE_DONATION_IS_ACTIVE,
} from '../action_types'
import { StateObject } from '@/dtos/state.dto'
import Donation from '@/services/donation'
import { Module } from 'vuex'
import { GetDonation, EditDonation, RegisterDonation } from '@/dtos/donation.dto'

const donation: Module<any, {}> = {
    state: {
        donation: {
            data: null,
            isLoading: false
        },
        donations: {
            data: null,
            isLoading: false
        },
        displayDonations: {
            data: null,
            isLoading: false
        },
        calendarDonations: {
            data: null,
            isLoading: false
        },
        memberDonations: {
            data: null,
            isLoading: false
        },
    },
    getters: {
        donation: (state: { donation: any }) => state.donation,
        donations: (state: { donations: any }) => state.donations,
        displayDonations: (state: { displayDonations: any }) => state.displayDonations,
        calendarDonations: (state: { calendarDonations: any }) => state.calendarDonations,
        memberDonations: (state: { memberDonations: any }) => state.memberDonations
    },
    mutations: {
        [SET_DONATION]: (state: { donation: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.donation.data = payload.data : null, state.donation.isLoading = payload.isLoading },
        [SET_DONATIONS]: (state: { donations: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.donations.data = payload.data : null, state.donations.isLoading = payload.isLoading },
        [SET_DISPLAY_DONATIONS]: (state: { displayDonations: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.displayDonations.data = payload.data : null, state.displayDonations.isLoading = payload.isLoading },
        [SET_CALENDAR_DONATIONS]: (state: { calendarDonations: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.calendarDonations.data = payload.data : null, state.calendarDonations.isLoading = payload.isLoading },
        [SET_MEMBER_DONATIONS]: (state: { memberDonations: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.memberDonations.data = payload.data : null, state.memberDonations.isLoading = payload.isLoading }
    },
    actions: {
        [REGISTER_DONATION]: async({ commit }: any, payload: any) => {
            commit(SET_DONATION, { data: null, isLoading: true })

            let result: AxiosResponse = await Donation.registerDonation(payload as RegisterDonation)
          
            if(result) {
                if (result.data.status) {
                    commit(SET_DONATION, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_DONATION, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_DONATION, { isLoading: false })
                return false;
            }
        },
        [GET_DONATIONS]: async({ commit }: any, payload: any) => {
            commit(SET_DONATIONS, { data: null, isLoading: true })

            let result: AxiosResponse = await Donation.getDonations(payload as GetDonation)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_DONATIONS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_DONATIONS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_DONATIONS, { isLoading: false })
                return false;
            }
        },
        [GET_DISPLAY_DONATIONS]: async({ commit }: any, payload: any) => {
            commit(SET_DISPLAY_DONATIONS, { data: null, isLoading: true })

            let result: AxiosResponse = await Donation.getDonations(payload as GetDonation)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data ggggggttt', result.data.data)
                    commit(SET_DISPLAY_DONATIONS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_DISPLAY_DONATIONS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_DISPLAY_DONATIONS, { isLoading: false })
                return false;
            }
        },
        [GET_CALENDAR_DONATIONS]: async({ commit }: any, payload: any) => {
            commit(SET_CALENDAR_DONATIONS, { data: null, isLoading: true })

            let result: AxiosResponse = await Donation.getCalendarDonation(payload as GetDonation)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_CALENDAR_DONATIONS, { data: result.data.data, isLoading: false })
                    return result.data.data;
                }
                commit(SET_CALENDAR_DONATIONS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_CALENDAR_DONATIONS, { isLoading: false })
                return false;
            }
        },
        [GET_MEMBER_DONATIONS]: async({ commit }: any, payload: any) => {
            commit(SET_MEMBER_DONATIONS, { data: null, isLoading: true })

            let result: AxiosResponse = await Donation.getMemberDonations(payload as GetDonation)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_MEMBER_DONATIONS, { data: result.data.data, isLoading: false })
                    return result.data.data;
                }
                commit(SET_MEMBER_DONATIONS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_MEMBER_DONATIONS, { isLoading: false })
                return false;
            }
        },
        [EDIT_DONATION]: async({ commit }: any, payload: any) => {
            commit(SET_DONATION, { data: null, isLoading: true })

            let result: AxiosResponse = await Donation.editDonation(payload as EditDonation)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_DONATION, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_DONATION, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_DONATION, { isLoading: false })
                return false;
            }
        },
        [TOGGLE_DONATION_IS_ACTIVE]: async({ commit, state }: any, payload: EditDonation) => {
            commit(SET_DONATION, { data: { id: payload.id }, isLoading: true })

            let result: AxiosResponse = await Donation.toggleDonationIsActive(payload as EditDonation)
          
            if(result) {
                if (result.data.status) {
                    let index
                    state.donations.data.forEach((e: any, i: number) => {
                        if (e.id == result.data.data.id) {
                            console.log('e.id == result.data.data.id gotcha', e.id == result.data.data.id)
                            index = i
                        }
                    })

                    if (index != null) state.donations.data[index].isActive = result.data.data.isActive

                    commit(SET_DONATION, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_DONATION, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_DONATION, { isLoading: false })
                return false;
            }
        },
        [DELETE_DONATION]: async({ commit }: any, payload: string) => {
            commit(SET_DONATION, { data: null, isLoading: true })

            let result: AxiosResponse = await Donation.deleteDonation(payload)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_DONATION, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_DONATION, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_DONATION, { isLoading: false })
                return false;
            }
        },
        [CLEAR_DISPLAY_DONATIONS]: ({ commit }) => {
            commit(SET_DISPLAY_DONATIONS, { data: null, isLoading: false })
        },
        [CLEAR_DONATION_ISLOADING]: ({ commit }) => {
            commit(SET_DONATION, { isLoading: false })
            commit(SET_DONATIONS, { isLoading: false })
            commit(SET_CALENDAR_DONATIONS, { isLoading: false })
        }
    }
}

export default donation