<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeGenerateMemberStatementModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Generate Statement</span>
            <br>
            <div class="modal__content">
                <JewishDatePicker v-if="currentlyShowingJewDatePicker == DATE_PICKER_PLEDGE_START_DATE"
                    @select-date="selectStartDate" @close-date-picker-modal="closeModal()" />
                <JewishDatePicker v-if="currentlyShowingJewDatePicker == DATE_PICKER_PLEDGE_END_DATE"
                    @select-date="selectEndDate" @close-date-picker-modal="closeModal()" />
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="row justify-content-end">
                                <div class="col-3">
                                    <div @click="currentlyShowingJewDatePicker = DATE_PICKER_PLEDGE_START_DATE"
                                        class="form-group">
                                        <label for="startDate">Start Date</label>
                                        <div class="row date-picker-row">
                                            <div class="col-10">
                                                <Field v-model="startDate" rules="required" disabled type="text"
                                                    name="startDate" class="form-control" id="startDate"
                                                    placeholder="Start Date" />
                                            </div>
                                            <div class="col-2">
                                                <i class="pi pi-calendar-plus text-primary"></i>
                                            </div>
                                        </div>
                                        <ErrorMessage name="startDate" class="form-text text-danger" />
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div @click="currentlyShowingJewDatePicker = DATE_PICKER_PLEDGE_END_DATE"
                                        class="form-group">
                                        <label for="endDate">End Date</label>
                                        <div class="row date-picker-row">
                                            <div class="col-10">
                                                <Field v-model="endDate" rules="required" disabled type="text"
                                                    name="endDate" class="form-control" id="endDate"
                                                    placeholder="End Date" />
                                            </div>
                                            <div class="col-2">
                                                <i class="pi pi-calendar-plus text-primary"></i>
                                            </div>
                                        </div>
                                        <ErrorMessage name="endDate" class="form-text text-danger" />
                                    </div>
                                </div>
                                <div class="col-1">
                                    <div class="form-check custom-checkbox">
                                        <label class="form-check-label" for="emailStatement">
                                            <input v-model="generateMemberStatementFileObj.emailStatement" :value="option" type="checkbox"
                                                class="form-check-input" id="emailStatement">
                                            <span class="checkmark"></span>
                                            Email
                                        </label>
                                        </div>
                                </div>
                                <div class="col-3">
                                    <button v-on:click="generateMemberStatementFile()"
                                        class="btn btn-generate-statement"
                                        :disabled="memberStatementFileIsLoading ? true : false">
                                        <span v-if="memberStatementFileIsLoading"
                                            class="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true"></span>
                                        <span v-if="!memberStatementFileIsLoading"> <i class="pi pi-print"></i> </span>
                                        Generate Statement
                                    </button>
                                </div>
                            </div>
                            <hr>
                            <h6 v-if="pledgeItemsIsLoading" class="text-center"><br>Loading... <br></h6>
                            <div v-if="pledgeItems" class="card-body">
                                <table v-if="pledgeItems.pledgeItems" class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Price</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col">Total</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Payment</th>
                                            <th scope="col">Pledge Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-bind:key="index" v-for="(item, index) in pledgeItems.pledgeItems">
                                            <tr
                                                :class="[item.isDeleted ? 'deleted-row' : isOdd(index + 1) ? 'striped' : null]">
                                                <th scope="row">{{ index + 1 }}</th>
                                                <td>
                                                    <span v-if="item.pledgeType">{{ item.pledgeType.name }}</span>
                                                    <span v-if="item.type == 'PLEDGE_TYPE_ALIAS'">{{ item.pledgeAlias?.parshiyot }} | {{ $filters.getDateOnly(item.pledgeAlias?.aliasDate) }}</span>
                                                </td>
                                                <td>{{ $filters.formatPrice(item.price, 'USD') }} </td>
                                                <td>{{ item.quantity }} </td>
                                                <td>{{ $filters.formatPrice(item.price * item.quantity, 'USD') }}
                                                </td>
                                                <td>
                                                    <span
                                                        v-if="(item.isDeleted && item.pledgeItemDeletionReason != null)">
                                                        <span class=""><span
                                                                class="text-danger"><strong>DELETED</strong></span><i
                                                                v-on:click="selectPledgeItemDeletionReason(item.pledgeItemDeletionReason.reason)"
                                                                class="pi pi-info-circle"></i></span>
                                                    </span>
                                                    <span v-else>
                                                        <span v-if="item.totalAmountPaid == 0"
                                                            class="badge badge-danger">- {{
                                                            $filters.formatPrice(item.price * item.quantity, 'USD')
                                                            }}</span>
                                                        <span
                                                            v-if="item.totalAmountPaid > 0 && (item.totalAmountPaid < (item.price * item.quantity))"
                                                            class="badge badge-warning"> - {{
                                                            $filters.formatPrice((item.price * item.quantity) -
                                                            item.totalAmountPaid, 'USD') }}</span>
                                                        <span
                                                            v-if="item.totalAmountPaid == (item.price * item.quantity)"
                                                            class="badge badge-success"> + {{
                                                            $filters.formatPrice(item.totalAmountPaid, 'USD')
                                                            }}</span>
                                                        <span v-if="item.pledgeItemEditReasons">
                                                            <i v-if="item.pledgeItemEditReasons.length > 0"
                                                                v-on:click="selectPledgeItemEditReason(item.pledgeItemEditReasons)"
                                                                class="pi pi-info-circle"></i>
                                                        </span>
                                                    </span>
                                                </td>
                                                <td>
                                                    <button v-on:click="selectPledgeItemToPay(item)"
                                                        class="btn btn-pay-pledge-item">PAY</button>
                                                </td>
                                                <td><span class="frankRuhl-font">{{
                                                        $filters.convertToHebrewDate(item.pledgeDate) }}</span></td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import JewishDatePicker from '@/components/JewishDatePicker.vue';
import deleteMemberStatementFileModal from '@/components/modals/Member_Statement/Delete_Member_Statement_File_Modal.vue'

import { GET_PLEDGE_ITEMS, GENERATE_MEMBER_STATEMENT_FILE } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';
import { DATE_PICKER_PLEDGE_START_DATE, DATE_PICKER_PLEDGE_END_DATE, STORAGE_URL } from '@/utils/const';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
    },
    components: {
        deleteMemberStatementFileModal,
        JewishDatePicker,
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage
    },
    data() {
        return {
            DATE_PICKER_PLEDGE_START_DATE,
            DATE_PICKER_PLEDGE_END_DATE,
            showModal: true,
            showDeleteMemberStatementFileModal: false,
            selectedMemberStatementFileId: null,
            currentlyShowingJewDatePicker: null,
            generateMemberStatementFileObj: {
                memb: null,
                startDate: null,
                endDate: null,
                emailStatement: false
            },
            startDate: null,
            endDate: null
        }
    },
    watch: {
        startDate: {
            handler(val) {
                if (val != null && this.endDate != null) {
                    console.log(';;;')
                    this.getPledgeItems()
                }
            }
        },
        endDate: {
            handler(val) {
                if (val != null && this.startDate != null) {
                    this.getPledgeItems()
                }
            }
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        memberStatementFile() {
            return this.$store.getters.memberStatementFile.data
        },
        memberStatementFileIsLoading() {
            return this.$store.getters.memberStatementFile.isLoading
        },
        pledgeItems() {
            return this.$store.getters.pledgeItems.data
        },
        pledgeItemsIsLoading() {
            return this.$store.getters.pledgeItems.isLoading
        },
        account() {
            return this.$store.getters.account.data
        }
    },
    methods: {
        isOdd(number) {
            return number % 2 !== 0;
        },
        selectStartDate(selectedDate) {
            this.generateMemberStatementFileObj.startDate = this.$filters.convertHDateToGregorian(selectedDate)
            this.startDate = this.$filters.convertToHebrewDate(this.generateMemberStatementFileObj.startDate)
            this.closeModal()
        },
        selectEndDate(selectedDate) {
            this.generateMemberStatementFileObj.endDate = this.$filters.convertHDateToGregorian(selectedDate)
            this.endDate = this.$filters.convertToHebrewDate(this.generateMemberStatementFileObj.endDate)
            this.closeModal()
        },
        setValues() {
            this.generateMemberStatementFileObj.memb = this.$route.params.membId
        },
        closeModal() {
            this.currentlyShowingJewDatePicker = null;
        },
        getPledgeItems() {
            this.$store.dispatch(GET_PLEDGE_ITEMS, { memb: this.$route.params.membId, startDate: this.generateMemberStatementFileObj.startDate, endDate: this.generateMemberStatementFileObj.endDate })
        },
        generateMemberStatementFile() {
            this.$store.dispatch(GENERATE_MEMBER_STATEMENT_FILE, this.generateMemberStatementFileObj).then((result) => {
                if (result) {
                    window.open(`${STORAGE_URL}/${result.file}`, '_blank');
                }
            })
        },
    },
    mounted() {
        this.setValues()
        this.getPledgeItems()
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    position: relative;
    left: 10%;
    margin-top: 20px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 0.5rem 1rem 1rem 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
    max-height: 96vh;
    overflow-x: auto;
    overflow-y: auto;
}

::v-deep .modal__content {
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.table .pi-info-circle {
    font-size: 12px;
    cursor: pointer;
    margin-left: 5px;
    position: relative;
    top: 1px;
}

.btn-generate-statement {
    font-size: 14px;
    border-radius: 8px;
    color: #FFFFFF !important;
    background-color: #646F80;
    border-color: #646F80 !important;
    position: relative;
    top: 25px;
}

.btn-generate-statement .pi {
    margin-right: 3px;
}

.pi-trash {
    margin-left: 15px;
}

.form-check {
    padding-left: 0px;
    position: relative;
    top: 33px;
    left: 0px;
    cursor: pointer;
}

.form-check .form-check-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.form-check .form-check-label {
    cursor: pointer;
}

.custom-checkbox .checkmark {
    position: relative;
    top: 3px;
    height: 18px;
    width: 18px;
    background-color: #F9F9FC;
    border: 1px solid #cfd3dd;
    border-radius: 4px;
    display: inline-block;
    margin-right: 10px;

}

.custom-checkbox .form-check-input:checked+.checkmark {
    background-color: #14243D;
}

.custom-checkbox .checkmark::after {
    content: "";
    position: absolute;
    display: none;
}

.custom-checkbox .form-check-input:checked+.checkmark::after {
    display: block;
}

.custom-checkbox .checkmark::after {
    left: 6px;
    top: 3px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
</style>