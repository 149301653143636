import store from "@/store"
import { CLEAR_ACCOUNT_ISLOADING, CLEAR_ALERTS_ISLOADING, CLEAR_ANNOUNCEMENT_ISLOADING, CLEAR_DONATION_ISLOADING, CLEAR_INVOICES_ISLOADING, CLEAR_MEMBER_STATEMENTS_ISLOADING, CLEAR_MEMBER_TYPES_ISLOADING, CLEAR_MEMBERS_ISLOADING, CLEAR_MINYANIM_TYPES_ISLOADING, CLEAR_MINYANIMS_ISLOADING, CLEAR_MODULE_SETTINGS_ISLOADING, CLEAR_NOTIFICATIONS_ISLOADING, CLEAR_ORGANIZATION_ISLOADING, CLEAR_ORGANIZATION_SCREENS_ISLOADING, CLEAR_PLEDGE_ITEM_DELETION_REASONS_ISLOADING, CLEAR_PLEDGE_ITEM_EDIT_REASONS_ISLOADING, CLEAR_PLEDGE_ITEM_PAYMENT_LOGS_ISLOADING, CLEAR_PLEDGE_ITEMS_ISLOADING, CLEAR_PLEDGE_TYPES_ISLOADING, CLEAR_PURPOSE_ISLOADING, CLEAR_RECEIPTS_ISLOADING, CLEAR_REPORT_FILES_ISLOADING, CLEAR_ROOMS_ISLOADING, CLEAR_SCREEN_ISLOADING, CLEAR_SHIURIM_ISLOADING, CLEAR_STATS_ISLOADING, CLEAR_SYNAGOGUE_MEMBER_SEATS_ISLOADING, CLEAR_SYNAGOGUE_SEAT_PRICE_CATEGORIES_ISLOADING, CLEAR_SYNAGOGUE_SEAT_PRICINGS_ISLOADING, CLEAR_SYNAGOGUE_SEATS_ISLOADING, CLEAR_SYNAGOGUES_ISLOADING, CLEAR_TEMPLATE_ISLOADING, CLEAR_TICKET_COMMENTS_ISLOADING, CLEAR_TICKETS_ISLOADING, CLEAR_USERS_ISLOADING } from "@/store/action_types"

export const resetLoaders = () => {
    store.dispatch(CLEAR_ACCOUNT_ISLOADING)
    store.dispatch(CLEAR_ALERTS_ISLOADING)
    store.dispatch(CLEAR_ANNOUNCEMENT_ISLOADING)
    store.dispatch(CLEAR_DONATION_ISLOADING)
    store.dispatch(CLEAR_INVOICES_ISLOADING)
    store.dispatch(CLEAR_MEMBERS_ISLOADING)
    store.dispatch(CLEAR_MEMBER_TYPES_ISLOADING)
    store.dispatch(CLEAR_MEMBER_STATEMENTS_ISLOADING)
    store.dispatch(CLEAR_MINYANIMS_ISLOADING)
    store.dispatch(CLEAR_MINYANIM_TYPES_ISLOADING)
    store.dispatch(CLEAR_MODULE_SETTINGS_ISLOADING)
    store.dispatch(CLEAR_NOTIFICATIONS_ISLOADING)
    store.dispatch(CLEAR_ORGANIZATION_ISLOADING)
    store.dispatch(CLEAR_ORGANIZATION_SCREENS_ISLOADING)
    store.dispatch(CLEAR_PURPOSE_ISLOADING)
    store.dispatch(CLEAR_PLEDGE_ITEMS_ISLOADING)
    store.dispatch(CLEAR_PLEDGE_ITEM_DELETION_REASONS_ISLOADING)
    store.dispatch(CLEAR_PLEDGE_ITEM_EDIT_REASONS_ISLOADING)
    store.dispatch(CLEAR_PLEDGE_ITEM_PAYMENT_LOGS_ISLOADING)
    store.dispatch(CLEAR_PLEDGE_TYPES_ISLOADING)
    store.dispatch(CLEAR_RECEIPTS_ISLOADING)
    store.dispatch(CLEAR_REPORT_FILES_ISLOADING)
    store.dispatch(CLEAR_ROOMS_ISLOADING)
    store.dispatch(CLEAR_SCREEN_ISLOADING)
    store.dispatch(CLEAR_SHIURIM_ISLOADING)
    store.dispatch(CLEAR_STATS_ISLOADING)
    store.dispatch(CLEAR_SYNAGOGUE_SEATS_ISLOADING)
    store.dispatch(CLEAR_SYNAGOGUE_SEAT_PRICE_CATEGORIES_ISLOADING)
    store.dispatch(CLEAR_SYNAGOGUE_SEAT_PRICINGS_ISLOADING)
    store.dispatch(CLEAR_SYNAGOGUE_MEMBER_SEATS_ISLOADING)
    store.dispatch(CLEAR_SYNAGOGUES_ISLOADING)
    store.dispatch(CLEAR_SYNAGOGUE_SEATS_ISLOADING)
    store.dispatch(CLEAR_TEMPLATE_ISLOADING)
    store.dispatch(CLEAR_TICKETS_ISLOADING)
    store.dispatch(CLEAR_TICKET_COMMENTS_ISLOADING)
    store.dispatch(CLEAR_USERS_ISLOADING)
}