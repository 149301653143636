<template>
    <div class="sidenav">
        <br>
        <div class="row">
            <img src="../assets/images/logo_new.png" class="img-fluid col-10 offset-1" alt="">
        </div>
        <div class="links">
            <br>
            <ul class="">
                <router-link :to="{ name: DASHBOARD_ROUTE }">
                    <li :class="[route == DASHBOARD_ROUTE ? 'selected' : null, 'nav-items']">
                        <span class="router-link">
                            <font-awesome-icon icon="fa-solid fa-grip" class="fa" />
                            <span class="d-none d-lg-block">Dashboard</span>
                        </span>
                    </li>
                </router-link>
                <router-link v-if="account != null && account.role == USER_ROLE_ADMIN"
                    :to="{ name: ORGANIZATIONS_ROUTE }">
                    <li
                        :class="[[ORGANIZATIONS_ROUTE, ORGANIZATION_DETAILS_ROUTE, ORGANIZATION_ACCOUNTS_ROUTE, ORGANIZATION_DONATIONS_ROUTE, ORGANIZATION_MINYANIM_ROUTE, ORGANIZATION_ANNOUNCEMENTS_ROUTE, ORGANIZATION_SHIURIMS_ROUTE, ORGANIZATION_SCREENS_ROUTE, ORGANIZATION_MEMBERS_ROUTE, ORGANIZATION_SYNAGOGUES_ROUTE, ORGANIZATION_TEMPLATES_ROUTE, ORGANIZATION_ALIASES_ROUTE, ORGANIZATION_INVOICES_ROUTE, ORGANIZATION_NOTIFICATIONS_ROUTE, ORGANIZATION_SETTINGS_ROUTE].includes(route) ? 'selected' : null, 'nav-items']">
                        <span class="router-link">
                            <font-awesome-icon icon="fa-solid fa-sitemap" class="fa" />
                            <span class="d-none d-lg-block">Organizations</span>
                        </span>
                    </li>
                </router-link>
                <router-link v-if="account != null && account.role == USER_ROLE_USER"
                    :to="{ name: ORGANIZATION_DETAILS_ROUTE, params: { id: account.org } }">
                    <li
                        :class="[[ORGANIZATION_DETAILS_ROUTE, ORGANIZATION_ACCOUNTS_ROUTE, ORGANIZATION_SCREENS_ROUTE].includes(route) ? 'selected' : null, 'nav-items']">
                        <span class="router-link">
                            <font-awesome-icon icon="fa-solid fa-sitemap" class="fa" />
                            <span class="d-none d-lg-block">My Organization</span>
                        </span>
                    </li>
                </router-link>
                <!-- <li :class="[ route == DISPLAY_ROUTE ? 'selected' : null, 'nav-items']"><router-link
                        :to="{ name: DISPLAY_ROUTE }" target="_blank" class="router-link"><font-awesome-icon icon="fa-solid fa-code-branch" class="fa"/> Display
                        Screen</router-link></li> -->
                <router-link v-if="moduleSettings" :to="{ name: MEMBERS_ROUTE }">
                    <li v-if="moduleSettings.includes(MODULE_SETTING_MEMBER)"
                        :class="[[MEMBERS_ROUTE, MEMBER_DETAILS_ROUTE, MEMBER_DONATIONS_ROUTE, MEMBER_PURPOSES_ROUTE, MEMBER_SEATS_ROUTE, MEMBER_INVOICES_ROUTE, MEMBER_INVOICE_PAYMENTS_ROUTE, MEMBER_RECEIPTS_ROUTE, MEMBER_PLEDGES_ROUTE].includes(route) ? 'selected' : null, 'nav-items']">
                        <span class="router-link">
                            <font-awesome-icon icon="fa-solid fa-people-group" class="fa" />
                            <span class="d-none d-lg-block">Members</span>
                        </span>
                    </li>
                </router-link>
                <router-link :to="{ name: DONATIONS_ROUTE }">
                    <li :class="[route == DONATIONS_ROUTE ? 'selected' : null, 'nav-items']">
                        <span class="router-link">
                            <font-awesome-icon icon="fa-solid fa-hand-holding-dollar" class="fa" />
                            <span class="d-none d-lg-block">Donations</span>
                        </span>
                    </li>
                </router-link>
                <router-link v-if="moduleSettings" :to="{ name: SYNAGOGUES_ROUTE }">
                    <li v-if="moduleSettings.includes(MODULE_SETTING_SEATS)"
                        :class="[route == SYNAGOGUES_ROUTE ? 'selected' : null, 'nav-items']">
                        <span class="router-link">
                            <font-awesome-icon icon="fa-solid fa-chair" class="fa" />
                            <span class="d-none d-lg-block">Seats</span>
                        </span>
                    </li>
                </router-link>
                <router-link :to="{ name: ANNOUNCEMENTS_ROUTE }">
                    <li :class="[route == ANNOUNCEMENTS_ROUTE ? 'selected' : null, 'nav-items']">
                        <span class="router-link">
                            <font-awesome-icon icon="fa-solid fa-bullhorn" class="fa" />
                            <span class="d-none d-lg-block">Announcements</span>
                        </span>
                    </li>
                </router-link>
                <router-link>
                    <li
                        :class="[[DONATION_REPORT_STATS_ROUTE, DONATION_REPORT_ROUTE, REPORT_SEATS_ROUTE, REPORT_PLEDGES_ROUTE].includes(route) ? 'selected' : null, 'nav-items']">
                        <span :to="{ name: DONATION_REPORT_STATS_ROUTE }" class="router-link">
                            <font-awesome-icon icon="fa-solid fa-chart-line" class="fa" />
                            <span class="d-none d-lg-block">Reports</span>
                        </span>
                    </li>
                </router-link>
                <router-link :to="{ name: TEMPLATES_ROUTE }">
                    <li :class="[route == TEMPLATES_ROUTE ? 'selected' : null, 'nav-items']">
                        <span class="router-link">
                            <font-awesome-icon icon="fa-solid fa-layer-group" class="fa" />
                            <span class="d-none d-lg-block">Templates</span>
                        </span>
                    </li>
                </router-link>
                <router-link v-if="moduleSettings" :to="{ name: SCREENS_ROUTE }">
                    <li v-if="moduleSettings.includes(MODULE_SETTING_SCREENS)"
                        :class="[route == SCREENS_ROUTE ? 'selected' : null, 'nav-items']">
                        <span class="router-link">
                            <font-awesome-icon icon="fa-solid fa-display" class="fa" /> 
                            <span class="d-none d-lg-block">Screens</span>
                        </span>
                    </li>
                </router-link>
                <router-link v-if="account != null && account.role == USER_ROLE_ADMIN" :to="{ name: ALERT_ROUTE }">
                    <li :class="[route == ALERT_ROUTE ? 'selected' : null, 'nav-items']">
                        <span class="router-link">
                            <font-awesome-icon icon="fa-solid fa-bell" class="fa" />
                            <span class="d-none d-lg-block">Alerts</span>
                        </span>
                    </li>
                </router-link>
                <!-- <li :class="[ route == ANNOUNCEMENTS_ROUTE ? 'selected' : null, 'nav-items']"><router-link
                        :to="{ name: ANNOUNCEMENTS_ROUTE }" class="router-link"><i
                            class="pi"></i>Announcements</router-link></li> -->
                <!-- <li :class="[ route == ACCOUNT_ROUTE ? 'selected' : null, 'nav-items']"><router-link :to="{ name: ACCOUNT_ROUTE }" class="router-link"><font-awesome-icon icon="fa-solid fa-code-branch" class="fa"/> Accounts</router-link></li> -->
            </ul>
        </div>
        <div class="footer">
            <router-link :to="{ name: TICKETS_ROUTE }" class="nav-items selected router-link"><font-awesome-icon
                    icon="fa-solid fa-circle-question" class="fa" /><span class="d-none d-lg-block">Support
                    Chat</span></router-link>
        </div>
        <!-- <div class="logo-img">
            <h6 class="info">P: <span>732-607-8977</span></h6>
            <h6 class="info">E: <span>info@persim.us</span></h6>
            <img v-on:click="routeToWebsite()" src="../assets/images/logo-sidenav.png" class="col-12" alt="" srcset="">
        </div> -->
    </div>
</template>
<script>
import { DASHBOARD_ROUTE, DISPLAY_ROUTE, DONATIONS_ROUTE, SYNAGOGUES_ROUTE, MEMBERS_ROUTE, MEMBER_DETAILS_ROUTE, MEMBER_DONATIONS_ROUTE, MEMBER_PURPOSES_ROUTE, MEMBER_SEATS_ROUTE, MEMBER_INVOICES_ROUTE, MEMBER_RECEIPTS_ROUTE, MEMBER_PLEDGES_ROUTE, TEMPLATES_ROUTE, ACCOUNT_ROUTE, ANNOUNCEMENTS_ROUTE, SCREENS_ROUTE, ORGANIZATIONS_ROUTE, ORGANIZATION_DETAILS_ROUTE, ORGANIZATION_ACCOUNTS_ROUTE, ORGANIZATION_DONATIONS_ROUTE, ORGANIZATION_MINYANIM_ROUTE, ORGANIZATION_ANNOUNCEMENTS_ROUTE, ORGANIZATION_SHIURIMS_ROUTE, ORGANIZATION_SCREENS_ROUTE, ORGANIZATION_MEMBERS_ROUTE, ORGANIZATION_SYNAGOGUES_ROUTE, ORGANIZATION_TEMPLATES_ROUTE, ORGANIZATION_INVOICES_ROUTE, ORGANIZATION_NOTIFICATIONS_ROUTE, ORGANIZATION_SETTINGS_ROUTE, ALERT_ROUTE, TICKETS_ROUTE, MEMBER_INVOICE_PAYMENTS_ROUTE, DONATION_REPORT_STATS_ROUTE, DONATION_REPORT_ROUTE, REPORT_SEATS_ROUTE, REPORT_PLEDGES_ROUTE } from '@/router/routes'
import { LOGOUT } from '@/store/action_types'
import { WEBSITE_URL, DEFAULT_ERROR_MSG, TOAST_GLOBAL_CONFIG, USER_ROLE_ADMIN, USER_ROLE_USER, MODULE_SETTING_MEMBER, MODULE_SETTING_SEATS, MODULE_SETTING_SCREENS } from '@/utils/const';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faGrip, faSitemap, faHandHoldingDollar, faChair, faPeopleGroup, faLayerGroup, faDisplay, faBell, faCircleQuestion, faChartLine, faBullhorn } from '@fortawesome/free-solid-svg-icons'
import {  } from '@fortawesome/free-regular-svg-icons'
import { ORGANIZATION_ALIASES_ROUTE } from '../router/routes';
library.add(faGrip, faSitemap, faHandHoldingDollar, faChair, faPeopleGroup, faLayerGroup, faDisplay, faBell, faCircleQuestion, faChartLine, faBullhorn)

export default {
    data() {
        return {
            USER_ROLE_ADMIN,
            USER_ROLE_USER,
            DASHBOARD_ROUTE,
            DISPLAY_ROUTE,
            DONATIONS_ROUTE,
            SYNAGOGUES_ROUTE,
            DONATION_REPORT_STATS_ROUTE,
            DONATION_REPORT_ROUTE,
            REPORT_SEATS_ROUTE,
            REPORT_PLEDGES_ROUTE,
            MEMBERS_ROUTE,
            MEMBER_DETAILS_ROUTE,
            MEMBER_DONATIONS_ROUTE,
            MEMBER_PURPOSES_ROUTE,
            MEMBER_SEATS_ROUTE,
            MEMBER_INVOICES_ROUTE,
            MEMBER_INVOICE_PAYMENTS_ROUTE,
            MEMBER_RECEIPTS_ROUTE,
            MEMBER_PLEDGES_ROUTE,
            TEMPLATES_ROUTE,
            SCREENS_ROUTE,
            ACCOUNT_ROUTE,
            ORGANIZATIONS_ROUTE,
            ORGANIZATION_DETAILS_ROUTE,
            ORGANIZATION_ACCOUNTS_ROUTE,
            ORGANIZATION_DONATIONS_ROUTE,
            ORGANIZATION_MINYANIM_ROUTE,
            ORGANIZATION_ANNOUNCEMENTS_ROUTE,
            ORGANIZATION_SHIURIMS_ROUTE,
            ORGANIZATION_SCREENS_ROUTE,
            ORGANIZATION_MEMBERS_ROUTE,
            ORGANIZATION_SYNAGOGUES_ROUTE,
            ORGANIZATION_TEMPLATES_ROUTE,
            ORGANIZATION_ALIASES_ROUTE,
            ORGANIZATION_INVOICES_ROUTE,
            ORGANIZATION_NOTIFICATIONS_ROUTE,
            ORGANIZATION_SETTINGS_ROUTE,
            ALERT_ROUTE,
            ANNOUNCEMENTS_ROUTE,
            TICKETS_ROUTE,
            MODULE_SETTING_MEMBER,
            MODULE_SETTING_SEATS,
            MODULE_SETTING_SCREENS
        }
    },
    watch: {
        errorMessage: {
            handler(val) {
                if(val != null) {
                    this.$toast.error(val ?? DEFAULT_ERROR_MSG, TOAST_GLOBAL_CONFIG);
                }
            }
            
        }
    },
    computed: {
        route() {
            return this.$route.name
        },
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        moduleSettings() {
            if (this.$store.getters.moduleSettings.data) {
                return this.$store.getters.moduleSettings.data.map((e) => e.setting)
            }
        },
        account() {
            return this.$store.getters.account.data
        }
    },
    methods: {
        routeToWebsite() {z
            window.open(WEBSITE_URL, '_blank');
        },
        logout() {
            this.$store.dispatch(LOGOUT)
        }
    }
}
</script>
<style scoped>
.sidenav {
    padding-right: 0px;
    background-color: #FFFFFF;
}

.sidenav ul {
    list-style: none;
    width: 100%;
    margin-block-start: 0px;
    padding-inline-start: 0px;
}

.sidenav ul a:hover {
    text-decoration: unset;
}

.sidenav ul .nav-items {
    font-size: 16px;
    font-weight: 700;
    margin-left: 0px;
    padding: 13px 0px 13px 28px;
    text-decoration: unset;
    color: #4A4C56!important;
    letter-spacing: 0.3px;
}

.sidenav ul .nav-items a {
    color: #4A4C56!important;
}

.router-link {
    display: flex;
    align-items: center;
}

.sidenav ul .selected {
    border-left: 6px solid #14243D;
    background-color: #D9E1F0;
    padding-left: 21px;
}

.sidenav ul .nav-items:hover {
    border-left: 6px solid #14243D;
    color: #14243D!important;
    background-color: #D9E1F0;
    cursor: pointer;
    transition: 0.1s;
}

.sidenav ul .nav-items a {
    text-decoration: unset;
}

.sidenav .selected .router-link {
    font-weight: 700;
    color: #14243D!important;
}

.sidenav .links .fa {
    font-size: 17px;
    position: relative;
    color: #808080;
    margin-left: 4px!important;
    margin-right: 12px!important;
}

.sidenav .links .selected .fa {
    color: #14243D !important;
}

.sidenav .footer {
    color: #636365;
    position: absolute;
    padding-left: 33px;
    bottom: 50px;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.3px;
}

.sidenav .footer .signed-in-user {
    font-size: 15px;
}

.sidenav .footer p {
    margin-bottom: 10px;
}

.sidenav .footer p:hover {
    cursor: pointer;
}

.sidenav .footer .fa {
    font-size: 18px;
    position: relative;
    top: 0px;
    margin-right: 8px;
}

.sidenav .info {
    color: #AEAEAE;
    padding-left: 45px;
}

.sidenav .logo-img {
    position: absolute;
    bottom: 25px;
    width: 100%;
}

.sidenav .logo-img img {
    width: 100%;
    margin-top: 15px;
    cursor: pointer;
}

.dashboard-content {
    padding-left: 0px!important;
    margin: 0px!important;
}

.footer .nav-items {
    color: #4A4C56;
}

.footer .selected {
    color: #14243D;
}

@media (max-width: 1000px) {
    .sidenav {
        padding-left: 15px;
    }

    .sidenav .links .fa {
        font-size: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
        width: 50%;
        margin: 0 auto;
    }
}

@media (min-width: 1500px) {
    .sidenav {
        padding-left: 15px;
    }
}

@media (min-width: 1000px) and (max-width: 1500px) {
    .sidenav {
        padding-left: 18px;
    }
}
</style>