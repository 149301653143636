<template>
    <div class="dashboard-body">
        <div class="row">
            <sidenav class="custom-col-width-2" />
            <div style="padding-left: 0px!important" class="custom-col-width-10 content-section dashboard-content">
                <navbar />
                <viewShiurimModal v-if="showShiurimMessageModal" :selectedShiurimToView="selectedShiurimToView"
                    @close-view-shiurim-modal="showShiurimMessageModal = false" />
                <addShiurimModal v-if="showAddShiurimModal" :organizationId="organizationId"
                    @close-add-shiurim-modal="showAddShiurimModal = false" @refresh-shiurims="getShiurims()" />
                <editShiurimModal v-if="showEditShiurimModal" :selectedShiurim="selectedShiurim"
                    @close-edit-shiurim-modal="showEditShiurimModal = false" @refresh-shiurims="getShiurims()" />
                <deleteShiurimModal v-if="showDeleteShiurimModal" :selectedShiurimId="selectedShiurimId"
                    @close-delete-shiurim-modal="showDeleteShiurimModal = false" @refresh-shiurims="getShiurims()" />
                <div class="content">
                    <br>
                    <div class="row button-grp justify-content-between">
                        <div class="col">
                            <h4>Organization</h4>
                        </div>
                        <div class="col">
                            <button v-on:click="showAddShiurimModal = true" class="btn btn-add"><i
                                    class="pi pi-plus"></i> Add
                                Shiurim</button>
                        </div>
                    </div>
                    <div class="row justify-content-between">
                        <organizationNavbar class="" />
                    </div>
                    <div class="row filter-grp">
                        <div class="col-lg-9 col-md-7">
                            <i class="pi pi-search" aria-hidden="true"></i>
                            <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
                        </div>
                        <div class="col-lg-3 col-md-5">
                            <div class="row">
                                <div class="btn col"><img src="../../assets/icons/calendar-icon.svg" alt=""> Select Date
                                </div>
                                <div class="btn col"><img src="../../assets/icons/filter-icon.svg" alt="">
                                    Filters</div>
                            </div>
                        </div>
                    </div>
                    <div v-if="shiurimsIsLoading && shiurims == null">
                        <div class="row">
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#DFDFFAFF'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                        </div>
                    </div>
                    <div v-else class="row">
                        <div class="col-lg-12 offset-lg-0">
                            <div class="card">
                                <div class="card-body">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Title</th>
                                                <th scope="col">Screen</th>
                                                <th scope="col">Display Date</th>
                                                <th scope="col">Created At</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-bind:key="index" v-for="(shiurim, index) in shiurims">
                                                <tr :class="[isOdd(index + 1) ? 'striped' : null]">
                                                    <th scope="row">{{ index + 1 }}</th>
                                                    <td>{{ shiurim.title }}</td>
                                                    <td><span v-if="shiurim.screen != null">{{
                                                            shiurim.screen.name }}</span></td>
                                                    <td>{{
                                                        $filters.getDateOnly(shiurim.shiurimDisplayStartDate)
                                                        }} <small> | </small> {{
                                                        $filters.getDateOnly(shiurim.shiurimDisplayEndDate) }}
                                                    </td>
                                                    <td>{{ $filters.getDateOnly(shiurim.createdAt) }}</td>
                                                    <td>
                                                        <i v-on:click="selectShiurimToView(shiurim)"
                                                            class="pi pi-eye text-secondary"></i>
                                                        <i v-on:click="selectShiurimToEdit(shiurim)"
                                                            class="pi pi-pencil text-primary"></i>
                                                        <i v-on:click="selectShiurimToDelete(shiurim.id)"
                                                            class="pi pi-trash text-danger"></i>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ContentLoader } from 'vue-content-loader';

import sidenav from '@/components/Sidenav.vue'
import navbar from '@/components/Navbar.vue'
import organizationNavbar from '@/components/Organization_Navbar.vue'
import addShiurimModal from '@/components/modals/Shiurim/Add_Shiurim_Modal.vue'
import editShiurimModal from '@/components/modals/Shiurim/Edit_Shiurim_Modal.vue'
import deleteShiurimModal from '@/components/modals/Shiurim/Delete_Shiurim_Modal.vue'
import viewShiurimModal from '@/components/modals/Shiurim/View_Shiurim_Modal.vue'

import { GET_SHIURIMS } from '@/store/action_types'

export default {
    title: 'Shiurims',
    components: {
        sidenav,
        navbar,
        organizationNavbar,
        addShiurimModal,
        editShiurimModal,
        deleteShiurimModal,
        viewShiurimModal,
        ContentLoader
    },
    data() {
        return {
            showAddShiurimModal: false,
            showEditShiurimModal: false,
            showDeleteShiurimModal: false,
            showShiurimMessageModal: false,
            selectedShiurim: null,
            selectedShiurimId: null,
            selectedShiurimToView: null,
            organizationId: null
        }
    },
    computed: {
        shiurimsIsLoading() {
            return this.$store.getters.shiurims.isLoading
        },
        shiurims() {
            return this.$store.getters.shiurims.data
        }
    },
    methods: {
        isOdd(number) {
            return number % 2 !== 0;
        },
        selectShiurimToView(shiurim) {
            this.selectedShiurimToView = shiurim
            this.showShiurimMessageModal = true
        },
        selectShiurimToEdit(shiurim) {
            this.selectedShiurim = shiurim
            this.showEditShiurimModal = true
        },
        selectShiurimToDelete(shiurimId) {
            this.selectedShiurimId = shiurimId
            this.showDeleteShiurimModal = true
        },
        setOrganization() {
            this.organizationId = this.$route.params.id
        },
        getShiurims() {
            this.$store.dispatch(GET_SHIURIMS, { org: this.$route.params.id })
        }
    },
    mounted() {
        this.getShiurims()
        this.setOrganization()
    }
}
</script>
<style scoped>
.content .card,
.content .card-body {
    padding: 0px;
}

.custom-col-width-2 {
    width: 16.66%;
}

.custom-col-width-10 {
    width: 83.3%;
}

@media (max-width: 1000px) {
    .custom-col-width-2 {
        width: 12%;
    }

    .custom-col-width-10 {
        width: 88%;
    }
}
</style>