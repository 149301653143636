import baseAPI from '@/api/base';
import { GenerateMemberStatementFile, GetMemberStatementFile } from '@/dtos/report/member_statement.dto';
import { GET_MEMBER_STATEMENT_URL, GENERATE_MEMBER_STATEMENT_FILE_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class MemberStatement {
    static generateMemberStatementFile = async(generateMemberStatement: GenerateMemberStatementFile) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(GENERATE_MEMBER_STATEMENT_FILE_URL, {}, {
                    params: generateMemberStatement
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default MemberStatement