import baseAPI from '@/api/base';
import { GetDonation, EditDonation, RegisterDonation } from '../dtos/donation.dto';
import { CALENDAR_DONATION_URL, DELETE_DONATION_URL, DONATION_URL, EDIT_DONATION_URL, MEMBER_DONATION_URL, REGISTER_DONATION_URL, TOGGLE_DONATION_IS_ACTIVE_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class Donation {
    static getDonations = async(donationQuery: GetDonation) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(DONATION_URL, {
                    params: donationQuery
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static getCalendarDonation = async(donationQuery: GetDonation) : Promise<AxiosResponse> => {
        return new Promise(async (resolve, reject) => {
            console.log('donationQuerydonationQuerydonationQuery', donationQuery)
            try {
                let response: AxiosResponse = await baseAPI.get(CALENDAR_DONATION_URL, {
                    params: donationQuery
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static getMemberDonations = async(donationQuery: GetDonation) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(MEMBER_DONATION_URL, {
                    params: donationQuery
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static registerDonation = async(registerDonation: RegisterDonation) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(REGISTER_DONATION_URL, {
                    ...registerDonation
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static editDonation = async(editDonation: EditDonation) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(EDIT_DONATION_URL, {
                    ...editDonation
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static toggleDonationIsActive = async(editDonation: EditDonation) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(TOGGLE_DONATION_IS_ACTIVE_URL, {
                    ...editDonation
                })
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static deleteDonation = async(donationId: string) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.delete(`${DELETE_DONATION_URL}/${donationId}`)

                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default Donation