import baseAPI from '@/api/base';
import { EditAnnouncement, GetAnnouncement, RegisterAnnouncement } from '../dtos/announcement.dto';
import { DELETE_ANNOUNCEMENT_URL, ANNOUNCEMENT_URL, EDIT_ANNOUNCEMENT_URL, ADD_ANNOUNCEMENT_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class Announcement {
    static getAnnouncements = async(getAnnouncement: GetAnnouncement) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(ANNOUNCEMENT_URL, {
                    params: getAnnouncement
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static registerAnnouncement = async(registerAnnouncement: RegisterAnnouncement) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(ADD_ANNOUNCEMENT_URL, {
                    ...registerAnnouncement
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static editAnnouncement = async(editAnnouncement: EditAnnouncement) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(EDIT_ANNOUNCEMENT_URL, {
                    ...editAnnouncement
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static deleteAnnouncement = async(donationId: string) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.delete(`${DELETE_ANNOUNCEMENT_URL}/${donationId}`)

                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default Announcement