import axios, { AxiosResponse } from 'axios'
import { SET_DONATION_REPORT_FILES, SET_DONATION_REPORT_FILE, SET_MEMBER_STATEMENT_REPORT_FILE, SET_MEMBER_STATEMENT_REPORT_FILES, SET_ERRORMSG } from '../../mutation_types'
import {
    GET_DONATION_REPORT_FILES,
    GET_MEMBER_STATEMENT_FILES,
    DELETE_REPORT_FILE,
    CLEAR_REPORT_FILES_ISLOADING
} from '../../action_types'
import { StateObject } from '@/dtos/state.dto'
import ReportFile from '@/services/report/report_files'
import { Module } from 'vuex'
import { GetReportFiles } from '@/dtos/report/report_file'
import { REPORT_FILE_TYPE_DONATION, REPORT_FILE_TYPE_MEMBER_STATEMENT } from '@/utils/const'

const reportFile: Module<any, {}> = {
    state: {
        donationReportFile: {
            data: null,
            isLoading: false
        },
        donationReportFiles: {
            data: null,
            isLoading: false
        },
        memberStatementReportFile: {
            data: null,
            isLoading: false
        },
        memberStatementReportFiles: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        donationReportFile: (state: { donationReportFile: any }) => state.donationReportFile,
        donationReportFiles: (state: { donationReportFiles: any }) => state.donationReportFiles,
        memberStatementReportFile: (state: { memberStatementReportFile: any }) => state.memberStatementReportFile,
        memberStatementReportFiles: (state: { memberStatementReportFiles: any }) => state.memberStatementReportFiles
    },
    mutations: {
        [SET_DONATION_REPORT_FILE]: (state: { donationReportFile: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.donationReportFile.data = payload.data : null, state.donationReportFile.isLoading = payload.isLoading },
        [SET_DONATION_REPORT_FILES]: (state: { donationReportFiles: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.donationReportFiles.data = payload.data : null, state.donationReportFiles.isLoading = payload.isLoading },
        [SET_MEMBER_STATEMENT_REPORT_FILE]: (state: { memberStatementReportFile: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.memberStatementReportFile.data = payload.data : null, state.memberStatementReportFile.isLoading = payload.isLoading },
        [SET_MEMBER_STATEMENT_REPORT_FILES]: (state: { memberStatementReportFiles: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.memberStatementReportFiles.data = payload.data : null, state.memberStatementReportFiles.isLoading = payload.isLoading }
    },
    actions: {
        [GET_DONATION_REPORT_FILES]: async({ commit }: any) => {
            commit(SET_DONATION_REPORT_FILES, { data: null, isLoading: true })

            let result: AxiosResponse = await ReportFile.getReportFiles({ type: REPORT_FILE_TYPE_DONATION } as GetReportFiles)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_DONATION_REPORT_FILES, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_DONATION_REPORT_FILES, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_DONATION_REPORT_FILES, { isLoading: false })
                return false;
            }
        },
        [GET_MEMBER_STATEMENT_FILES]: async({ commit }: any, payload: any) => {
            commit(SET_MEMBER_STATEMENT_REPORT_FILES, { data: null, isLoading: true })

            let result: AxiosResponse = await ReportFile.getReportFiles({ type: REPORT_FILE_TYPE_MEMBER_STATEMENT } as GetReportFiles)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_MEMBER_STATEMENT_REPORT_FILES, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_MEMBER_STATEMENT_REPORT_FILES, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_MEMBER_STATEMENT_REPORT_FILES, { isLoading: false })
                return false;
            }
        },
        [DELETE_REPORT_FILE]: async({ commit }: any, payload: any) => {
            commit(SET_DONATION_REPORT_FILE, { data: null, isLoading: true })

            let result: AxiosResponse = await ReportFile.deleteReportFile(payload)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_DONATION_REPORT_FILE, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_DONATION_REPORT_FILE, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_DONATION_REPORT_FILE, { isLoading: false })
                return false;
            }
        },
        [CLEAR_REPORT_FILES_ISLOADING]: ({ commit }) => {
            commit(SET_DONATION_REPORT_FILE, { isLoading: false })
            commit(SET_DONATION_REPORT_FILES, { isLoading: false })
        }
    }
}

export default reportFile