import axios, { AxiosResponse } from 'axios'
import { SET_MINYANIMS, SET_MINYANIM, SET_ERRORMSG, SET_DISPLAY_MINYANIMS } from '../mutation_types'
import {
    CLEAR_MINYANIMS_ISLOADING,
    DELETE_MINYANIM,
    EDIT_MINYANIM,
    GET_MINYANIMS,
    GET_DISPLAY_MINYANIMS,
    ADD_MINYANIM,
    TOGGLE_MINYANIM_IS_ACTIVE,
} from '../action_types'
import { StateObject } from '@/dtos/state.dto'
import Minyanim from '@/services/minyanim'
import { Module } from 'vuex'
import { EditMinyanim, AddMinyanim, GetMinyanim } from '@/dtos/minyanim.dto'

const minyanim: Module<any, {}> = {
    state: {
        minyanim: {
            data: null,
            isLoading: false
        },
        minyanims: {
            data: null,
            isLoading: false
        },
        displayMinyanims: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        minyanim: (state: { minyanim: any }) => state.minyanim,
        minyanims: (state: { minyanims: any }) => state.minyanims,
        displayMinyanims: (state: { displayMinyanims: any }) => state.displayMinyanims
    },
    mutations: {
        [SET_MINYANIM]: (state: { minyanim: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.minyanim.data = payload.data : null, state.minyanim.isLoading = payload.isLoading },
        [SET_MINYANIMS]: (state: { minyanims: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.minyanims.data = payload.data : null, state.minyanims.isLoading = payload.isLoading },
        [SET_DISPLAY_MINYANIMS]: (state: { displayMinyanims: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.displayMinyanims.data = payload.data : null, state.displayMinyanims.isLoading = payload.isLoading }
    },
    actions: {
        [ADD_MINYANIM]: async({ commit }: any, payload: any) => {
            commit(SET_MINYANIM, { data: null, isLoading: true })

            let result: AxiosResponse = await Minyanim.registerMinyanim(payload as AddMinyanim)
          
            if(result) {
                if (result.data.status) {
                    commit(SET_MINYANIM, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_MINYANIM, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_MINYANIM, { isLoading: false })
                return false;
            }
        },
        [GET_MINYANIMS]: async({ commit }: any, payload: any) => {
            commit(SET_MINYANIMS, { data: null, isLoading: true })

            let result: AxiosResponse = await Minyanim.getMinyanims(payload as GetMinyanim)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_MINYANIMS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_MINYANIMS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_MINYANIMS, { isLoading: false })
                return false;
            }
        },
        [GET_DISPLAY_MINYANIMS]: async({ commit }: any, payload: any) => {
            commit(SET_DISPLAY_MINYANIMS, { data: null, isLoading: true })

            let result: AxiosResponse = await Minyanim.getDisplayMinyanims(payload as GetMinyanim)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_DISPLAY_MINYANIMS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_DISPLAY_MINYANIMS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_DISPLAY_MINYANIMS, { isLoading: false })
                return false;
            }
        },
        [EDIT_MINYANIM]: async({ commit }: any, payload: any) => {
            commit(SET_MINYANIM, { data: null, isLoading: true })

            let result: AxiosResponse = await Minyanim.editMinyanim(payload as EditMinyanim)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_MINYANIM, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_MINYANIM, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_MINYANIM, { isLoading: false })
                return false;
            }
        },
        [TOGGLE_MINYANIM_IS_ACTIVE]: async({ commit, state }: any, payload: any) => {
            commit(SET_MINYANIM, { data: null, isLoading: true })

            let result: AxiosResponse = await Minyanim.toggleMinyanimIsActive(payload as EditMinyanim)
          
            if(result) {
                if (result.data.status) {
                    let index
                    state.minyanims.data.forEach((e: any, i: number) => {
                        if (e.id == result.data.data.id) {
                            index = i
                        }
                    })
                    if(index != null) state.minyanims.data[index].isActive = result.data.data.isActive

                    commit(SET_MINYANIM, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_MINYANIM, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_MINYANIM, { isLoading: false })
                return false;
            }
        },
        [DELETE_MINYANIM]: async({ commit }: any, payload: string) => {
            commit(SET_MINYANIM, { data: null, isLoading: true })

            let result: AxiosResponse = await Minyanim.deleteMinyanim(payload)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_MINYANIM, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_MINYANIM, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_MINYANIM, { isLoading: false })
                return false;
            }
        },
        [CLEAR_MINYANIMS_ISLOADING]: ({ commit }) => {
            commit(SET_MINYANIM, { isLoading: false })
            commit(SET_MINYANIMS, { isLoading: false })
        }
    }
}

export default minyanim