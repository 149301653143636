<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeMemberStatementFilesModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Generated Member Statement Files</span>
            <br>
            <div class="modal__content">
                <deleteMemberStatementFileModal v-if="showDeleteMemberStatementFileModal" :selectedMemberStatementFileId="selectedMemberStatementFileId"
                    @close-delete-member-statement-file-modal="showDeleteMemberStatementFileModal = false"
                    @refresh-member-statement-files="getMemberStatementFiles()" />
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Generated At</th>
                                            <th scope="col">File</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-bind:key="index" v-for="(file, index) in memberStatementReportFiles">
                                            <tr>
                                                <th scope="row">{{ index + 1 }}</th>
                                                <td>{{ $filters.getDateAndTime(file.createdAt) }}</td>
                                                <td><span class="open-file" v-on:click="viewMemberStatementFile(file)"><i
                                                            class="pi pi-external-link text-primary"></i></span></td>
                                                <td><i v-on:click="deleteMemberStatementFile(file.id)"
                                                        class="pi pi-trash text-danger"></i></td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import deleteMemberStatementFileModal from '@/components/modals/Member_Statement/Delete_Member_Statement_File_Modal.vue'

import { GET_MEMBER_STATEMENT_FILES } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';
import { REPORT_FILE_TYPE_DONATION, STORAGE_URL } from '@/utils/const';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
    },
    components: {
        deleteMemberStatementFileModal,
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage
    },
    data() {
        return {
            showModal: true,
            showDeleteMemberStatementFileModal: false,
            selectedMemberStatementFileId: null
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        memberStatementReportFiles() {
            return this.$store.getters.memberStatementReportFiles.data
        },
        memberStatementReportFilesIsLoading() {
            return this.$store.getters.memberStatementReportFiles.isLoading
        },
        account() {
            return this.$store.getters.account.data
        }
    },
    methods: {
        viewMemberStatementFile(file) {
            window.open(`${STORAGE_URL}/${file.file}`, '_blank');
        },
        deleteMemberStatementFile(fileId) {
            this.showDeleteMemberStatementFileModal = true
            this.selectedMemberStatementFileId = fileId
        },
        getMemberStatementFiles() {
            this.$store.dispatch(GET_MEMBER_STATEMENT_FILES, { type: REPORT_FILE_TYPE_DONATION, org: this.account.org })
        }
    },
    mounted() {
        this.getMemberStatementFiles()
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    position: relative;
    left: 30%;
    margin-top: 60px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
    max-height: 96vh;
    overflow-x: auto;
    overflow-y: auto;
}

::v-deep .modal__content {
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.open-file .pi {}

.pi-trash {
    margin-left: 15px;
}
</style>