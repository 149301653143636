import baseAPI from '@/api/base';
import { GetInvoiceItemPaymentLog } from '../dtos/invoice_item_purchase_log';
import { INVOICE_ITEM_PAYMENT_LOG_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class InvoiceItemPaymentLog {
    static getInvoiceItemPaymentLogs = async(getInvoiceItemPaymentLog: GetInvoiceItemPaymentLog) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(INVOICE_ITEM_PAYMENT_LOG_URL, {
                    params: getInvoiceItemPaymentLog
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default InvoiceItemPaymentLog