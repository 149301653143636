import baseAPI from '@/api/base';
import { GetReceipt } from '../dtos/receipt.dto';
import { RECEIPT_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class Receipt {
    static getReceipts = async(getReceipt: GetReceipt) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(RECEIPT_URL, {
                    params: getReceipt
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default Receipt