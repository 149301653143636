import axios, { AxiosResponse } from 'axios'
import { SET_TICKET_COMMENTS, SET_TICKET_COMMENT, SET_ERRORMSG } from '../mutation_types'
import {
    CLEAR_TICKET_COMMENTS_ISLOADING,
    DELETE_TICKET_COMMENT,
    EDIT_TICKET_COMMENT,
    GET_TICKET_COMMENTS,
    ADD_TICKET_COMMENT,
    CLEAR_TICKET_COMMENTS,
} from '../action_types'
import { StateObject } from '@/dtos/state.dto'
import TicketComment from '@/services/ticket_comment'
import { Module } from 'vuex'
import { EditTicketComment, GetTicketComment, AddTicketComment } from '@/dtos/ticket_comment.dto'

const ticketComment: Module<any, {}> = {
    state: {
        ticketComment: {
            data: null,
            isLoading: false
        },
        ticketComments: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        ticketComment: (state: { ticketComment: any }) => state.ticketComment,
        ticketComments: (state: { ticketComments: any }) => state.ticketComments
    },
    mutations: {
        [SET_TICKET_COMMENT]: (state: { ticketComment: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.ticketComment.data = payload.data : null, state.ticketComment.isLoading = payload.isLoading },
        [SET_TICKET_COMMENTS]: (state: { ticketComments: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.ticketComments.data = payload.data : null, state.ticketComments.isLoading = payload.isLoading }
    },
    actions: {
        [ADD_TICKET_COMMENT]: async({ commit }: any, payload: any) => {
            commit(SET_TICKET_COMMENT, { data: null, isLoading: true })

            let result: AxiosResponse = await TicketComment.registerTicketComment(payload as AddTicketComment)
          
            if(result) {
                if (result.data.status) {
                    commit(SET_TICKET_COMMENT, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_TICKET_COMMENT, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_TICKET_COMMENT, { isLoading: false })
                return false;
            }
        },
        [GET_TICKET_COMMENTS]: async({ commit }: any, payload: any) => {
            commit(SET_TICKET_COMMENTS, { data: null, isLoading: true })

            let result: AxiosResponse = await TicketComment.getTicketComment(payload as GetTicketComment)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_TICKET_COMMENTS, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_TICKET_COMMENTS, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_TICKET_COMMENTS, { isLoading: false })
                return false;
            }
        },
        [EDIT_TICKET_COMMENT]: async({ commit }: any, payload: any) => {
            commit(SET_TICKET_COMMENT, { data: null, isLoading: true })

            let result: AxiosResponse = await TicketComment.editTicketComment(payload as EditTicketComment)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_TICKET_COMMENT, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_TICKET_COMMENT, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_TICKET_COMMENT, { isLoading: false })
                return false;
            }
        },
        [DELETE_TICKET_COMMENT]: async({ commit }: any, payload: string) => {
            commit(SET_TICKET_COMMENT, { data: null, isLoading: true })

            let result: AxiosResponse = await TicketComment.deleteTicketComment(payload)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_TICKET_COMMENT, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_TICKET_COMMENT, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_TICKET_COMMENT, { isLoading: false })
                return false;
            }
        },
        [CLEAR_TICKET_COMMENTS]: ({ commit }) => {
            commit(SET_TICKET_COMMENT, { data: null, isLoading: false })
            commit(SET_TICKET_COMMENTS, { data: [], isLoading: false })
        },
        [CLEAR_TICKET_COMMENTS_ISLOADING]: ({ commit }) => {
            commit(SET_TICKET_COMMENT, { isLoading: false })
            commit(SET_TICKET_COMMENTS, { isLoading: false })
        }
    }
}

export default ticketComment