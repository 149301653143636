import baseAPI from '@/api/base';
import { EditSynagogueSeatPriceCategory, AddSynagogueSeatPriceCategory, GetSynagogueSeatPriceCategory } from '../dtos/synagogue_seat_price_category';
import { DELETE_SYNAGOGUE_SEAT_PRICE_CATEGORY_URL, SYNAGOGUE_SEAT_PRICE_CATEGORY_URL, EDIT_SYNAGOGUE_SEAT_PRICE_CATEGORY_URL, ADD_SYNAGOGUE_SEAT_PRICE_CATEGORY_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class SynagogueSeatPriceCategory {
    static getSynagogueSeatPriceCategories = async(getSynagogueSeatPriceCategory: GetSynagogueSeatPriceCategory) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(SYNAGOGUE_SEAT_PRICE_CATEGORY_URL, {
                    params: getSynagogueSeatPriceCategory
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static registerSynagogueSeatPriceCategory = async(addSynagogueSeatPriceCategory: AddSynagogueSeatPriceCategory) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(ADD_SYNAGOGUE_SEAT_PRICE_CATEGORY_URL, {
                    ...addSynagogueSeatPriceCategory
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static editSynagogueSeatPriceCategory = async(editSynagogueSeatPriceCategory: EditSynagogueSeatPriceCategory) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(EDIT_SYNAGOGUE_SEAT_PRICE_CATEGORY_URL, {
                    ...editSynagogueSeatPriceCategory
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static deleteSynagogueSeatPriceCategory = async(seatPriceCategoryId: string) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.delete(`${DELETE_SYNAGOGUE_SEAT_PRICE_CATEGORY_URL}/${seatPriceCategoryId}`)

                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default SynagogueSeatPriceCategory