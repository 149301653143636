import axios, { AxiosResponse } from 'axios'
import { SET_INVOICES, SET_INVOICE, SET_ERRORMSG, SET_MEMBER_INVOICES } from '../mutation_types'
import {
    CLEAR_INVOICES_ISLOADING,
    DELETE_INVOICE,
    EDIT_INVOICE,
    GET_INVOICES,
    GET_MEMBER_INVOICES,
    PAY_INVOICE,
    REGISTER_INVOICE,
} from '../action_types'
import { StateObject } from '@/dtos/state.dto'
import Invoice from '@/services/invoice'
import { Module } from 'vuex'
import { EditInvoice, RegisterInvoice, GetInvoice, PayInvoice } from '@/dtos/invoice.dto'

const invoice: Module<any, {}> = {
    state: {
        invoice: {
            data: null,
            isLoading: false
        },
        invoices: {
            data: null,
            isLoading: false
        },
        memberInvoices: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        invoice: (state: { invoice: any }) => state.invoice,
        invoices: (state: { invoices: any }) => state.invoices,
        memberInvoices: (state: { memberInvoices: any }) => state.memberInvoices
    },
    mutations: {
        [SET_INVOICE]: (state: { invoice: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.invoice.data = payload.data : null, state.invoice.isLoading = payload.isLoading },
        [SET_INVOICES]: (state: { invoices: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.invoices.data = payload.data : null, state.invoices.isLoading = payload.isLoading },
        [SET_MEMBER_INVOICES]: (state: { memberInvoices: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.memberInvoices.data = payload.data : null, state.memberInvoices.isLoading = payload.isLoading }
    },
    actions: {
        [REGISTER_INVOICE]: async({ commit }: any, payload: any) => {
            commit(SET_INVOICE, { data: null, isLoading: true })

            let result: AxiosResponse = await Invoice.registerInvoice(payload as RegisterInvoice)
          
            if(result) {
                if (result.data.status) {
                    commit(SET_INVOICE, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_INVOICE, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_INVOICE, { isLoading: false })
                return false;
            }
        },
        [GET_INVOICES]: async({ commit }: any, payload: any) => {
            commit(SET_INVOICES, { data: null, isLoading: true })

            let result: AxiosResponse = await Invoice.getInvoices(payload as GetInvoice)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_INVOICES, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_INVOICES, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_INVOICES, { isLoading: false })
                return false;
            }
        },
        [GET_MEMBER_INVOICES]: async({ commit }: any, payload: any) => {
            commit(SET_MEMBER_INVOICES, { data: null, isLoading: true })

            let result: AxiosResponse = await Invoice.getMemberInvoices(payload as GetInvoice)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_MEMBER_INVOICES, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_MEMBER_INVOICES, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_MEMBER_INVOICES, { isLoading: false })
                return false;
            }
        },
        [EDIT_INVOICE]: async({ commit }: any, payload: any) => {
            commit(SET_INVOICE, { data: null, isLoading: true })

            let result: AxiosResponse = await Invoice.editInvoice(payload as EditInvoice)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_INVOICE, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_INVOICE, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_INVOICE, { isLoading: false })
                return false;
            }
        },
        [PAY_INVOICE]: async({ commit }: any, payload: any) => {
            commit(SET_INVOICE, { data: null, isLoading: true })

            let result: AxiosResponse = await Invoice.payInvoice(payload as PayInvoice)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_INVOICE, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_INVOICE, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_INVOICE, { isLoading: false })
                return false;
            }
        },
        [DELETE_INVOICE]: async({ commit }: any, payload: string) => {
            commit(SET_INVOICE, { data: null, isLoading: true })

            let result: AxiosResponse = await Invoice.deleteInvoice(payload)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_INVOICE, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_INVOICE, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_INVOICE, { isLoading: false })
                return false;
            }
        },
        [CLEAR_INVOICES_ISLOADING]: ({ commit }) => {
            commit(SET_INVOICE, { isLoading: false })
            commit(SET_INVOICES, { isLoading: false })
        }
    }
}

export default invoice