<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeEditSynagogueModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Edit Shul Seats</span>
            <div class="modal__content">
                <p v-if="errorMessage" class="text-center text-danger auth-status-msg">{{ errorMessage }}</p>
                <div class="card">
                    <div class="card-body">
                        <Form @submit="editSynagogue">
=                            <div class="row">
                                <div class="col-12">
                                    <div class="row justify-content-between">
                                        <div class="col-4 floor-plan-col">
                                            <div class="form-group">
                                                <label for="name">Shul Name</label>
                                                <Field v-model="synagogueObj.name" rules="required" type="text"
                                                    name="name" class="form-control" id="name" placeholder="Name" />
                                                <ErrorMessage name="name" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-md-auto zoom-controls">
                                            <button @click="zoomIn" class="btn"><font-awesome-icon
                                                    icon="fa-solid fa-magnifying-glass-plus" class="fa" /></button>
                                            <button @click="zoomOut" class="btn"><font-awesome-icon
                                                    icon="fa-solid fa-magnifying-glass-minus" class="fa" /></button>
                                        </div>
                                    </div>
                                    <div class="row justify-content-end modal__actions">
                                        <button class="col-1 btn btn-save" :disabled="synagogueIsLoading ? true : false">
                                            <span v-if="synagogueIsLoading" class="spinner-border spinner-border-sm"
                                                role="status" aria-hidden="true"></span>
                                            <span v-if="!synagogueIsLoading"> Save</span>
                                        </button>
                                    </div>
                                    <hr>
                                    <div id="seats" class="">
                                        <label for="name">Design your floor plan</label>
                                        <div :style="zoomStyle">
                                            <div v-for="(row, rowIndex) in matrix" :key="rowIndex" class="row">
                                                <div class="col p-1" v-for="(value, colIndex) in row" :key="colIndex">
                                                    <button type="button" class="btn btn-block"
                                                        :class="{ 'btn-seat-selected': isSelected(rowIndex, colIndex), 'btn-seat-not-selected': !isSelected(rowIndex, colIndex) }"
                                                        @click="toggleSelection(rowIndex, colIndex)">
                                                        {{ value }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import { EDIT_SYNAGOGUE, GET_SYNAGOGUE_SEATS } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';
import { SEATS_MATRIX } from '@/utils/const';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faMagnifyingGlassPlus, faMagnifyingGlassMinus } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
library.add(faMagnifyingGlassPlus, faMagnifyingGlassMinus)

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        selectedSynagogue: Object
    },
    components: {
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage
    },
    data() {
        return {
            SEATS_MATRIX,
            synagogueObj: {
                id: null,
                name: null,
                rows: null,
                cols: null,
                seats: []
            },
            rows: 20,
            cols: 20,
            matrix: [],
            selected: [],
            showModal: true,
            zoomLevel: 1
        }
    },
    watch: {
        rows(newValue, oldValue) {
            this.generateMatrix();
        },
        cols(newValue, oldValue) {
            this.generateMatrix();
        },
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        synagogue() {
            return this.$store.getters.synagogue.data
        },
        synagogueIsLoading() {
            return this.$store.getters.synagogue.isLoading
        },
        synagogueSeats() {
            return this.$store.getters.synagogueSeats.data
        },
        synagogueSeatsIsLoading() {
            return this.$store.getters.synagogueSeats.isLoading
        },
        zoomStyle() {
            return {
                transform: `scale(${this.zoomLevel})`,
                transformOrigin: '0% 0%', // Anchor zoom to the top-left corner
                transition: 'transform 0.3s ease'
            };
        },
    },
    methods: {
        zoomIn() {
            this.zoomLevel *= 1.1; // Increase the zoom level by 10%
        },
        zoomOut() {
            this.zoomLevel /= 1.1; // Decrease the zoom level by 10%
        },
        generateMatrix() {
            this.matrix = []
            let value = 1;
            for (let i = 0; i < this.rows; i++) {
                let row = [];
                for (let j = 0; j < this.cols; j++) {
                    row.push(value);
                    value++;
                }
                this.matrix.push(row);
            }
            // return this.matrixkjhgyf;
        },
        toggleSelection(rowIndex, colIndex) {
            const index = this.selected.findIndex(item => item.row === rowIndex && item.col === colIndex);
            if (index === -1) {
                this.selected.push({ row: rowIndex, col: colIndex });
            } else {
                this.selected.splice(index, 1);
            }
        },
        isSelected(rowIndex, colIndex) {
            return this.selected.some(item => item.row === rowIndex && item.col === colIndex);
        },
        setValues() {
            this.synagogueObj.id = this.selectedSynagogue.id
            this.synagogueObj.name = this.selectedSynagogue.name
            this.synagogueObj.rows = this.selectedSynagogue.rows
            this.synagogueObj.cols = this.selectedSynagogue.cols
            this.rows = this.selectedSynagogue.rows
            this.cols = this.selectedSynagogue.cols
        },
        setSelectedSeats() {
            this.selected = this.synagogueSeats.map((e) => {
                const seat = {
                    row: e.rowIndex,
                    col: e.colIndex
                };
                return seat;
            })
        },
        getSynagogueSeats() {
            this.$store.dispatch(GET_SYNAGOGUE_SEATS, { syng: this.selectedSynagogue.id }).then((res) => {
                this.setSelectedSeats()
            })
        },
        editSynagogue() {
            this.synagogueObj.seats = this.selected
            
            this.$store.dispatch(EDIT_SYNAGOGUE, this.synagogueObj).then((res) => {
                if (res) {
                    this.getSynagogueSeats()
                    this.$emit('closeEditSynagogueModal')
                    this.$emit('refreshSynagogues')
                }
            })
        }
    },
    mounted() {
        this.setValues()
        this.generateMatrix()
        this.getSynagogueSeats()
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;  justify-content: center;
    align-items: center;
    width: 98%;
    position: relative;
    left: 1%;    
    margin-top: 20px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
    max-height: 96vh; /* Set a maximum height for the modal content */
    overflow-y: auto;
}

::v-deep .modal__content {
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
}

#seats .row {
    width: 15000px;
    overflow-x: scroll;
}

#seats .row .col {
    max-width: 67px !important;
}

.modal__content .zoom-controls {
    position: relative;
    top: 25px;
}

.modal__content .zoom-controls .btn {
    font-size: 17px;
    color: #6489C4;
}

.modal__content .zoom-controls .btn:hover {
    color: #14243D;
}

.modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    margin-top: 0px;
    margin-right: 15px !important;
}

.modal__title {
    font-size: 18px;
    font-weight: 600;
}

.modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}

.modal__content .nav-tabs .nav-link {
    font-size: 15px !important;
}

.modal__content .btn-seat-not-selected {
    font-size: 10px;
    font-weight: 700;
    background-color: #ededf1;
}

.modal__content .btn-seat-selected {
    font-size: 10px;
    font-weight: 700;
    background-color: #6489C4;
    color: #FFFFFF;
}

::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
* {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.floor-plan-col {
    margin-left: 0px;
    padding-left: 0px;
}

hr {
    border: 1px solid #EDEDF1;
}

.btn-save {
    margin-top: 10px;
    background-color: #14243D;
}

.appointment-card {
    margin-top: 5px;
}

.appointment-card .card-body {
    padding: 0px 5px 0px 5px;
}

.appointment-card .customer {
    font-size: 15px;
}

.appointment-card .badge {
    margin-left: 10px;
    margin-right: 5px;
}

label {
    font-size: 13px;
}

input {
    height: 35px !important;
    font-size: 13px;
}

input::placeholder {
    font-size: 13px;
}

select {
    height: 35px !important;
    font-size: 13px;
}

select::placeholder {
    font-size: 13px;
}

.form-check {
    position: relative;
    display: block;
    margin-top: 25px;
    margin-bottom: 0.75rem;
    padding-left: 0px;
}

.form-check-toggle {
    position: relative;
    padding-left: 0;
    line-height: 30px;
}

.form-check-toggle input {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 0%;
    margin: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
}

.form-check-toggle input+span {
    cursor: pointer;
    user-select: none;
    height: 30px;
    margin-left: 70px;
    display: block;
}

.form-check-toggle input+span:before {
    content: "";
    position: absolute;
    left: 0;
    display: inline-block;
    height: 30px;
    width: 50px;
    background: #F0F5FC;
    border: solid 1px #D4D3D3;
    transition: background 0.3s ease-in-out, border-color 0.3s ease-in-out;
    border-radius: 7px;
}

.form-check-toggle input+span:after {
    width: 28px;
    height: 28px;
    margin-top: 1px;
    margin-left: 1px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    background: #fff;
    transition: margin-left 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    text-align: center;
    font-weight: bold;
    content: "";
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2), 0 0 5px rgba(0, 0, 0, 0.05);
}

.form-check-toggle input:checked+span:after {
    content: "";
    margin-left: 21px;
    box-shadow: none;
}

.form-check-toggle input:checked+span:before {
    background-color: #CAB781;
    border-color: #CAB781;
    transition: background 0.3s ease-in-out, border-color 0.3s ease-in-out;
}

button {
    font-size: 15px;
}
</style>