<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeEditPledgeModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Edit Pledge</span>
            <br>
            <div class="modal__content">
                <JewishDatePicker v-if="showJewishDatePicker" @select-date="selectPledgeItemPledgeDate"
                    @close-date-picker-modal="closeModal()" />
                <div class="row">
                    <div class="col-12">
                        <p v-if="errorMessage" class="text-center text-danger auth-status-msg">{{ errorMessage }}</p>
                        <Form @submit="registerPledge">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="title">Items</h5>
                                    <div class="row">
                                        <div class="col-10">
                                            <div class="row">
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <label for="name">Name</label>
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <label for="price">Unit Price</label>
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <label for="quatity">Quantity</label>
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <label for="pledgeDate">Pledge Date</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2">
                                            <div class="form-group">
                                                <label for="amount">Total</label>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <template v-bind:key="index" v-for="(item, index) in pledgeItem"> -->
                                    <div class="row invoice-item">
                                        <div class="col-10">
                                            <div class="row">
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <select v-model="editPledgeItemObj.pldgType"
                                                            :name="`invoiceItemType${index}`" class="form-control"
                                                            rules="required">
                                                            <option>---</option>
                                                            <template v-bind:key="index" v-for="(type, index) in pledgeTypes.map(({ user, ...rest }) => rest)">
                                                                <option :value="type">{{ type.name }}
                                                                </option>
                                                            </template>
                                                        </select>
                                                        <ErrorMessage :pldgType="`invoiceItemType${index}`"
                                                            class="form-text text-danger" />
                                                        <!-- <label for="name">Name</label> -->
                                                        <!-- <Field v-model="editPledgeItemObj.name" rules="required"
                                                            type="text" :name="`invoiceItemName${index}`"
                                                            class="form-control" id="name" placeholder="Item Name" />
                                                        <ErrorMessage :name="`invoiceItemName${index}`"
                                                            class="form-text text-danger" /> -->
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <!-- <label for="amount">Unit Price</label> -->
                                                        <Field v-model="editPledgeItemObj.price" rules="required"
                                                            type="number" min="0.1" step="0.01"
                                                            :name="`invoiceItemPrice${index}`" class="form-control"
                                                            id="amount" placeholder="0.00" />
                                                        <ErrorMessage :name="`invoiceItemPrice${index}`"
                                                            class="form-text text-danger" />
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <!-- <label for="amount">Quantity</label> -->
                                                        <Field v-model="editPledgeItemObj.quantity" rules="required"
                                                            type="number" min="1" step="1"
                                                            :name="`invoiceItemQuantity${index}`" class="form-control"
                                                            id="amount" placeholder="0" />
                                                        <ErrorMessage :name="`invoiceItemQuantity${index}`"
                                                            class="form-text text-danger" />
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div @click="showPledgeItemDueDatePicker(index)" class="form-group">
                                                        <!-- <label for="donationDisplayEndDate">Display End Date</label> -->
                                                        <div class="row date-picker-row">
                                                            <div class="col-10">
                                                                <Field v-model="editPledgeItemObj.hebrewPledgeDate"
                                                                    rules="required" disabled type="text"
                                                                    :name="`pledgeDate${index}`" class="form-control"
                                                                    id="itemDueDate" placeholder="Pledge Date" />
                                                            </div>
                                                            <div class="col-2">
                                                                <i class="pi pi-calendar-plus text-primary"></i>
                                                            </div>
                                                        </div>
                                                        <ErrorMessage :name="`pledgeDate${index}`"
                                                            class="form-text text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-1">
                                            <small>{{ $filters.formatPrice(editPledgeItemObj.price *
                                                editPledgeItemObj.quantity, editPledgeObj.currency ?? 'USD') }}</small>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label for="reason">Reason</label>
                                                <Field v-slot="{ field, errors }" v-model="editPledgeItemObj.reason"
                                                    rules="" name="details" class="form-control">
                                                    <textarea v-bind="field" name="reason" class="form-control" rows="5"
                                                        placeholder="Enter any notes related to this edit..." />
                                                    <ErrorMessage name="reason" class="form-text text-danger" />
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- </template> -->
                                    <!-- <a v-on:click="addPledgeItem()" class="btn btn-add-new-item">Add New Item</a> -->
                                </div>
                            </div>
                            <div class="row justify-content-end modal__actions">
                                <button v-on:click="closePledgeModal()" class="col-1 btn btn-close">
                                    <img src="../../../assets/icons/close-icon.svg" alt=""> Cancel
                                </button>
                                <button type="submit" class="col-1 btn btn-save"
                                    :disabled="pledgeItemIsLoading ? true : false">
                                    <span v-if="pledgeItemIsLoading" class="spinner-border spinner-border-sm"
                                        role="status" aria-hidden="true"></span>
                                    <span v-if="!pledgeItemIsLoading"><img src="../../../assets/icons/save-icon.svg"
                                            alt=""> Save</span>
                                </button>
                            </div>
                        </Form>
                    </div>
                    <!-- <div class="col-6">
                        <br>
                        <div class="card">
                            <div class="card-body">
                                <span v-html="renderContent(editPledgeObj.title, editPledgeObj)"></span>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import JewishDatePicker from '@/components/JewishDatePicker.vue';

import { EDIT_PLEDGE_ITEM, GET_PLEDGE_TYPES } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';
import { DATE_PICKER_DONATION_DATE, DATE_PICKER_DONATION_DISPLAY_START_DATE, DATE_PICKER_INVOICE_ITEM_DUE_DATE, USER_ROLE_ADMIN } from '@/utils/const';
import { CURRENCIES } from '@/utils/const';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        selectedPledgeItem: Object
    },
    components: {
        JewishDatePicker,
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage
    },
    data() {
        return {
            DATE_PICKER_DONATION_DATE,
            DATE_PICKER_DONATION_DISPLAY_START_DATE,
            DATE_PICKER_INVOICE_ITEM_DUE_DATE,
            CURRENCIES,
            selectedPledgeItemIndex: 0,
            showJewishDatePicker: false,
            showModal: true,
            showAddMemberModal: false,
            editPledgeObj: {
                pledgeItem: null,
                memb: null,
                org: null
            },
            editPledgeItemObj: {
                id: null,
                pldgType: null,
                quantity: 1,
                price: null,
                pledgeDate: null,
                hebrewPledgeDate: null,
                reason: null
            },
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        account() {
            return this.$store.getters.account.data
        },
        invoice() {
            return this.$store.getters.invoice.data
        },
        invoiceIsLoading() {
            return this.$store.getters.invoice.isLoading
        },
        pledgeItem() {
            return this.$store.getters.pledgeItem.data
        },
        pledgeItemIsLoading() {
            return this.$store.getters.pledgeItem.isLoading
        },
        pledgeTypes() {
            return this.$store.getters.pledgeTypes.data
        },
        pledgeTypesIsLoading() {
            return this.$store.getters.pledgeTypes.isLoading
        },
        account() {
            return this.$store.getters.account.data
        },
    },
    methods: {
        showPledgeItemDueDatePicker(index) {
            this.selectedPledgeItemIndex = index
            this.showJewishDatePicker = true
        },
        selectPledgeItemPledgeDate(selectedDate) {
            this.editPledgeItemObj.pledgeDate = this.$filters.convertHDateToGregorian(selectedDate)
            this.editPledgeItemObj.hebrewPledgeDate = this.$filters.convertToHebrewDate(this.editPledgeItemObj.pledgeDate)
        },
        setValues() {
            this.editPledgeItemObj.id = this.selectedPledgeItem.id
            this.editPledgeItemObj.pldgType = this.selectedPledgeItem.pledgeType
            this.editPledgeItemObj.quantity = this.selectedPledgeItem.quantity
            this.editPledgeItemObj.price = this.selectedPledgeItem.price
            this.editPledgeItemObj.pledgeDate = this.selectedPledgeItem.pledgeDate
            this.editPledgeItemObj.hebrewPledgeDate = this.$filters.convertToHebrewDate(this.selectedPledgeItem.pledgeDate)
        },
        closeModal() {
            this.showJewishDatePicker = false;
        },
        closePledgeModal() {
            this.$emit('closeEditPledgeModal')
        },
        caluclateTotalAmount() {
            return this.pledgeItem.reduce((total, item) => {
                return total + (item.price * item.quantity);
            }, 0);
        },
        getPledgeTypes() {
            this.$store.dispatch(GET_PLEDGE_TYPES, { org: this.organizationId ?? this.account.org })
        },
        registerPledge() {
            // this.editPledgeObj.pledgeItem = this.pledgeItem.map((e) => {
            //     const updatedItem = {
            //         ...e,
            //         pledgeDate: this.$filters.convertHDateToGregorian(e.pledgeDate)
            //     };
            //     return updatedItem;
            // })

            // if (this.memberId == null) {
            //     this.editPledgeObj.memb = this.editPledgeObj.memb.id
            // }

            this.editPledgeItemObj.pldgType = this.editPledgeItemObj.pldgType.id

            this.$store.dispatch(EDIT_PLEDGE_ITEM, this.editPledgeItemObj).then((result) => {
                if (result) {
                    this.$emit('closeEditPledgeModal')
                    this.$emit('refreshPledges')
                }
            })
        },

    },
    created() {
        this.getPledgeTypes()
        this.setValues()
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    position: relative;
    left: 5%;
    margin-top: 50px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
}

.modal__content .nav-tabs .nav-link {
    font-size: 15px !important;
}

.btn-add-new-item {
    border-radius: 8px;
    font-size: 13px;
    margin-top: 10px;
    color: #14243D !important;
    background-color: transparent;
    border: 1px solid #14243D;
}

.invoice-item {
    height: 50px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.add-new-badge {
    font-size: 10px;
}

.btn-delete-invoice-item {
    font-size: 15px;
}
</style>