<template>
    <div class="dashboard-body">
        <div class="row">
            <sidenav class="custom-col-width-2" />
            <div style="padding-left: 0px!important" class="custom-col-width-10 content-section dashboard-content">
                <navbar />
                <addUserModal v-if="showAddUserModal" :organizationId="organizationId"
                    @close-add-user-modal="showAddUserModal = false" @refresh-users="getUsers()" />
                <editUserModal v-if="showEditUserModal" :selectedUser="selectedUser"
                    @close-edit-user-modal="showEditUserModal = false" @refresh-users="getUsers()" />
                <deleteUserModal v-if="showDeleteUserModal" :selectedUserId="selectedUserId"
                    @close-delete-user-modal="showDeleteUserModal = false" @refresh-users="getUsers()" />
                <div class="content">
                    <br>
                    <div class="row button-grp justify-content-between">
                        <div class="col">
                            <h4>Organization</h4>
                        </div>
                        <div v-if="account.role == USER_ROLE_ADMIN" class="col">
                            <button v-on:click="showAddUserModal = true" class="btn btn-add"><i class="pi pi-plus"></i>
                                Add Account</button>
                        </div>
                    </div>
                    <div class="row justify-content-between">
                        <organizationNavbar class="" />
                    </div>
                    <div class="row filter-grp">
                        <div class="col-9">
                            <i class="pi pi-search" aria-hidden="true"></i>
                            <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
                        </div>
                        <div class="col-2">
                            <div class="row">
                                <div class="btn col-lg-7 col-mg-9 col-sm-12" style="display: block"><img src="../../assets/icons/filter-icon.svg" alt=""> Filters</div>
                            </div>
                        </div>
                    </div>
                    <div v-if="usersIsLoading && users == null">
                        <div class="row">
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#DFDFFAFF'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                            <br>
                            <div class="col-12 shimmer-loader-row">
                                <content-loader :speed="1" :viewBox="'0 0 100 3'" :background-color="'#FFFFFF'"
                                    :border-radius="'1px'" :foreground-color="'#F5F5FB'">
                                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="18" />
                                </content-loader>
                            </div>
                        </div>
                    </div>
                    <div v-else class="row">
                        <div class="col-lg-12">
                            <div class="card">
                                <div class="card-body">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">First Name</th>
                                                <th scope="col">Last Name</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Role</th>
                                                <th v-if="account.role == USER_ROLE_ADMIN" scope="col">Status</th>
                                                <th scope="col">Created By</th>
                                                <th scope="col">Created On</th>
                                                <th v-if="account.role == USER_ROLE_ADMIN" scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-bind:key="index" v-for="(user, index) in users">
                                                <tr :class="[isOdd(index + 1) ? 'striped' : null ]">
                                                    <th scope="row">{{ index + 1 }}</th>
                                                    <td>{{ user.fname }}</td>
                                                    <td>{{ user.lname }}</td>
                                                    <td>{{ user.email }}</td>
                                                    <td>{{ user.role }}</td>
                                                    <td v-if="account.role == USER_ROLE_ADMIN">
                                                        <label
                                                            v-if="user.id != account.id && account.role == USER_ROLE_ADMIN"
                                                            v-on:click=" toggleUserIsActive(user.id)"
                                                            class="form-switch">
                                                            <input
                                                                v-if="!(actingUser != null && actingUserIsLoading && actingUser.id == user.id)"
                                                                type="checkbox" :checked="user.isActive">
                                                            <span v-else class="spinner-border spinner-border-sm"
                                                                role="status" aria-hidden="true"></span>
                                                            <i></i>
                                                        </label>
                                                        <h2 v-else class="text-secondary"> - </h2>
                                                    </td>
                                                    <!-- <td>{{ user.isActive ? 'active' : 'in-active' }}</td> -->
                                                    <td>{{ user.user.fname }}</td>
                                                    <td>{{ $filters.getDateOnly(user.createdAt) }}</td>
                                                    <td v-if="account.role == USER_ROLE_ADMIN">
                                                        <i v-on:click="selectUser(user)"
                                                            class="pi pi-pencil text-primary"></i>
                                                        <i v-on:click="selectUserToDelete(user.id)"
                                                            class="pi pi-trash text-danger"></i>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ContentLoader } from 'vue-content-loader';

import sidenav from '@/components/Sidenav.vue'
import navbar from '@/components/Navbar.vue'
import organizationNavbar from '@/components/Organization_Navbar.vue'
import addUserModal from '@/components/modals/User/Add_User_Modal.vue'
import editUserModal from '@/components/modals/User/Edit_User_Modal.vue'
import deleteUserModal from '@/components/modals/User/Delete_User_Modal.vue'
import { GET_USERS, TOGGLE_USER_IS_ACTIVE } from '@/store/action_types'
import { ORGANIZATION_DETAILS_ROUTE, ORGANIZATION_ACCOUNTS_ROUTE, ORGANIZATION_SCREENS_ROUTE } from '@/router/routes'
import { USER_ROLE_USER, USER_ROLE_ADMIN } from '@/utils/const';

export default {
    title: 'Accounts',
    components: {
        sidenav,
        navbar,
        addUserModal,
        editUserModal,
        deleteUserModal,
        organizationNavbar,
        ContentLoader
    },
    data() {
        return {
            ORGANIZATION_DETAILS_ROUTE,
            ORGANIZATION_ACCOUNTS_ROUTE,
            ORGANIZATION_SCREENS_ROUTE,
            USER_ROLE_ADMIN,
            USER_ROLE_USER,
            showAddUserModal: false,
            showEditUserModal: false,
            showDeleteUserModal: false,
            selectedUser: null,
            selectedUserId: null,
            organizationId: null
        }
    },
    computed: {
        route() {
            return this.$route.name
        },
        isLoading() {
            return this.$store.getters.organizations.isLoading
        },
        organizations() {
            return this.$store.getters.organizations.data
        },
        organization() {
            return this.$store.getters.organization.data
        },
        actingUser() {
            return this.$store.getters.user.data
        },
        actingUserIsLoading() {
            return this.$store.getters.user.isLoading
        },
        users() {
            return this.$store.getters.users.data
        },
        usersIsLoading() {
            return this.$store.getters.users.isLoading
        },
        account() {
            return this.$store.getters.account.data
        }
    },
    methods: {
        isOdd(number) {
            return number % 2 !== 0;
        },
        selectUser(user) {
            this.showEditUserModal = true
            this.selectedUser = user
        },
        selectUserToDelete(userId) {
            this.showDeleteUserModal = true
            this.selectedUserId = userId
        },
        toggleUserIsActive(id) {
            this.$store.dispatch(TOGGLE_USER_IS_ACTIVE, { id } )
        },
        setOrganization() {
            this.organizationId = this.$route.params.id
        },
        getUsers() {
            this.$store.dispatch(GET_USERS, { org: this.$route.params.id })
        }
    },
    mounted() {
        this.getUsers()
        this.setOrganization()
    }
}
</script>
<style scoped>
.content .card,
.content .card-body {
    padding: 0px;
}

.custom-col-width-2 {
    width: 16.66%;
}

.custom-col-width-10 {
    width: 83.3%;
}

@media (max-width: 1000px) {
    .custom-col-width-2 {
        width: 12%;
    }

    .custom-col-width-10 {
        width: 88%;
    }
}
</style>