<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeAddTicketModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">New Ticket</span>
            <br>
            <div class="modal__content">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <p v-if="errorMessage" class="text-center text-danger auth-status-msg">{{ errorMessage }}</p>
                                <Form @submit="addTicket">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="subject">Subject</label>
                                                <Field v-model="addTicketObj.subject" rules="required" type="text"
                                                    name="subject" class="form-control" id="subject"
                                                    placeholder="Subject" />
                                                <ErrorMessage name="subject" class="form-text text-danger" />
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="subject">Details</label>
                                                <Field v-slot="{ field, errors }" v-model="addTicketObj.details"
                                                    rules="required" name="details" class="form-control">
                                                    <textarea v-bind="field" name="details" class="form-control"
                                                        rows="10" placeholder="Enter details enter...." />
                                                    <ErrorMessage name="details" class="form-text text-danger" />
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row justify-content-end modal__actions">
                                        <button class="col-12 btn btn-save" :disabled="ticketIsLoading ? true : false">
                                            <span v-if="ticketIsLoading" class="spinner-border spinner-border-sm"
                                                role="status" aria-hidden="true"></span>
                                            <span v-if="!ticketIsLoading">Submit</span>
                                        </button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import Quill from 'quill';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import { ADD_ANNOUNCEMENT, ADD_TICKET, REGISTER_ROOM } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';

import { DATE_PICKER_ANNOUNCEMENT_DISPLAY_START_DATE, DATE_PICKER_ANNOUNCEMENT_DISPLAY_END_DATE } from '@/utils/const';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        organizationId: String
    },
    components: {
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage,
        QuillEditor
    },
    data() {
        return {
            DATE_PICKER_ANNOUNCEMENT_DISPLAY_START_DATE,
            DATE_PICKER_ANNOUNCEMENT_DISPLAY_END_DATE,
            showModal: true,
            addTicketObj: {
                subject: null,
                details: null,
                org: null
            }
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        ticket() {
            return this.$store.getters.ticket.data
        },
        ticketIsLoading() {
            return this.$store.getters.ticket.isLoading
        }
    },
    methods: {
        setValues() {
            this.addTicketObj.org = this.organizationId
        },
        addTicket() {
            this.$store.dispatch(ADD_TICKET, this.addTicketObj).then((result) => {
                if (result) {
                    this.$emit('closeAddTicketModal')
                    this.$emit('refreshTickets')
                }
            })
        },
    },
    mounted() {
        this.setValues()
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    position: relative;
    left: 30%;
    margin-top: 60px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
}

.btn-save {
    background-color: #14243D;
}
</style>