<template>
    <div>
        <vue-final-modal 
            :drag="false"
            drag-selector=""
            v-model="showModal"
            content-class="modal-content"
            classes="modal-container"
            focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeAddTemplateModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Add Template</span>
            <br>
            <div class="modal__content">
              <div class="card">
                    <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <p v-if="errorMessage" class="text-center text-danger auth-status-msg">{{ errorMessage }}</p>
                            <!-- <Form @submit="addTemplate"> -->
                                <div class="form-group">
                                    <label for="namesd">Name</label>
                                    <Field v-model="addTemplateObj.name" rules="required" type="text" name="namesd" class="form-control" id="namesd" placeholder="Name"/>
                                    <ErrorMessage name="namesd" class="form-text text-danger"/>
                                </div> 
                                <div class="row content-row">
                                    <div class="col-1">
                                        <label for="title">Content</label>
                                    </div>
                                    <div class="col-2 offset-8">
                                        <div class="dropdown">
                                          <button class="btn btn-add button-insert-var dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Insert Variable
                                          </button>
                                          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                              <button class="dropdown-item" @click="insertVariable('member')">Insert 'member'</button>
                                              <button class="dropdown-item" @click="insertVariable('amount')">Insert 'amount'</button>
                                              <button class="dropdown-item" @click="insertVariable('purpose')">Insert 'purpose'</button>
                                              <button class="dropdown-item" @click="insertVariable('donationDate')">Insert 'donationDate'</button>
                                              <button class="dropdown-item" @click="insertVariable('donationDisplayStartDate')">Insert 'donationDisplayStartDate'</button>
                                              <button class="dropdown-item" @click="insertVariable('donationDisplayEndDate')">Insert 'donationDisplayEndDate'</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <QuillEditor ref="myQuillEditor" :options="editorOption" style="height: 280px;"></QuillEditor>
                                <div class="row justify-content-end modal__actions">
                                    <button v-on:click="addTemplate" class="col-12 btn btn-save" :disabled="templateIsLoading ? true : false">
                                        <span v-if="templateIsLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        <span v-if="!templateIsLoading">Save</span>
                                    </button>
                                </div>
                            <!-- </Form> -->
                        </div>
                    </div>
                  </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import { ADD_TEMPLATE } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

import Quill from 'quill';


export default {
    props: {
        organizationId: String
    },
    components: {
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage,
        QuillEditor
    },
    data () {
        return {
            showModal: true,
            addTemplateObj: {
                name: null,
                content: '',
                org: null
            },
            editorOption: {
                modules: {
                    toolbar: [
                        [{ 'font': ['TzlilimPM', 'Pyutim'] }],
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                        [{ 'direction': 'rtl' }],                         // text direction
                        [{ 'align': [] }],

                        ['link', 'image', 'video', 'formula'],            // add's image support
                        ['clean']    
                    ]
                }
            }
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        template() {
              return this.$store.getters.template.data
        },
        templateIsLoading() {
            return this.$store.getters.template.isLoading
        }
    },
    methods: {
      registerFonts() {
          const Font = Quill.import('formats/font');
          Font.whitelist = ['TzlilimPM', 'Pyutim']; // add custom fonts to the whitelist
          Quill.register(Font, true);
        },
        insertVariable(variable) {
          this.$nextTick(() => {
            if (this.$refs.myQuillEditor && this.$refs.myQuillEditor.getQuill) {
              const quill = this.$refs.myQuillEditor.getQuill();
              const range = quill.getSelection();
              if (range) {
                quill.insertText(range.index, `{${variable}}`);
              }
            } else {
              console.error("Quill editor is not yet initialized");
            }
          });
        },
      addTemplate() {  
          if (this.organizationId != null) {
            this.addTemplateObj.org = this.organizationId
          } else {
            delete this.addTemplateObj.org
          }
          
          this.addTemplateObj.content = this.$refs.myQuillEditor.getHTML()

          console.log(this.addTemplateObj)
          this.$store.dispatch(ADD_TEMPLATE, this.addTemplateObj).then((result) => {
              if(result) {
                  this.$emit('closeAddTemplateModal')
                  this.$emit('refreshTemplates')
              }
          })           
        },
    },
    mounted() {
      this.$nextTick(() => {
        if (this.$refs.myQuillEditor && this.$refs.myQuillEditor.getQuill) {
          console.log("Quill instance:", this.$refs.myQuillEditor.getQuill());
        } else {
          console.error("Quill editor is not available.");
        }
      });
      this.registerFonts()
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    position: relative;
    left: 30%;
    margin-top: 60px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
}

.btn-save {
  margin-top: 10px;
  background-color: #14243D;
}

.button-insert-var {
  font-size: 14px;
  background-color: #F9F9FC;
  color: #858D9D;
  position: relative;
  right: 25px;
  margin-bottom: 10px;
  border-radius: 8px;
  border: 1px solid #E0E2E7
}

</style>