import baseAPI from '@/api/base';
import { GetModuleSettingBody, RegisterModuleSettingBody } from '../dtos/module_settings.dto';
import { ADD_MODULE_SETTING_URL, MODULE_SETTING_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class ModuleSettings {
    static getModuleSettingss = async(getModuleSettingBody: GetModuleSettingBody) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(MODULE_SETTING_URL, {
                    params: getModuleSettingBody
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static registerModuleSettings = async(registerModuleSettingBody: RegisterModuleSettingBody) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(ADD_MODULE_SETTING_URL, {
                    ...registerModuleSettingBody
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default ModuleSettings