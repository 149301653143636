import baseAPI from '@/api/base';
import { EditSynagogueMemberSeat, AddSynagogueMemberSeat, GetSynagogueMemberSeat } from '../dtos/synagogue_member_seat.dto';
import { DELETE_SYNAGOGUE_MEMBER_SEAT_URL, SYNAGOGUE_MEMBER_SEAT_URL, EDIT_SYNAGOGUE_MEMBER_SEAT_URL, ADD_SYNAGOGUE_MEMBER_SEAT_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class SynagogueMemberSeat {
    static getSynagogueMemberSeats = async(getSynagogueMemberSeat: GetSynagogueMemberSeat) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(SYNAGOGUE_MEMBER_SEAT_URL, {
                    params: getSynagogueMemberSeat
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static addSynagogueMemberSeat = async(addSynagogueMemberSeat: AddSynagogueMemberSeat) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(ADD_SYNAGOGUE_MEMBER_SEAT_URL, {
                    ...addSynagogueMemberSeat
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static editSynagogueMemberSeat = async(editSynagogueMemberSeat: EditSynagogueMemberSeat) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(EDIT_SYNAGOGUE_MEMBER_SEAT_URL, {
                    ...editSynagogueMemberSeat
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static deleteSynagogueMemberSeat = async(synagogueMemberSeatId: string) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.delete(`${DELETE_SYNAGOGUE_MEMBER_SEAT_URL}/${synagogueMemberSeatId}`)

                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default SynagogueMemberSeat