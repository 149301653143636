import baseAPI from '@/api/base';
import { EditPledgeType, AddPledgeType, GetPledgeType } from '@/dtos/pledge_type.dto';
import { DELETE_PLEDGE_TYPE_URL, PLEDGE_TYPE_URL, EDIT_PLEDGE_TYPE_URL, ADD_PLEDGE_TYPE_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class PledgeType {
    static getPledgeTypes = async(getPledgeType: GetPledgeType) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(PLEDGE_TYPE_URL, {
                    params: getPledgeType
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static registerPledgeType = async(addPledgeType: AddPledgeType) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(ADD_PLEDGE_TYPE_URL, {
                    ...addPledgeType
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static editPledgeType = async(editPledgeType: EditPledgeType) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(EDIT_PLEDGE_TYPE_URL, {
                    ...editPledgeType
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static deletePledgeType = async(donationId: string) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.delete(`${DELETE_PLEDGE_TYPE_URL}/${donationId}`)

                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default PledgeType