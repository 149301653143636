import baseAPI from '@/api/base';
import { EditInvoiceItem, GetInvoiceItem } from '../dtos/invoice_items.dto';
import { INVOICE_ITEM_DETAILS_URL, INVOICE_ITEM_URL, TOGGLE_INVOICE_ITEM_ISPAID_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class InvoiceItem {
    static getInvoiceItems = async(getInvoiceItem: GetInvoiceItem) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(INVOICE_ITEM_URL, {
                    params: getInvoiceItem
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static getInvoiceItemDetails = async(getInvoiceItem: GetInvoiceItem) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(INVOICE_ITEM_DETAILS_URL, {
                    params: getInvoiceItem
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static toggleInvoiceItemIsPaid = async(editInvoiceItem: EditInvoiceItem) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(TOGGLE_INVOICE_ITEM_ISPAID_URL, {
                    ...editInvoiceItem
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default InvoiceItem