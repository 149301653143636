import baseAPI from '@/api/base';
import { EditPurpose, AddPurpose, GetPurpose } from '../dtos/purpose.dto';
import { DELETE_PURPOSE_URL, PURPOSE_URL, EDIT_PURPOSE_URL, ADD_PURPOSE_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class Purpose {
    static getPurposes = async(getPurpose: GetPurpose) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(PURPOSE_URL, {
                    params: getPurpose
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static registerPurpose = async(addPurpose: AddPurpose) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(ADD_PURPOSE_URL, {
                    ...addPurpose
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static editPurpose = async(editPurpose: EditPurpose) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(EDIT_PURPOSE_URL, {
                    ...editPurpose
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static deletePurpose = async(donationId: string) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.delete(`${DELETE_PURPOSE_URL}/${donationId}`)

                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default Purpose