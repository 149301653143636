<template>
    <div>
        <vue-final-modal :drag="false" drag-selector="" v-model="showModal" content-class="modal-content"
            classes="modal-container" focus-trap>
            <button class="modal__close btn btn-light" @click="$emit('closeEditOrganizationScreenModal')">
                <i class="pi pi-times"></i>
            </button>
            <span class="modal__title">Edit Organization Screen</span>
            <br>
            <div class="modal__content">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <p v-if="errorMessage" class="text-center text-danger auth-status-msg">{{ errorMessage }}</p>
                                <Form @submit="editOrganizationScreen">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="name">Screen Name</label>
                                                <Field v-model="editOrganizationScreenObj.name" rules="required"
                                                    type="text" name="name" class="form-control" id="name"
                                                    placeholder="Screen Name" />
                                                <ErrorMessage name="name" class="form-text text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row justify-content-end modal__actions">
                                        <button class="col-12 btn btn-save"
                                            :disabled="organizationScreenIsLoading ? true : false">
                                            <span v-if="organizationScreenIsLoading"
                                                class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            <span v-if="!organizationScreenIsLoading"> Save</span>
                                        </button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>
<script>
import { EDIT_ORGANIZATION_SCREEN } from '@/store/action_types'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

export default {
    props: {
        selectedOrganizationScreen: Object
    },
    components: {
        VueFinalModal,
        ModalsContainer,
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        return {
            showModal: true,
            editOrganizationScreenObj: {
                name: null,
                scr: null,
                org: null
            }
        }
    },
    computed: {
        errorMessage() {
            return this.$store.getters.errorMsg
        },
        screens() {
            return this.$store.getters.screens.data
        },
        screensIsLoading() {
            return this.$store.getters.screens.isLoading
        },
        organizationScreen() {
            return this.$store.getters.organizationScreen.data
        },
        organizationScreenIsLoading() {
            return this.$store.getters.organizationScreen.isLoading
        }
    },
    methods: {
        setValues() {
            this.editOrganizationScreenObj.id = this.selectedOrganizationScreen.id
            this.editOrganizationScreenObj.name = this.selectedOrganizationScreen.name
            this.editOrganizationScreenObj.scr = this.selectedOrganizationScreen.scr
        },
        editOrganizationScreen() {
            this.editOrganizationScreenObj.scr = this.editOrganizationScreenObj.scr.id

            this.$store.dispatch(EDIT_ORGANIZATION_SCREEN, this.editOrganizationScreenObj).then((result) => {
                if (result) {
                    this.$emit('closeEditOrganizationScreenModal')
                    this.$emit('refreshOrganizationScreens')
                }
            })
        },
    },
    mounted() {
        this.setValues()
    }
}
</script>
<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    position: relative;
    left: 30%;
    margin-top: 60px;
}

::v-deep .modal-content {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.2rem;
    background: #fff;
}

.btn-save {
    background-color: #14243D;
}
</style>