import baseAPI from '@/api/base';
import { EditScreen, AddScreen, GetScreen, RefreshScreen } from '../dtos/screen.dto';
import { DELETE_SCREEN_URL, SCREEN_URL, EDIT_SCREEN_URL, ADD_SCREEN_URL, REFRESH_SCREEN_URL } from '@/utils/urls';
import { AxiosResponse } from 'axios';

class Screen {
    static getScreens = async(getScreen: GetScreen) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(SCREEN_URL, {
                    params: getScreen
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static registerScreen = async(formData: FormData) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.post(ADD_SCREEN_URL, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static editScreen = async(editScreen: EditScreen) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.put(EDIT_SCREEN_URL, {
                    ...editScreen
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static refreshScreen = async(refreshScreen: RefreshScreen) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.get(REFRESH_SCREEN_URL, {
                    params: refreshScreen
                })
                console.log('response', response)
                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }

    static deleteScreen = async(donationId: string) : Promise<AxiosResponse> => {
        return new Promise(async(resolve, reject) => {
            try {
                let response: AxiosResponse = await baseAPI.delete(`${DELETE_SCREEN_URL}/${donationId}`)

                resolve(response)
            } catch (error) {
                reject(error)
            }
        })
    }
}

export default Screen