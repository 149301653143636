import axios, { AxiosResponse } from 'axios'
import { SET_ALIAS_OFFERING_TYPES, SET_ALIAS_OFFERING_TYPE, SET_ERRORMSG } from '../mutation_types'
import {
    CLEAR_ALIAS_OFFERING_TYPES_ISLOADING,
    DELETE_ALIAS_OFFERING_TYPE,
    EDIT_ALIAS_OFFERING_TYPE,
    GET_ALIAS_OFFERING_TYPES,
    ADD_ALIAS_OFFERING_TYPE,
} from '../action_types'
import { StateObject } from '@/dtos/state.dto'
import AliasOfferingType from '@/services/alias_offering_type'
import { Module } from 'vuex'
import { EditAliasOfferingType, AddAliasOfferingType, GetAliasOfferingType } from '@/dtos/alias_offering_type.dto'

const aliasOfferingType: Module<any, {}> = {
    state: {
        aliasOfferingType: {
            data: null,
            isLoading: false
        },
        aliasOfferingTypes: {
            data: null,
            isLoading: false
        }
    },
    getters: {
        aliasOfferingType: (state: { aliasOfferingType: any }) => state.aliasOfferingType,
        aliasOfferingTypes: (state: { aliasOfferingTypes: any }) => state.aliasOfferingTypes
    },
    mutations: {
        [SET_ALIAS_OFFERING_TYPE]: (state: { aliasOfferingType: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.aliasOfferingType.data = payload.data : null, state.aliasOfferingType.isLoading = payload.isLoading },
        [SET_ALIAS_OFFERING_TYPES]: (state: { aliasOfferingTypes: StateObject }, payload: { data: null; status: any; isLoading: any }) => { payload.data != null ? state.aliasOfferingTypes.data = payload.data : null, state.aliasOfferingTypes.isLoading = payload.isLoading }
    },
    actions: {
        [ADD_ALIAS_OFFERING_TYPE]: async({ commit }: any, payload: any) => {
            commit(SET_ALIAS_OFFERING_TYPE, { data: null, isLoading: true })

            let result: AxiosResponse = await AliasOfferingType.registerAliasOfferingType(payload as AddAliasOfferingType)
          
            if(result) {
                if (result.data.status) {
                    commit(SET_ALIAS_OFFERING_TYPE, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_ALIAS_OFFERING_TYPE, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ALIAS_OFFERING_TYPE, { isLoading: false })
                return false;
            }
        },
        [GET_ALIAS_OFFERING_TYPES]: async({ commit }: any, payload: any) => {
            commit(SET_ALIAS_OFFERING_TYPES, { data: null, isLoading: true })

            let result: AxiosResponse = await AliasOfferingType.getAliasOfferingTypes(payload as GetAliasOfferingType)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_ALIAS_OFFERING_TYPES, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_ALIAS_OFFERING_TYPES, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ALIAS_OFFERING_TYPES, { isLoading: false })
                return false;
            }
        },
        [EDIT_ALIAS_OFFERING_TYPE]: async({ commit }: any, payload: any) => {
            commit(SET_ALIAS_OFFERING_TYPE, { data: null, isLoading: true })

            let result: AxiosResponse = await AliasOfferingType.editAliasOfferingType(payload as EditAliasOfferingType)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_ALIAS_OFFERING_TYPE, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_ALIAS_OFFERING_TYPE, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ALIAS_OFFERING_TYPE, { isLoading: false })
                return false;
            }
        },
        [DELETE_ALIAS_OFFERING_TYPE]: async({ commit }: any, payload: string) => {
            commit(SET_ALIAS_OFFERING_TYPE, { data: null, isLoading: true })

            let result: AxiosResponse = await AliasOfferingType.deleteAliasOfferingType(payload)
          
            if(result) {
                if (result.data.status) {
                    console.log('result.data.data', result.data.data)
                    commit(SET_ALIAS_OFFERING_TYPE, { data: result.data.data, isLoading: false })
                    return true;
                }
                commit(SET_ALIAS_OFFERING_TYPE, { data: null, isLoading: false })
                commit(SET_ERRORMSG, result.data.msg)
                return false;
            } else {
                commit(SET_ALIAS_OFFERING_TYPE, { isLoading: false })
                return false;
            }
        },
        [CLEAR_ALIAS_OFFERING_TYPES_ISLOADING]: ({ commit }) => {
            commit(SET_ALIAS_OFFERING_TYPE, { isLoading: false })
            commit(SET_ALIAS_OFFERING_TYPES, { isLoading: false })
        }
    }
}

export default aliasOfferingType